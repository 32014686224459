








import {defineComponent, reactive, ref, SetupContext, watch} from "@vue/composition-api";
import Orders from "@/views/orders/Orders.vue";

export default defineComponent({
  name: "OrdersMain",
  components: {
    'OrdersGrid': Orders
  }
})

