import {quoteService} from "@/services/QuoteService";
import {OptionChainExpiry, OptionContract, OptionPair, Quotable, SecurityQuote} from "@/data/QuoteData";
import {Order, OrderLeg, OrderPreview} from "@/data/OrdersData";
import {
    AsyncStatus,
    OrderClass,
    OrderDuration,
    OrderType,
    PriceEffect,
    SecurityDirection,
    SecurityType, StrikeSelectionCriteria,
    TradeMode,
    TransactionType
} from "@/data/EnumData";
import {appGlobals} from "@/data/AppData";
import {Constants} from "@/data/common/Constants";
import {
    AddLegPayload,
    OptionChainFilters,
    SharedTradeData,
    TemplateLeg,
    TradeAnalysis,
    TradeTemplate
} from "@/data/TradeData";
import {reactive} from "@vue/composition-api";
import {BNUtil, NumberUtil} from "@/util/Util";
import _map from "lodash/map";
import _find from "lodash/find";
import _sortBy from "lodash/sortBy";
import {BrokerUser} from "@/data/UserData";
import {AsyncResponse, VuetifySelection} from "@/data/CommonData";
import {userService} from "@/services/UserService";
import {BrokerFactory} from "@/services/broker/BrokerFactory";
import {orderService} from "@/services/OrderService";
import {BaseService} from "@/services/CommonService";
import {eventEmitter} from "@/main";
import {PositionItem} from "@/data/PositionsData";
import {LRUMap} from "lru_map";
import BigNumber from "bignumber.js";
import {orderBackendService} from "@/services/backend/order/OrderBackendService";


export default class TradeService extends BaseService{

    tradeData = reactive<SharedTradeData>(new SharedTradeData());
    // set to true once init() method is invoked
    initialized: boolean = false;

    constructor() {
        super();
        const _this = this;
        // override the shift method to add a callback
        // when setting an entry in cache results in the least recently used entry being removed, the callback is invoked
        this.tradeData.lruOptionExpiries.shift = function(){
            let entry = LRUMap.prototype.shift.call(this);
            if(entry){
                _this.removeOptionExpiryFromDisplay(entry[1]);
            }
            return entry;
        }
    }

    init(){
        if(!this.initialized){
            eventEmitter.on("quotesLoaded", param => {
                this.quotesUpdated();
            });
        }
    }

    /**
     * Invoked when symbol changes. Could happen when symbol is entered for first time or if it is changed
     * @param symbol
     */
    async changeSymbol(){
        // symbol should already be set in tradeData when this is invoked
        if(!this.tradeData.symbol){
            this.resetTradeData()
            return;
        }
        // first let's cache old data for previous symbol
        this.cacheTradeData();
        // let's check if the new symbol is in cache
        const tradeDataCache = appGlobals.tradeDataCache.get(this.tradeData.symbol);
        if (tradeDataCache){
            await this.populateTradeDataFromCache(tradeDataCache);
        }else{
            const symbol = this.tradeData.symbol;
            this.resetTradeData();
            this.tradeData.symbol = symbol;
            this.tradeData.oldSymbol = this.tradeData.symbol;
            this.tradeData.quotable = Quotable.getStockQuotable(symbol);
            this.tradeData.mode = TradeMode.New;
            this.tradeData.order = new Order();
            this.tradeData.order.symbol = symbol;

            this.updateAccountSelections();
            // let's refresh the stock quote immediately, so symbol summary on the top can rendered
            await this.refreshTradeQuotes();
            // clear order data

            // await this.refreshTradeQuotes();
            // get optionchain expiries
            // don't wait for optionExpiries to fill, they will eventually fill
            await this.loadOptionExpiries();
        }
    }

    private cacheTradeData(){
        // cache only if the mode is cacheable
        if (this.tradeData.oldSymbol && this.tradeData.mode.isCacheable){
            if(this.tradeData.mode.isPreviewMode){
                // remove preview mode and bring to 'New' mode
                this.cancelPreview();
            }
            // const tradeDataCopy = classToClass(this.tradeData as SharedTradeData, {enableCircularCheck: true});
            const tradeDataCopy = this.copyTradeDataForCaching(this.tradeData as SharedTradeData, new SharedTradeData());
            // update symbol with oldSymbol value
            // tradeDataCopy.symbol = tradeDataCopy.oldSymbol;
            appGlobals.tradeDataCache.set(this.tradeData.oldSymbol, tradeDataCopy);
        }
    }

    private async populateTradeDataFromCache(tradeDataCache: SharedTradeData){
        this.tradeData.optionChainGridLoadingIndicator = true;
        // if cache exists
        this.copyTradeDataForCaching(tradeDataCache, this.tradeData as SharedTradeData);
        this.tradeData.oldSymbol = this.tradeData.symbol;
        await this.updateOnLegChanges(true, true);
        // let's move focus to the first option leg in optionChain
        await this.focusFirstOptionLeg();
        this.tradeData.optionChainGridLoadingIndicator = false;
    }


    /**
     * Used for copying tradeData while putting in cache and retrieving from cache
     * @param source
     * @param target
     */
    private copyTradeDataForCaching(source: SharedTradeData, target: SharedTradeData){
        // copy oldSymbol here
        target.symbol = source.oldSymbol;
        target.oldSymbol = source.oldSymbol
        target.mode = source.mode;
        target.mode = TradeMode.New;
        target.quotable = source.quotable;
        target.optionChainItems.splice(0, target.optionChainItems.length, ...source.optionChainItems);
        target.optionExpiries = source.optionExpiries;
        target.order = source.order;
        // don't copy orderPreview
        target.userAccount = source.userAccount;
        target.brokerUser = source.brokerUser;
        target.tradeSettings = source.tradeSettings;
        return target;
    }

    updateAccountSelections(){
        if (!this.tradeData.userAccount){
            if (this.tradeData.mode == TradeMode.Edit){
                // retrieve account info from order
                this.tradeData.userAccount = this.tradeData.order.userAccount;
                this.tradeData.brokerUser = appGlobals.user?.getBrokerUser(this.tradeData.userAccount!.brokerUserId!);
            }else{
                // if userAccount is not set, use the default
                this.tradeData.userAccount = appGlobals.selectedUserAccount;
                this.tradeData.brokerUser = appGlobals.selectedBrokerUser;
            }
        }
        this.tradeData.order.userAccount = this.tradeData.userAccount;
        this.tradeData.userAccountSelections.splice(0, this.tradeData.userAccountSelections.length,
            ...userService.getUserAccountSelectionListFromCache());
    }

    private async loadOptionExpiries(){
        this.tradeData.optionChainGridLoadingIndicator = true;
        const optionChainExpiriesResponse: AsyncResponse<OptionChainExpiry[]> = await quoteService.getOptionExpiries(this.tradeData.symbol);
        if(optionChainExpiriesResponse.status == AsyncStatus.Ok){
            this.tradeData.optionExpiries.splice(0, this.tradeData.optionExpiries.length, ...optionChainExpiriesResponse.data!);
            this.tradeData.optionChainItems.splice(0, this.tradeData.optionChainItems.length, ...optionChainExpiriesResponse.data!);
            this.tradeData.optionChainGridLoadingIndicator = false;
        }else{
            this.handleAsyncErrors(optionChainExpiriesResponse);
        }

    }

    private async loadOptionPairsForExpiry(optionChainExpiry: OptionChainExpiry, expandChain: boolean){
        // load only if optionPairs don't exist
        if(optionChainExpiry.optionPairs.length == 0){
            // load the data from the server
            await quoteService.updateOptionPairs(this.tradeData.quotable as Quotable, optionChainExpiry);
            this.setDerivedAttributesOnExpiry(optionChainExpiry)
            // insert optionPairs into optionChainItems
            this.tradeData.optionChainItems.splice(this.tradeData.optionChainItems.indexOf(optionChainExpiry)+1, 0, ...optionChainExpiry.optionPairs);
            // if there are any legs, that belong in this expiry, select those contracts
            for (let leg of this.tradeData.order.legs ){
                if (leg.securityType.isOption && leg.expiryDate.getTime() == optionChainExpiry.expiryDate.getTime()){
                    const optionPair = _find(optionChainExpiry!.optionPairs, optionPair => leg.securityType == SecurityType.Put ? optionPair.put!.symbol == leg.symbol : optionPair.call!.symbol == leg.symbol)
                    if(optionPair){
                        const selectedOptionContract = leg.securityType == SecurityType.Put ? optionPair.put : optionPair.call;
                        leg.quotable = selectedOptionContract;
                        selectedOptionContract!.orderLeg = leg as OrderLeg;
                    }
                }
            }
        }
        if (expandChain){
            this.expandChainAndRefresh(optionChainExpiry);
        }
    }

    private setDerivedAttributesOnExpiry(optionChainExpiry: OptionChainExpiry){
        if(this.tradeData.symbol == optionChainExpiry.symbol){
            const stockQuotable = this.tradeData.quotable! as Quotable;
            const stockPrice = stockQuotable.rawQuote!.price;
            const strikesMap : Map<BigNumber, OptionPair> = new Map();
            optionChainExpiry.optionPairs.forEach(optionPair => strikesMap.set(optionPair.strike, optionPair));
            const strikes = [...strikesMap.keys()];
            optionChainExpiry.strikes = strikes;

            const atmStrike = BNUtil.getNearest(strikes, stockPrice);
            optionChainExpiry.atmStrike = atmStrike;
            for(let optionPair of optionChainExpiry.optionPairs){
                optionPair.key = OptionPair.constructKey(optionChainExpiry.expiryDate, optionPair.strike);
                this.setDerivedAttributesOnContract(stockQuotable.rawQuote, optionPair.call!);
                this.setDerivedAttributesOnContract(stockQuotable.rawQuote, optionPair.put!);

                if (optionPair.strike.eq(atmStrike)){
                    optionPair.isAtm = true;
                    if (stockPrice.lt(atmStrike)){
                        optionPair.stockAboveAtm = false;
                    }else{
                        optionPair.stockAboveAtm = true;
                    }
                }else{
                    // we have to reset if this Pair was previously flagged as atm
                    optionPair.isAtm = false;
                }
            }
        }
    }

    private setDerivedAttributesOnContract(stockQuote: SecurityQuote, optionContract: OptionContract){
        const stockPrice = stockQuote.price;
        const optionStrike = optionContract.strikePrice;
        const optionQuote = optionContract.rawQuote;

        if( (optionStrike.gte(stockPrice) &&  optionContract.securityType.isPut) ||
            (optionStrike.lte(stockPrice) &&  optionContract.securityType.isCall)){
            optionContract.isItm = true;
            optionQuote.intrinsicValue = stockPrice.minus(optionStrike);
        }else{
            optionContract.isItm = false;
            optionQuote.intrinsicValue = BNUtil.ZERO;
        }
    }

    // invoked after quotes have been updated by the background process
    private quotesUpdated(){
        // update totals
        this.updateOrderTotal(this.tradeData.order as Order, this.tradeData.tradeAnalysis as TradeAnalysis);
        // for each of the OptionChainExpiries that are in expanded status, update their fields
        for(let optionExpiry of this.tradeData.optionExpiries){
            if (optionExpiry.expanded){
                this.setDerivedAttributesOnExpiry(optionExpiry as OptionChainExpiry);
            }
        }
    }


    /**
     * Should be invoked after loading the OptionChain with OptionPairs
     * @private
     */
    private async expandChainAndRefresh(optionChainExpiry: OptionChainExpiry){
        optionChainExpiry.expanded = true;

        this.applyOptionChainStrikeFilters(optionChainExpiry);
        // optionChainExpiry.optionPairs.forEach(pair => pair.show = true);
        // we don't have to update quotes because we just retrieved them
        await this.refreshOptionChainQuotes(false);
        // add the expiry to lru map to cache it
        this.setOptionExpiryInLRUMap(optionChainExpiry);
    }

    /**
     * Reset trade data, so you can start fresh
     */
    resetTradeData(){
        // to clear order, simply set it to a new reference
        this.tradeData.order = new Order();
        this.tradeData.symbol = "";
        this.tradeData.optionChainGridLoadingIndicator = false;
        this.tradeData.optionChainItems.splice(0, this.tradeData.optionChainItems.length);
        this.tradeData.optionExpiries = [];
        this.tradeData.mode = TradeMode.New;
        this.tradeData.optionChainItems = [];
        this.tradeData.orderTypes = [];
        this.tradeData.durationSelections = [];
        this.tradeData.orderPreview = new OrderPreview();
        this.tradeData.tradeAnalysis = new TradeAnalysis();
        // leave userAccount values
    }

    /**
     * invoked when user would like to clear current trade or order to start fresh with the same existing symbol
     * so, we don't clear out the optionChain
     * Also invoked when placing an equity trade
     */
    resetOrder(){
        // remove selections from legs
        for(let leg of this.tradeData.order.legs){
            if (leg.securityType != SecurityType.Stock){
                const optionContract = (leg.quotable as OptionContract);
                if (optionContract.orderLeg){
                    this.updateSelectionsOnOptionChainExpiry(optionContract, optionContract.orderLeg.securityDirection);
                    optionContract.orderLeg = undefined;
                }
            }
        }

        this.tradeData.order.legs.splice(0, this.tradeData.order.legs.length);
        // this.tradeData.order = new Order();  // if I set it to a new order, things weren't working
        this.tradeData.mode = TradeMode.New;
        this.tradeData.orderPreview = new OrderPreview();
        this.tradeData.tradeAnalysis = new TradeAnalysis();
        this.tradeData.order.symbol = this.tradeData.symbol;
        this.updateOnLegChanges(true, false);
    }

    /**
     * Adds given positions to the TradeTicket for closing
     * All positions should belong to the same Broker Account
     * @param positionItems
     */
    async addPositionsForClosing(positionItems: PositionItem[]): Promise<boolean>{
        // if there is an equity leg, move it to the top
        const equityPosition = positionItems.find(item => item.securityType.isStock);
        if (equityPosition && positionItems.length > 1){
            const nonEquityPositions = positionItems.filter(item => item != equityPosition);
            positionItems.splice(0, positionItems.length, equityPosition);
            positionItems.push(...nonEquityPositions);
        }

        const positionItem = positionItems[0];
        // first reset tradeData
        this.resetTradeData();
        const order = new Order();
        order.symbol = positionItem.underlying;
        this.tradeData.order = order;
        this.tradeData.symbol = positionItem.underlying;
        const stockQuotable = Quotable.getStockQuotable(positionItem.underlying);
        // get the actual rawQuote from positionItem (the rawquote should already be set)
        stockQuotable.rawQuote = positionItem.securityType.isStock ? positionItem.quotable!.rawQuote! : positionItem.quotable!.underlyingQuote!;
        this.tradeData.quotable = stockQuotable;
        this.tradeData.mode = TradeMode.Close;
        this.tradeData.userAccount = positionItem.userAccount;
        this.tradeData.brokerUser = positionItem.brokerUser;
        this.updateAccountSelections();

        for(let positionItem of positionItems){
            await this.createOrderLegFromPosition(positionItem);
        }
        // we have to retrieve the optionChain and
        if (order.class != OrderClass.Equity){
            await this.loadOptionExpiries();
            // we'll have to select the legs in OptionChain
            // we don't have to wait for the selections to happen before we open the trade screen, so let's not await this
            this.selectOrderLegsInOptionChain(true);
        }else{
            // for equity order, load the expiries to fill up the grid, but do so asynchronously
            this.loadOptionExpiries();
        }
        return true;
    }

    private async createOrderLegFromPosition(positionItem: PositionItem){
        const addLegPayload = new AddLegPayload();
        addLegPayload.isEquityLeg = positionItem.securityType.isStock;
        addLegPayload.isClose = true;
        // keep the quantity positive, it'll be adjusted in addLegToOrder based on legDirection
        addLegPayload.quantity = Math.abs(positionItem.quantity);
        // change the direction from long to short and viceversa
        addLegPayload.isLong = positionItem.securityDirection.isShort;
        if(!addLegPayload.isEquityLeg){
            // we need to build an
            const optionContract = OptionContract.createFromQuotable(positionItem.quotable!);
            addLegPayload.selectedOptionContract = optionContract;
        }
        // don't refresh the quotes for each Position, do it at the end
        addLegPayload.refreshQuotes = false;
        await this.addLegToOrder(addLegPayload);
    }


    /**
     * Open an existing order for editing. Returns false, if the order could not be opened for editing
     * @param order
     */
    async openOrderForEditing(order: Order) : Promise<boolean>{
        // before opening the order, check if the order is still editable
        const brokerUser = appGlobals.user?.getBrokerUser(order.userAccount?.brokerUserId!);
        const brokerOrderService = BrokerFactory.getOrderService(brokerUser!.broker!);
        const getOrderResponse = await brokerOrderService.getOrder(brokerUser!, order.userAccount!, order.brokerOrderId);
        // lock the price so it's not updated with latest quote
        order.priceLocked = true;
        if(getOrderResponse.status != AsyncStatus.Ok ){
            // we don't know what went wrong in retrieving order, just show generic message
            eventEmitter.emit("showMessage", ["There was a problem retrieving the order for modification"]);
        }else{
            const latestOrder: Order = getOrderResponse.data!;
            // if the order is not editable anymore
            if(!latestOrder.status.editable){
                eventEmitter.emit("showMessage", [`Order is in ${latestOrder.status.label} status and cannot be modified anymore`]);
                return false;
            }
            // update order reference to latestOrder
            // The original order object is still reference in the Orders grid, so don't use it
            // this latestOrder however doesn't have quotables set in it for legs
            // TODO this copy quotes works only if the legs are in the same order
            latestOrder.legs.forEach((leg, index) => {
                if (leg.securityType.isOption){
                    const optionContract = OptionContract.createFromQuotable(order.legs[index].quotable!);
                    optionContract.orderLeg = leg;
                    leg.quotable = optionContract;
                }else{
                    leg.quotable = Quotable.getDuplicateQuotable(order.legs[index].quotable!);
                }
            })
            order = latestOrder;
        }
        // first reset tradeData
        this.resetTradeData();
        order.priceLocked = true;
        this.tradeData.order = order;
        this.tradeData.symbol = order.symbol
        this.tradeData.quotable = Quotable.getStockQuotable(order.symbol);
        this.tradeData.mode = TradeMode.Edit;
        this.updateAccountSelections();
        // don't override the price of the order
        await this.updateOnLegChanges(false);
        // we have to retrieve the optionChain and
        if (order.class != OrderClass.Equity){
            await this.loadOptionExpiries();
            // we'll have to select the legs in OptionChain
            this.selectOrderLegsInOptionChain();
        }else{
            // for equity order, load the expiries to fill up the grid, but do so asynchronously
            this.loadOptionExpiries();
        }
        return true;
    }

    /**
     * Used to toggle display for OptionPairs for an OptionExpiry
     * @param optionChainExpiry
     */
    async toggleChainDisplay(optionChainExpiry: OptionChainExpiry){
        if (optionChainExpiry.expanded){
            this.removeOptionExpiryFromDisplay(optionChainExpiry);
            // remove the expiry from lru map
            this.removeOptionExpiryFromLRUMap(optionChainExpiry);
            // invoking this removes the collapsed expiry from being refreshed
            this.refreshOptionChainQuotes(false);
        }else{
            // if the optionPairs were never loaded for this expiry
            await this.loadOptionPairsForExpiry(optionChainExpiry, true);
        }
    }

    /**
     * selects the legs by expanding the optionchain
     * This is called when an order is opened for editing
     * @param focusFirst
     */
    async selectOrderLegsInOptionChain(focusFirst: boolean = true){
        const order = this.tradeData.order as Order;
        let index = 0;
        for(let leg of order.legs){
            if (leg.securityType.isOption){
                // before this method is called the optionExpiries are already loaded
                const optionExpiries = this.tradeData.optionExpiries as OptionChainExpiry[];
                // get the expiry based on expiry_date and it should exist
                const optionChainExpiry = _find(optionExpiries, optionChainExpiry => optionChainExpiry.expiryDate.getTime() === leg.expiryDate.getTime())!;
                this.updateSelectionsOnOptionChainExpiry(leg.quotable as OptionContract, undefined, optionChainExpiry);
                if (index == 0){
                    await this.focusLegInOptionChain(leg, optionChainExpiry);
                }
                // // show the first leg in OptionChain
                // await this.selectLegInOptionChain(leg, index == 0);
                index++;
            }
        }
    }

    /**
     * This method is invoked when reloading a symbol from cache
     * @private
     */
    private async focusFirstOptionLeg(){
        // assumes that OptionExpiries are already loaded and have their selection statuses appropriately set
        for(let leg of this.tradeData.order.legs){
            if (leg.securityType.isOption){
                await this.focusLegInOptionChain(leg as OrderLeg);
                break;
            }
        }
    }

    async focusLegInOptionChain(leg: OrderLeg, optionChainExpiry?: OptionChainExpiry){
        this.tradeData.optionChainGridLoadingIndicator = true;
        if(!optionChainExpiry){
            const optionExpiries = this.tradeData.optionExpiries as OptionChainExpiry[];
            optionChainExpiry = _find(optionExpiries, optionChainExpiry => optionChainExpiry.expiryDate.getTime() === leg.expiryDate.getTime())!;
        }
        await this.loadOptionPairsForExpiry(optionChainExpiry, true);
        this.tradeData.eventEmitter.emit("focusContractWithDelay", leg.quotable as OptionContract);
        this.tradeData.optionChainGridLoadingIndicator = false;
    }


    async removeOptionContractFromOrder(optionContract: OptionContract){
        if(this.tradeData.mode.disableLegChanges){
            return;
        }
        // the optionContract is stored in OrderLeg as a quotable property,
        const order: Order = this.tradeData.order as Order;
        for(let leg of order.legs){
            if (leg.quotable == optionContract){
                await this.removeLegFromOrder(leg);
                break;
            }
        }
    }

    async removeLegFromOrder(orderLeg: OrderLeg){
        // don't use order.class here, as it's not udpated yet
        if(this.tradeData.mode.disableLegChanges){
            // no leg changes are allowed
            return;
        }
        const order: Order = this.tradeData.order as Order;
        const newLegs: OrderLeg[] = [];
        order.legs.forEach(leg => {
            // compare by reference is safe here
            if(leg != orderLeg){
                newLegs.push(leg);
            }
        })
        // the optionContract from OptionChain is stored as a quotable on the leg, so update it's selection
        if (orderLeg.securityType.isOption){
            const optionContract = orderLeg.quotable as OptionContract;
            if (optionContract.orderLeg){
                optionContract.orderLeg = undefined;
                this.updateSelectionsOnOptionChainExpiry(optionContract, orderLeg.securityDirection);
            }
            // also remove  selection on contract
            optionContract.legSelected = false;
        }

        // replace all old legs with newLegs
        order.legs.splice(0, order.legs.length, ...newLegs);
        // since we are just removing leg, we don't have to update to latest quotes because we already have quotes for existing legs that are not removed
        await this.updateOnLegChanges(true, false);
    }

    /**
     * invoked when userAccount is changed
      */
    changeUserAccount(){
        // the updated userAccount is already set in tradeData
        const userAccount = this.tradeData.userAccount!;
        this.tradeData.brokerUser = appGlobals.user?.getBrokerUser(userAccount.brokerUserId!);
        // also set the userAccount in order
        this.tradeData.order.userAccount = userAccount;
    }

    /**
     * Updates data when a leg is added or removed
     * @private
     */
    private async updateOnLegChanges(overridePriceLock: boolean = true, updateQuotes: boolean = true){
        this.updateOrderClass();
        await this.refreshTradeQuotes(updateQuotes);
        // make sure the quotes are updated on the order
        this.updateOrderTotal(this.tradeData.order as Order, this.tradeData.tradeAnalysis as TradeAnalysis, overridePriceLock);
        this.updateOrderTypes();
        this.updateDurations();
        this.sortLegs();
    }

    /**
     * @param addLegPayload
     * @param selectedOptionContract - is undefined/null for equityLeg
     */
    async addLegToOrder(addLegPayload: AddLegPayload){
        const order = this.tradeData.order as Order;
        if(this.tradeData.mode.disableLegChanges){
            return;
        }

        // handle user selecting an OptionContract that is already part of the legs
        // Trying to add a Stock leg will reset the Order
        let existingOptionLeg:OrderLeg | undefined;
        if(!addLegPayload.isEquityLeg){
            // if the selected optionContract is already part of the legs,
            existingOptionLeg = _find(order.legs, leg => leg.quotable == addLegPayload.selectedOptionContract);
            if (existingOptionLeg){
                // user shouldn't be able to add a contract that's already part of order legs
                return;
            }
        }
        // let's validate if the leg can be added, only 4 legs at max
        if (order.legs.length == 4){
            return false;
        }
        if (order.class == OrderClass.Combo && !addLegPayload.isEquityLeg){
            // if the order was already determined to be a Combo, that means it has a stock and option order
            // so, we cannot add another leg to it
            eventEmitter.emit("showError", ["More than two legs are not allowed on a Combo(equity + option) order"])
            return false;
        }

        // set OrderClass
        if(addLegPayload.isEquityLeg){
            // adding an equity leg should clear all existing legs
            this.resetOrder();
            this.buildEquityOrderLeg(addLegPayload, order);
        }else if(addLegPayload.selectedOptionContract){
            const selectedOptionContract = addLegPayload.selectedOptionContract;
            this.buildOptionOrderLeg(addLegPayload, order);
            this.updateSelectionsOnOptionChainExpiry(selectedOptionContract);
        }

        await this.updateOnLegChanges(true, addLegPayload.refreshQuotes);
        return true;
    }

    /**
     * Updates selection counts on OptionChainExpiry when a contract is selected/un-selected
     * Should be invoked only after orderLeg is set/unset on OptionContract
     * @param optionContract
     * @param previousSecurityDirection - this is undefined only if we are unsetting the securityDirection on a contract
     * @param optionChainExpiry - this is sent when the optionContract is not from OptionChain but isolated, and OptionPairs are not loaded for the expiry
     * @private
     */
    private updateSelectionsOnOptionChainExpiry(optionContract:OptionContract, previousSecurityDirection?: SecurityDirection, optionChainExpiry?: OptionChainExpiry ){
        if (!optionChainExpiry){
            optionChainExpiry = optionContract.optionPair?.optionChainExpiry;
        }
        // previousSecurityDirection is undefined if this contract is being added
        if (!previousSecurityDirection && optionContract.orderLeg){
            const securityDirection = optionContract.orderLeg.securityDirection;
            if (optionChainExpiry){
                if(optionContract.securityType.isPut){
                    securityDirection.isLong ? optionChainExpiry.longPutSelections++ : optionChainExpiry.shortPutSelections++;
                }else{
                    securityDirection.isLong ? optionChainExpiry.longCallSelections++ : optionChainExpiry.shortCallSelections++;
                }
            }
        }else if(previousSecurityDirection){
            // this condition happens when a contract is removed from selection
            // then we need to reduce the count based on what it's previous selection was
            if (optionChainExpiry){
                if(optionContract.securityType.isPut){
                    previousSecurityDirection.isLong ? optionChainExpiry.longPutSelections-- : optionChainExpiry.shortPutSelections--;
                }else{
                    previousSecurityDirection.isLong ? optionChainExpiry.longCallSelections-- : optionChainExpiry.shortCallSelections--;
                }
            }
        }

    }

    private buildEquityOrderLeg(addLegPayload:AddLegPayload, order:Order){
        // don't use order.class here, as it's not udpated yet
        const orderLeg = new OrderLeg();
        orderLeg.symbol = order.symbol;
        orderLeg.underlying = order.symbol;
        orderLeg.securityType = SecurityType.Stock;
        // set the quantity to the gcd on the order
        const quantity: number = addLegPayload.quantity == 0 ? Constants.DefaultOrderQuantityStock : addLegPayload.quantity;
        orderLeg.quantity = quantity;
        orderLeg.securityDirection = SecurityDirection.Long;
        if(addLegPayload.isLong === false){
            orderLeg.quantity = quantity*-1;
            orderLeg.securityDirection = SecurityDirection.Short;
        }
        orderLeg.transactionType = addLegPayload.isClose ? TransactionType.Close : TransactionType.Open;
        orderLeg.quotable = this.tradeData.quotable as Quotable; // Quotable.getStockQuotable(order.symbol);
        orderLeg.selected = addLegPayload.selected;
        // get the rawQuote from symbol summary
        // orderLeg.quotable.rawQuote = appGlobals.getBrokerDataMap()
        order.legs.push(orderLeg);
    }

    private updateOrderClass(){
        const order = this.tradeData.order;
        const legs = this.tradeData.order.legs;
        if(legs.length == 0){
            order.class = OrderClass.None;
        }else if(legs.length == 1){
            order.class = legs[0].securityType == SecurityType.Stock ? OrderClass.Equity : OrderClass.Option;
        }else{
            const atleastOneStockLeg = legs.filter(leg => leg.securityType == SecurityType.Stock).length > 0;
            order.class = atleastOneStockLeg ? OrderClass.Combo : OrderClass.MultiLeg;
        }
    }

    private updateDurations(){
        let orderDurations: OrderDuration[] = [];
        if(this.tradeData.order.legs.length == 0){
            this.tradeData.durationSelections = [];
        }else if (this.tradeData.order.class == OrderClass.Equity){
            orderDurations = OrderDuration.enumValues as OrderDuration[];
        }else{
            orderDurations = OrderDuration.enumValues.filter(orderDuration => (orderDuration as OrderDuration).applicableForOptions) as OrderDuration[];
        }
        this.tradeData.durationSelections.splice(0, this.tradeData.durationSelections.length,
            ...orderDurations.map(duration => new VuetifySelection(duration.toString(), duration)));

    }

    /**
     * Invoked whenever there are leg changes
     * @private
     */
    private updateOrderTypes(){
        const order = this.tradeData.order as Order;
        const orderTypes = this.tradeData.orderTypes;
        orderTypes.splice(0, orderTypes.length);
        let orderTypeEnums : OrderType[] = [];
        if (order.legs.length == 1){
            orderTypeEnums = [OrderType.Market, OrderType.Limit, OrderType.Stop, OrderType.StopLimit];
            if (order.class == OrderClass.Option){
                orderTypeEnums.push(OrderType.RollingLimit);
            }
        }else if(order.legs.length > 1){
            orderTypeEnums = [OrderType.NetDebit, OrderType.NetCredit, OrderType.RollingLimit];
        }
        for(let orderTypeEnum of orderTypeEnums){
            orderTypes.push(new VuetifySelection(orderTypeEnum.label, orderTypeEnum));
        }
        // if the selected orderType is not in the list, then set a default
        // or if it is a multi-leg order, select the default because the trade could have changed from debit to credit or viceversa
        if (!orderTypeEnums.includes(this.tradeData.order.orderType) || order.class.hasTwoOrMoreLegs){
            this.setDefaultOrderType();
        }
    }

    private setDefaultOrderType(){
        const order = this.tradeData.order as Order;
        // set the selection in the dropdown based on the current legs
        if(order.legs.length > 1){
            if (order.currentPriceEffect == PriceEffect.Debit){
                order.orderType = OrderType.NetDebit;
            }else{
                order.orderType = OrderType.NetCredit;
            }
        }else{
            order.orderType = OrderType.Limit;
        }
    }

    private buildOptionOrderLeg(addLegPayload: AddLegPayload,order: Order){
        const selectedOptionContract = addLegPayload.selectedOptionContract!;
        // don't use order.class here, as it's not udpated yet
        const orderLeg = new OrderLeg();
        orderLeg.symbol = selectedOptionContract.symbol;
        orderLeg.underlying = selectedOptionContract.underlying;
        orderLeg.securityType = selectedOptionContract.securityType;
        // set the quantity to the gcd on the order
        const quantity: number = addLegPayload.quantity == 0 ? Constants.DefaultOrderQuantityOption : addLegPayload.quantity;
        orderLeg.quantity = quantity;
        if(addLegPayload.isLong === false){
            orderLeg.quantity = quantity*-1;
        }
        orderLeg.expiryDate = selectedOptionContract.expiryDate;
        orderLeg.strikePrice = selectedOptionContract.strikePrice;
        orderLeg.securityDirection = addLegPayload.isLong ? SecurityDirection.Long : SecurityDirection.Short;
        orderLeg.transactionType = addLegPayload.isClose ? TransactionType.Close : TransactionType.Open;
        // orderLeg and the OptionPair in OptionChain should refer to the same OptionContract
        orderLeg.quotable = selectedOptionContract; // Quotable.getDuplicateQuotable(selectedOptionContract);
        // set reverse reference
        selectedOptionContract.orderLeg = orderLeg;
        orderLeg.selected = addLegPayload.selected;
        selectedOptionContract.legSelected = addLegPayload.selected;
        // if the quotable already has a quote, add it too
        // orderLeg.quotable.rawQuote = selectedOptionContract.rawQuote;
        order.legs.push(orderLeg);
        // whenever a leg is added from an expiry, set the expiry in LRU map
        if(selectedOptionContract.optionPair){
            this.setOptionExpiryInLRUMap(selectedOptionContract.optionPair!.optionChainExpiry);
        }
    }

    updateOrderTotal(order: Order, analysis: TradeAnalysis, overridePriceLock:boolean = false){
        // const order =  this.tradeData.order as Order;
        // const analysis = this.tradeData.tradeAnalysis as TradeAnalysis;
        // the bid and ask are calculated as if you are a buyer,
        // but if your net effect is to sell, they need to be reversed
        let bid = BNUtil.ZERO;
        let ask = BNUtil.ZERO;
        let mid = BNUtil.ZERO;
        let delta = BNUtil.ZERO;
        let theta = BNUtil.ZERO;
        let gamma = BNUtil.ZERO;
        let vega = BNUtil.ZERO;

        // the complexity is with Combo Orders -
        // since the equity and option legs are at different scales, we need to normalize them to bring them on to the same scale
        // let's say we combine 10 long shares of MSFT (each at $200) with 4 STO contracts of MSFT Calls (each at $1)
        //option - 1 - keep the quantities at the same level and calculate GCD. So, the GCD will be 2.
        // Since we kept the quantities same, when calculating price, we need to bring them on to same scale -
        // MSFT Equity -  (10/GCD)*200  minus    MSFT Options -  (4/GCD)*2*100   =   (1000 - 400) = 600 for 1 quantity. Total = 600 * GCD = 1200
        //option - 2 - Get the quantities on to the same scale can calculate GCD. So, the GCP will be GCD (10,400) = 10
        // Since the quantities are at same scale, we can keep prices same -
        // MSFT Equity - (10/GCD)*200 [minus] MSFT Options - (400/GCD)*2   =   (200 - 80)  = 120 for 1 quantity. Total = 120 * GCD = 1200
        // TW uses option 2 and Generic broker uses option 1

        let gcd = 1;
        if (order.legs.length == 1){
            gcd = Math.abs(order.legs[0].quantity);
        } else{
            gcd = NumberUtil.gcdArray(..._map(order.legs, leg => Math.abs(leg.quantity)));
        }
        for(let leg of order.legs){
            let quantity = Math.abs(leg.quantity);
            let quantityFactor = quantity/gcd;
            const signedQuote = leg.quotable!.getSignedQuote(leg.quantity)!;
            // option - 1 - since we have calculated the gcd for a combo at the same scale (1 equity quantity mapped to 1 option quantity)
            // now to bring them on to the same scale, we have to multiply the rest of the values with 100 if it is an option leg
            let contractMultiplier = leg.securityType.isOption ? BNUtil.of(100) : BNUtil.of(1);
            // don't calculate mid for each leg separately, otherwise the rounding errors will add up
            // mid = mid.plus(signedQuote.price.multipliedBy(contractMultiplier).multipliedBy(quantityFactor));
            bid = bid.plus(signedQuote.bid.multipliedBy(contractMultiplier).multipliedBy(quantityFactor));
            ask = ask.plus(signedQuote.ask.multipliedBy(contractMultiplier).multipliedBy(quantityFactor));
            delta = delta.plus(signedQuote.delta.multipliedBy(contractMultiplier).multipliedBy(quantityFactor));
            theta = bid.plus(signedQuote.theta.multipliedBy(contractMultiplier).multipliedBy(quantityFactor));
            gamma = bid.plus(signedQuote.gamma.multipliedBy(contractMultiplier).multipliedBy(quantityFactor));
            vega = bid.plus(signedQuote.vega.multipliedBy(contractMultiplier).multipliedBy(quantityFactor));
        }
        mid = bid.plus(ask).dividedBy(2);
        // now normalize all of them back by dividing with 100
        if(order.class != OrderClass.Equity){
            bid = bid.dividedBy(100);
            ask = ask.dividedBy(100);
            mid = mid.dividedBy(100);
        }
        // Price is overwritten even when it is locked - when user makes leg changes like quantity, expiries, add/remove legs etc.,
        if (!order.priceLocked || overridePriceLock){
            // set the Rolling Limit Prices
            this.setRollingLimitOrderParams(bid, ask, mid);

            if (order.orderType == OrderType.RollingLimit){
                // use the same values as rollingLimit PriceStart
                order.price = order.rollingLimitParams.priceStart
                order.priceEffect = order.rollingLimitParams.priceStartEffect;
            }else{
                order.price = mid.abs();  // set this only if price not locked
                order.priceEffect = mid.gte(BNUtil.ZERO) ? PriceEffect.Credit : PriceEffect.Debit;
            }
            order.priceLocked = false;
        }
        // set the price on 'stop' field to the same as price
        // keep the stopPrice up to date with the price only if the current orderType is not set to Stop or Stop/Limit
        // Once the user selects stop/StopLimit, we'll start auto updating and let the user enter value
        if(order.orderType != OrderType.Stop && order.orderType != OrderType.StopLimit){
            order.stopPrice  = order.price;
            order.stopPriceEffect = order.priceEffect;
        }

        order.currentPrice = mid.abs();
        order.currentPriceEffect = mid.gte(BNUtil.ZERO) ? PriceEffect.Credit : PriceEffect.Debit;
        // if you are a net seller, reverse bid and ask
        if (order.currentPriceEffect == PriceEffect.Credit){
            const tempBid = bid;
            bid = ask;
            ask = tempBid;
        }
        order.currentPriceBid = bid.abs();
        order.currentPriceBidEffect = bid.gte(BNUtil.ZERO) ? PriceEffect.Credit : PriceEffect.Debit;
        order.currentPriceAsk = ask.abs();
        order.currentPriceAskEffect = ask.gte(BNUtil.ZERO) ? PriceEffect.Credit : PriceEffect.Debit;

        analysis.delta = delta;
        analysis.theta = theta;
        analysis.gamma = gamma;
        analysis.vega = vega;
    }

    /**
     * Invoked when updating order total to calculate RollingLimit Order Params
     * the big/ask/mid values are signed based on debit/credit
     * @param bid
     * @param ask
     * @param mid
     */
    setRollingLimitOrderParams(bid: BigNumber, ask: BigNumber, mid: BigNumber){
        const spread = ask.minus(bid).abs();

        const order = this.tradeData.order;
        const rollingLimitParams = order.rollingLimitParams;
        const startStopDiff = spread.multipliedBy(Constants.RollingLimitSpreadPriceFactor);
        const diffFromMid = startStopDiff.dividedBy(2);
        const increment = startStopDiff.multipliedBy(BNUtil.HUNDRED).multipliedBy(Constants.RollingLimitIncrementFactor);
        const priceStart = mid.plus(diffFromMid);
        const priceStop = mid.minus(diffFromMid);
        rollingLimitParams.priceStart = priceStart.abs();
        rollingLimitParams.priceStartEffect = priceStart.gte(BNUtil.ZERO) ? PriceEffect.Credit : PriceEffect.Debit;
        order.rollingLimitParams.priceStop1 = priceStop.abs();
        rollingLimitParams.priceStop1Effect = priceStop.gte(BNUtil.ZERO) ? PriceEffect.Credit : PriceEffect.Debit;
        order.rollingLimitParams.priceStop2 = priceStop.abs();
        rollingLimitParams.priceStop2Effect = priceStop.gte(BNUtil.ZERO) ? PriceEffect.Credit : PriceEffect.Debit;
        rollingLimitParams.adjustment1 = Math.max(increment.integerValue().toNumber(), 1);
    }

    // invoked when OrderType is changed
    orderTypeChanged(){
        // update the order totals so that Price values are updated if we are switching
        // back and forth from RollingLimit to other OrderTypes
        this.updateOrderTotal(this.tradeData.order as Order, this.tradeData.tradeAnalysis as TradeAnalysis, true);
    }

    // invoked when PriceStart is changed for a RollingLimit Order
    priceStartChanged(){
        // update the price to the same value as priceStart
        const order = this.tradeData.order as Order;
        order.price = order.rollingLimitParams.priceStart;
        order.priceEffect = order.rollingLimitParams.priceStartEffect;
        // invoke priceChanged which takes care of the rest of the logic
        this.priceChanged();
    }

    // invoked when limit price is changed
    priceChanged(){
        // when user updates price, the order.priceInput field is updated
        // if user updates the price, we need to lock it, so updated quotes don't change the price
        const order = this.tradeData.order as Order;
        order.priceLocked = true;
        if(!order.priceInput){
            // if user cleared the price, invoke updateOrderTotal which will set the price to the currentPrice based on the orderType
            this.updateOrderTotal(this.tradeData.order as Order, this.tradeData.tradeAnalysis as TradeAnalysis, true);
            // we should also reset the orderType for combo/multileg because user might have changed
            // the orderType from net debit to net credit which doesn't match the currentPriceEffect
            if (order.legs.length > 1 && order.orderType != OrderType.RollingLimit){
                this.setDefaultOrderType();
            }
        }else{
            order.price = BNUtil.of(order.priceInput);
            this.updateOrderTotal(this.tradeData.order as Order, this.tradeData.tradeAnalysis as TradeAnalysis, true);
        }
    }

    stopPriceChanged(){
        const order = this.tradeData.order as Order;
        if(!order.stopPriceInput){
            // if user cleared stopPrice, set it to currentPrice
            order.stopPrice = order.currentPrice;
            order.priceEffect = order.currentPriceEffect;
        }else{
            order.stopPrice = BNUtil.of(order.stopPriceInput);
        }
    }


    // invoked when leg quantity is changed
    legQuantityChanged(leg: OrderLeg){
        // the quantityStr field is updated on the leg
        if (leg.quantityStr ==  ""){
            // setting the quantity, will also update quantityStr
            let quantity = leg.securityType == SecurityType.Stock ? Constants.DefaultOrderQuantityStock : Constants.DefaultOrderQuantityOption;
            leg.quantity = leg.securityDirection == SecurityDirection.Short ? quantity * -1 : quantity;
        }else{
            // even though, the user might change the quantity sign (positive to negative), reset it back based on the SecurityDirection
            // that was originally chosen when adding the leg
            leg.quantity = leg.securityDirection == SecurityDirection.Short ? Math.abs(Number(leg.quantityStr))* -1 : Math.abs(Number(leg.quantityStr));
        }
        this.updateOrderTotal(this.tradeData.order as Order, this.tradeData.tradeAnalysis as TradeAnalysis, true);
        // also need to update the orderType
        if (this.tradeData.order.class.hasTwoOrMoreLegs){
            this.setDefaultOrderType();
        }
    }

    /**
     * User clicks on a contract that is already part of the legs,
     * this means the leg should be selected/unselected in TradeTicket as well as highlighted in OC
     * @param contract
     */
    toggleLegSelectionFromOC(optionContract: OptionContract){
        // only if the contract is already selected, toggle leg selection
        if(optionContract.selected){
            // the leg should exist
            const leg: OrderLeg = this.getOptionLegFromContract(optionContract)!;
            // toggle leg selection
            leg.selected = !leg.selected;
            // toggle selection in optionContract
            optionContract.legSelected = !optionContract.legSelected;
            this.setOptionExpiryInLRUMap(optionContract.optionPair!.optionChainExpiry);
        }
    }

    async toggleLegSelection(leg: OrderLeg){
        // let's toggle selection
        leg.selected = !leg.selected;
        if(leg.securityType.isOption){
            const optionContract = (leg.quotable as OptionContract);
            optionContract.legSelected = !optionContract.legSelected;
            // fire an event to focus the contract in the OptionChain if the leg is selected
            // this event is handled in OptionChain.vue
            if (leg.selected){
                await this.focusLegInOptionChain(leg);
            }
        }
    }

    // An Option leg stores the OC contract in leg.quotable
    // but to get to a leg from a contract, use this
    private getOptionLegFromContract(contract: OptionContract) : OrderLeg | undefined{
        const order = this.tradeData.order as Order;
        // find the quotable by reference
        return _find(order.legs, leg => leg.quotable == contract);
    }

    // sort legs in Order
    sortLegs(){
        if(this.tradeData.order.class == OrderClass.MultiLeg){
            const legs = this.tradeData.order.legs;
            legs.splice(0, legs.length, ..._sortBy(legs, leg => leg.expiryDate, leg => leg.strikePrice));
        }
    }

    /**
     * Invoked by Trade View whenever trade changes happen
     * @param updateQuotes - sometimes you want to replace the quotables, but not necessarily update the quotes immediately because we already have quotes
     * @private
     */
    private async refreshTradeQuotes(updateQuotes: boolean = true){
        let quotables = [this.tradeData.quotable as Quotable];
        quotables = quotables.concat(this.tradeData.order.legs.map(leg => leg.quotable! as Quotable))
        // add the quotables to quotableGroups to have them updated periodically
        appGlobals.userBrokerDataMap[this.tradeData.userAccount!.brokerUserId!].quotableGroups.trade = quotables;
        if (updateQuotes){
            await quoteService.updateQuotables(quotables);
        }
    }

    private async refreshOptionChainQuotes(updateQuotes: boolean = true){
        // update quotes of OptionExpiries that are currently in expanded status
        // Whenever an OptionExpiry is toggled for display, this method should be called to replace the list of quotes that need to be updated
        let quotables : Quotable[] = [];
        let quotablesFarOTM: Quotable[] = [];
        const expandedExpiries = this.tradeData.optionExpiries.filter(optionExpiry => optionExpiry.expanded) as OptionChainExpiry[] ;

        expandedExpiries.forEach(optionExpiry => {
            const puts = optionExpiry.optionPairs.map(optionPair => optionPair.put!);
            const calls = optionExpiry.optionPairs.map(optionPair => optionPair.call!);
            for (let put of puts){
                put.rawQuote.delta.abs().gt(BNUtil.of(0.05)) ? quotables.push(put) : quotablesFarOTM.push(put);
            }
            for (let call of calls){
                call.rawQuote.delta.abs().gt(BNUtil.of(0.05)) ? quotables.push(call) : quotablesFarOTM.push(call);
            }
        })
        const quotableGroups = appGlobals.userBrokerDataMap[this.tradeData.userAccount!.brokerUserId!].quotableGroups;
        quotableGroups.optionChain = quotables;
        quotableGroups.optionChainFarOTM = quotablesFarOTM;
        if (updateQuotes){
            await quoteService.updateQuotables(quotables);
        }
    }

    /**
     * Invoked when user 'Preview's order
     */
    async previewOrder(){
        this.tradeData.loadingPreview = true;
        const clonedOrder = this.cloneOrderForBroker();
        const brokerOrderService = BrokerFactory.getOrderService(this.tradeData.brokerUser!.broker!);
        const asyncResponse = await brokerOrderService.previewOrder(this.tradeData.brokerUser! as BrokerUser, clonedOrder);
        if(asyncResponse.status == AsyncStatus.Ok){
            this.tradeData.order.updatePriceDescription();
            this.tradeData.orderPreview = asyncResponse.data!;
            this.tradeData.loadingPreview = false;
            this.tradeData.mode == TradeMode.Edit ? this.tradeData.mode = TradeMode.EditPreview : this.tradeData.mode = TradeMode.Preview;
        }else{
            this.tradeData.loadingPreview = false;
            this.handleAsyncErrors(asyncResponse, "There was a problem with Order Preview");
        }
    }

    /**
     * Our version of Order is different from the Broker's version especially when it comes to RollingOrders
     * So, create clone for the broker instead of modifying our version
     * @private
     */
    private cloneOrderForBroker(){
        const order = this.tradeData.order as Order;
        const clonedOrder = new Order();
        clonedOrder.userAccount = order.userAccount;
        if (order.orderType == OrderType.RollingLimit){
            clonedOrder.price = order.rollingLimitParams.priceStart;
            if(order.legs.length > 1){
                clonedOrder.orderType = order.rollingLimitParams.priceStartEffect == PriceEffect.Debit ? OrderType.NetDebit : OrderType.NetCredit;
            }else{
                clonedOrder.orderType = OrderType.Limit;
            }
            clonedOrder.priceEffect = order.rollingLimitParams.priceStartEffect;
        }else{
            clonedOrder.orderType = order.orderType;
            clonedOrder.price = order.price;
        }
        clonedOrder.duration = order.duration;
        clonedOrder.stopPrice = order.stopPrice;
        clonedOrder.class = order.class;
        clonedOrder.symbol = order.symbol;
        // copy legs as is
        clonedOrder.legs.push(...order.legs);
        return clonedOrder;
    }

    cancelPreview(){
        if (this.tradeData.mode == TradeMode.EditPreview){
            this.tradeData.mode = TradeMode.Edit;
        }else{
            this.tradeData.mode = TradeMode.New;
        }
    }

    async placeOrder() : Promise<boolean>{
        const brokerOrderService = BrokerFactory.getOrderService(this.tradeData.brokerUser!.broker!);
        let asyncResponse = new AsyncResponse();
        this.tradeData.submittingOrder = true;
        if (this.tradeData.order.brokerOrderId.length > 0 && this.tradeData.mode == TradeMode.EditPreview){
            asyncResponse =  await brokerOrderService.modifyOrder(this.tradeData.brokerUser! as BrokerUser, this.tradeData.order as Order);
        }else if(this.tradeData.mode == TradeMode.Preview){
            const rollingLimitResponse = orderBackendService.createRollingLimitOrder(this.tradeData.order as Order);
            if(rollingLimitResponse){
                return false;
            }
            asyncResponse =  await brokerOrderService.placeOrder(this.tradeData.brokerUser! as BrokerUser, this.tradeData.order as Order);
        }else{
            // we shouldn't be in this situation
            return false;
        }
        if(asyncResponse.status == AsyncStatus.Ok){
            // if orderType is RollingLimit, we'll have to

            // if everything went well, brokerOrderId should be set in order
            eventEmitter.emit("showMessage", ["Order successfully placed"]);
            // let's force refresh orders
            await orderService.refreshOrders(this.tradeData.brokerUser as BrokerUser);
            this.resetTradeData();
            this.tradeData.submittingOrder = false;
            return true;
        }else{
            this.tradeData.submittingOrder = false;
            this.handleAsyncErrors(asyncResponse, "There was a problem with placing Order");
            return false;
        }
    }

    async createRollingLimitOrder(){

    }

    /**
     * Moves order legs from one expiry to next expiry
     * Works on all legs if none selected
     * @param step
     */
    async moveLegsToAdjacentExpiry(step: number = 1){
        let legs = this.tradeData.order.getSelectedLegsOrAll();
        // get option legs
        legs = legs.filter(leg => leg.securityType.isOption);
        // if increasing step, re-order the legs with later expiration first
        if(step > 0){
            legs = _sortBy(legs, leg => leg.expiryDate.getTime()*-1);
        }
        let firstTargetContract : OptionContract | undefined = undefined;
        for(let leg of legs){
            if (!leg.canMove){
                continue;
            }
            const optionExpiry = (leg.quotable as OptionContract).optionPair!.optionChainExpiry;
            // get adjacent expiry
            const adjacentOptionExpiry = this.getAdjacentOptionExpiry(optionExpiry, step);
            if (adjacentOptionExpiry){
                // load optionPairs
                await this.loadOptionPairsForExpiry(adjacentOptionExpiry, false);
                // find the nearest strike for current leg
                const targetStrike = BNUtil.getNearest(adjacentOptionExpiry.strikes, leg.strikePrice);
                const targetOptionPair = adjacentOptionExpiry.getOptionPair(targetStrike);
                const targetContract = leg.securityType.isPut ? targetOptionPair.put! : targetOptionPair.call!;
                // if the matchingContract already has a leg, dont' perform the move
                if (!targetContract.selected){
                    // replace old with new one
                    this.replaceLegWithNewContract(leg, targetContract);
                    // also expand and open up the new OptionExiry
                    this.expandChainAndRefresh(adjacentOptionExpiry);
                    if(!firstTargetContract){
                        firstTargetContract = targetContract;
                    }
                }
            }
        }
        // focus the first contract that moved
        if(firstTargetContract){
            this.tradeData.eventEmitter.emit("focusContractWithDelay", firstTargetContract);
        }
    }

    private getAdjacentOptionExpiry(optionExpiry: OptionChainExpiry, step: number): OptionChainExpiry | undefined{
        const optionExpiries = this.tradeData.optionExpiries;
        // should be ok to compare by reference
        const currentOptionPairIndex = optionExpiries.findIndex(ocExpiry => ocExpiry == optionExpiry);
        const adjacentExpiryIndex = currentOptionPairIndex + step;
        return adjacentExpiryIndex < 0 || adjacentExpiryIndex >= optionExpiries.length ? undefined : optionExpiries[adjacentExpiryIndex] as OptionChainExpiry;
    }


    /**
     * Move all legs or only selected legs to next strike based on step
     * @param step
     */
    async moveLegsToNextStrike(step: number = 1){
        let legs = this.tradeData.order.getSelectedLegsOrAll();
        // get option legs
        legs = legs.filter(leg => leg.securityType.isOption);
        // if increasing step, re-order the legs with higher strike first
        if(step > 0){
            legs = _sortBy(legs, leg => leg.strikePrice.negated().toNumber());
        }
        for(let leg of legs){
            await this.moveLegToNextStrike(leg.quotable as OptionContract, step);
        }
    }

    /**
     *
     * @param optionContract
     * @param step - step tells us by how many strikes we have to move. Positive number moves up strikes, negative number moves down
     */
    async moveLegToNextStrike(optionContract: OptionContract, step: number = 1){
        if(!optionContract.canMove){
            return 0;
        }
        // stepsMoved indicates the number of steps we actually moved to get to target. starts with 0.
        let stepsMoved: number = 0;
        // get a list of OptionPairs in selected expiry
        const optionChainExpiry: OptionChainExpiry = this.tradeData.getOptionExpiryByDate(optionContract.expiryDate)!;
        const optionPairs: OptionPair[] = optionChainExpiry.optionPairs;
        const currentOptionPairIndex = optionPairs.findIndex(optionPair => optionPair.strike.eq(optionContract.strikePrice));
        let targetOptionPairIndex = currentOptionPairIndex + step;
        while(true){
            if (targetOptionPairIndex >= 0 && targetOptionPairIndex <= optionPairs.length - 1){
                // a valid optionPair exists
                const targetPair = optionPairs[targetOptionPairIndex];
                const targetContract: OptionContract = optionContract.securityType.isPut ? targetPair.put! : targetPair.call!;
                // the target contract shouldn't already be in selected status, if it is, we need to jump over it
                if (!targetContract.selected){
                    const orderLeg = optionContract.orderLeg!;
                    this.replaceLegWithNewContract(orderLeg, targetContract);
                    stepsMoved = stepsMoved + step;
                    break;
                }else{
                    stepsMoved = stepsMoved + step;
                    targetOptionPairIndex = targetOptionPairIndex + step;
                }
            }else{
                break;
            }
        }
        return stepsMoved;
    }

    /**
     * This method is invoked when an orderLeg is moved (same expiry or adjacent expiry) from one contract to a different
     * @param orderLeg
     * @param newContract
     * @private
     */
    private replaceLegWithNewContract(orderLeg: OrderLeg, newContract: OptionContract){
        const isLong: boolean =  orderLeg.securityDirection.isLong;
        // don't await for async calls, because doing so will make the strike move jerky
        this.removeOptionContractFromOrder(orderLeg.quotable as OptionContract);
        const addLegPayload = new AddLegPayload();
        addLegPayload.isEquityLeg = false;
        addLegPayload.selectedOptionContract = newContract;
        addLegPayload.isLong = isLong;
        // don't have to retain the sign on quantity, it'll be set based on addLegPayload.isLong indicator
        addLegPayload.quantity = Math.abs(orderLeg.quantity);
        // don't have to refresh quotes, let it refresh during next run
        addLegPayload.refreshQuotes = false;
        addLegPayload.selected = orderLeg.selected;
        this.addLegToOrder(addLegPayload);
    }

    /**
     * Adjust quantity when the up/down arrows in 'Trade Controls' bar are used
     * Affects all legs if none selected
     * @param change
     */
    async adjustQuantity(change: number = 1){
        let legs = this.tradeData.order.getSelectedLegsOrAll();
        const order = this.tradeData.order as Order;
        let gcd = 1;
        if (legs.length == 1){
            gcd = Math.abs(legs[0].quantity);
        } else{
            gcd = NumberUtil.gcdArray(..._map(legs, leg => Math.abs(leg.quantity)));
        }
        if (legs.length == 1 && legs[0].securityType.isStock){
            if (gcd == Constants.DefaultOrderQuantityStock && change < 0){
                return;
            }
            const leg = this.tradeData.order.legs[0] as OrderLeg;
            let absQuantity = Math.abs(leg.quantity);
            leg.quantity = absQuantity + ((absQuantity/gcd)*change*Constants.DefaultOrderQuantityStock);
            this.legQuantityChanged(leg);
        }else{
            // if gcd is 1, we cannot reduce the quantity
            if (gcd == 1 && legs.length > 0 && change < 0){
                return;
            }
            for (let leg of legs){
                let absQuantity = Math.abs(leg.quantity);
                let newQuantity = absQuantity + ((absQuantity/gcd)*change);
                leg.quantity = newQuantity;
                // set the new quantity on leg
                this.legQuantityChanged(leg);
            }
        }
    }

    /**
     * Everytime a leg is chosen from an OptionExpiry or if it is expanded, we set it in the map
     * @param optionChainExpiry
     * @private
     */
    private setOptionExpiryInLRUMap(optionChainExpiry: OptionChainExpiry){
        this.tradeData.lruOptionExpiries.set(optionChainExpiry.key, optionChainExpiry);
    }

    private removeOptionExpiryFromLRUMap(optionChainExpiry: OptionChainExpiry){
        this.tradeData.lruOptionExpiries.delete(optionChainExpiry.key);
    }

    private removeOptionExpiryFromDisplay(optionChainExpiry: OptionChainExpiry){
        // hide all the children of this expiry
        optionChainExpiry.optionPairs.forEach(optionPair => optionPair.show = false);
        // optionChainItems.splice(optionChainItems.indexOf(optionChainExpiry)+1, optionChainExpiry.optionPairs.length);
        optionChainExpiry.expanded = false;
    }

    applyOptionChainFilters(){
        this.tradeData.optionExpiries.forEach(optionExpiry => {
            if(optionExpiry.expanded){
                this.applyOptionChainStrikeFilters(optionExpiry as OptionChainExpiry);
            }
        })
    }

    applyOptionChainStrikeFilters(optionChainExpiry: OptionChainExpiry){
        const filters = this.tradeData.optionChainFilters as OptionChainFilters;
        // let's first show them all
        optionChainExpiry.optionPairs.forEach(pair => pair.show = true);
        if (filters.noOfStrikes != "All"){
            // get atm strike index and move up and down
            const noOfStrikesInt = Number.parseInt(filters.noOfStrikes);
            const atmStrike = optionChainExpiry.atmStrike;
            const atmStrikeIndex = optionChainExpiry.strikes.findIndex(strike => strike.eq(atmStrike));
            const startingStrikeIndex = atmStrikeIndex - noOfStrikesInt/2;
            const endingStrikeIndex = startingStrikeIndex + noOfStrikesInt;
            optionChainExpiry.optionPairs.forEach((pair, index) => {
                if (index >= startingStrikeIndex && index <= endingStrikeIndex){
                    pair.show = true;
                }else{
                    pair.show = false;
                }
            })

            const subsetOptionPairs = optionChainExpiry.optionPairs.slice(startingStrikeIndex, noOfStrikesInt);

        }
    }

    async applyTradeTemplate(tradeTemplate: TradeTemplate){
        // turn on loading indicator
        this.tradeData.optionChainGridLoadingIndicator = true;
        for(let templateLeg of tradeTemplate.templateLegs){
            await this.applyTemplateLeg(templateLeg);
        }
        // highlight the first leg in OC
        for(let leg of this.tradeData.order.legs){
            if (leg.securityType.isOption){
                this.tradeData.eventEmitter.emit("focusContractWithDelay", leg.quotable as OptionContract);
                break;
            }
        }
        this.tradeData.optionChainGridLoadingIndicator = false;
    }

    private async applyTemplateLeg(templateLeg: TemplateLeg){

        const addLegPayload = new AddLegPayload();
        let matchingContract : OptionContract | undefined = undefined;

        if (templateLeg.securityType?.isOption) {
            // identify OptionExpiry
            const dteArray: number[] = this.tradeData.optionExpiries.map(expiry => expiry.daysToExpiry)
            const nearestDte = NumberUtil.getNearest(dteArray, templateLeg.daysToExpiry);
            const optionExpiry = this.tradeData.optionExpiries.find(optionExpiry => optionExpiry.daysToExpiry == nearestDte)! as OptionChainExpiry;

            // identify strike with in expiry
            await this.loadOptionPairsForExpiry(optionExpiry, true);
            // find nearest strike by delta
            const optionContracts: OptionContract[] = templateLeg.securityType == SecurityType.Put ? optionExpiry.optionPairs.map(pair => pair.put!) : optionExpiry.optionPairs.map(pair => pair.call!);


            if (templateLeg.strikeSelectionCriteria == StrikeSelectionCriteria.Delta) {
                // our quote deltas in decimals, so divide by 100
                const targetDelta = templateLeg.delta.dividedBy(100);
                // use absolute delta values for all comparisions
                matchingContract = optionContracts.reduce((previous, current) =>
                    current.rawQuote.delta.abs().minus(targetDelta).abs().lt(previous.rawQuote.delta.abs().minus(targetDelta).abs()) ? current : previous);
            } else if (templateLeg.strikeSelectionCriteria == StrikeSelectionCriteria.PercentOfUnderlying) {

                const factorOfUnderlying = templateLeg.percentOfUnderlying.dividedBy(BNUtil.HUNDRED);
                const stockPrice = this.tradeData.quotable!.rawQuote.price;
                matchingContract = optionContracts.reduce((previous, current) => {
                    return current.strikePrice.dividedBy(stockPrice).minus(factorOfUnderlying).abs().lt(previous.strikePrice.dividedBy(stockPrice).minus(factorOfUnderlying).abs()) ? current : previous;
                })
            }
        }

        addLegPayload.isEquityLeg = templateLeg.securityType!.isStock;
        addLegPayload.isLong = templateLeg.securityDirection == SecurityDirection.Long ? true : false;
        // don't have to retain the sign on quantity, it'll be set based on addLegPayload.isLong indicator
        addLegPayload.quantity = Math.abs(templateLeg.quantity);
        // don't have to refresh quotes, let it refresh during next run
        addLegPayload.refreshQuotes = false;
        addLegPayload.selected = false;
        if(templateLeg.securityType!.isOption){
            addLegPayload.selectedOptionContract = matchingContract;
        }
        await this.addLegToOrder(addLegPayload);
    }

}


export const tradeService = new TradeService();
