// useful for populating selection/auto-complete and combo boxes
import {AsyncStatus} from "@/data/EnumData";

export class VuetifySelection {
    text = "";
    value = "";
    indeterminate:boolean = false;

    constructor(text: string, value: any, indeterminate: boolean = false){
        this.text =text;
        this.value = value;
        if(indeterminate)
            this.indeterminate = indeterminate;
    }
}

export class AsyncResponse<T = any>{
    data?: T;
    status: AsyncStatus = AsyncStatus.Ok;
    businessErrors: string[] = [];
    unknownErrors: string[] = [];
}


export type GlobalEvents = {
    positionsUpdated: string;
    quotesLoaded: string;
    positionsLoaded: string;
    accountsLoaded: string;
    ordersLoaded: string;
    toggleWatchlists: string;
    showMessage: string[];
    showWarning: string[];
    showError: string[];
}