







































































































import {defineComponent, onMounted, reactive, ref, SetupContext, watch} from "@vue/composition-api";
import {tradeService} from "@/services/TradeService";

export default defineComponent({
  name: "TradePreviewSection",
  setup(props: undefined, context: SetupContext) {

    const tradeData = tradeService.tradeData;

    function cancelPreview(){
      tradeService.cancelPreview();
    }

    function placeOrder(){
      tradeService.placeOrder().then(function(success: boolean){
        if(success){
          // redirect to orders screen
          context.root.$router.push("/main/orders");
        }
      })
    }


    return {
      tradeData,
      cancelPreview,
      placeOrder
    }
  }
});


