import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/home/Home.vue";
import CognitoAuth from "@/views/home/auth/CognitoAuth.vue";
import HomeLanding from "@/views/landing/HomeLanding.vue";
import Main from "@/views/main/Main.vue";
import PositionsMain from "@/views/positions/PositionsMain.vue";
import TradeMain from "@/views/trade/TradeMain.vue";
import UserMain from "@/views/user/UserMain.vue";
import OrdersMain from "@/views/orders/OrdersMain.vue";
import {SetupContext} from "@vue/composition-api";
import {appGlobals} from "@/data/AppData";
import {authService} from "@/services/AuthService";
import {User} from "@/data/UserData";
import {userService} from "@/services/UserService";
import secondsDifference from 'date-fns/differenceInSeconds'

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: Home,
    children: [
      {
        name: "Auth",
        path: "/auth",
        component: CognitoAuth
      },
      {
        name: "Landing",
        path: "home",
        component: HomeLanding
      },
      {
        name: "Home",
        path: "/",
        redirect: "home"
      }
    ]
  },
  {
    path: "/main",
    name: "Main",
    component: Main,
    children: [
      {
        name: "Positions",
        path: "positions",
        component: PositionsMain
      },
      {
        name: "Trade",
        path: "trade",
        component: TradeMain
      },
      {
        name: "Orders",
        path: "orders",
        component: OrdersMain
      },
      {
        name: "User",
        path: "user",
        component: UserMain
      },
      {
        name: "main",
        path: "",
        redirect: "positions"
      }
    ]
  }
];


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// invoked before each router navigation or when page refreshes
router.beforeEach((to, from, next) => {
  if (to.path.startsWith("/main")){
    revalidateSession(next);
  }else{
    next();
  }
})

let lastSessionRefreshTime: Date = new Date();
const sessionRefreshInterval : number = 50*60;  // setting to refresh every 50 minutes
async function revalidateSession(next: any) {
  const timeNow : Date = new Date();
  // even if user exists in globals, the session needs validation
  if (appGlobals.user == null || secondsDifference(timeNow, lastSessionRefreshTime) >= sessionRefreshInterval) {
    console.log("Attempting to refresh session");
    const isValid: boolean = await authService.refreshSession();
    lastSessionRefreshTime = new Date();
    if (isValid) {
      console.log("Session refreshed and valid.");
      if (appGlobals.user == null){
        // this happens when page is refreshed, so start the full user sessions
        const user: User = await userService.startUserSession();
      }else{
        const user: User = await userService.getUserDetails();
      }
      next();
    } else {
      console.log("Session invalid. Redirecting to login.");
      // no valid user session, go to auth
      next("/auth");
    }
  }else{
    next();
  }
}


export default router;
