import {AsyncResponse} from "@/data/CommonData";
import {AsyncStatus} from "@/data/EnumData";
import {AxiosError} from "axios";


export default class BaseBackendService{

    captureErrors(exception: any, asyncResponse: AsyncResponse){
        if (exception && "response" in exception) {
            // that means the exception is an AxiosError Object and exception.response is a AxiosResponse object
            const errorData = exception.response.data;
            // our backend returns all errors (400,500) in an {errors: []} object
            if ("errors" in errorData){
                asyncResponse.businessErrors = errorData.errors;
            }
        }else {
            if (exception instanceof Error){
                asyncResponse.unknownErrors = [exception.message];
            }else if(typeof exception === "string"){
                asyncResponse.unknownErrors = [exception];
            }
        }
        asyncResponse.status = AsyncStatus.Error;
    }

}
