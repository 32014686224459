import _map from "lodash/map";
import _find from "lodash/find";
import _flatMap from "lodash/flatMap";
import {Transform, Type} from 'class-transformer';
import {Constants} from "@/data/common/Constants";
import {AccountIconIndexMapping, Broker} from "@/data/EnumData";
import {PositionItem} from "@/data/PositionsData";
import {QuotableGroups} from "@/data/QuoteData";
import {AccountBalances, AccountProfile, BrokerAccount} from "@/data/AccountData";
import {reactive} from "@vue/composition-api";
import {Order} from "@/data/OrdersData";

export class BaseData{
  // a Nan is translated to null in json and appropriately set to null in java object
  // id: Number = new Number(NaN);
  id?: number;

}

export class User extends BaseData {
  oauthId = "";
  email = "";
  name = "";
  @Type( () => UserPrivilege)
  userPrivileges: UserPrivilege[] = [];

  @Type( () => UserPreferences)
  userPreferences?: UserPreferences;

  @Type( () => BrokerUser)
  brokerUsers: BrokerUser[] = [];

  brokerUserMap = new Map<number, UserAccount>();

  // map of userAccountId and userAccount
  userAccountMap = new Map<number, UserAccount>();

  getPrimaryAccount() : UserAccount |undefined{
    return _find(_flatMap(this.brokerUsers, brokerUser => brokerUser.userAccounts) , account => account.primary);
  }

  getBrokerUser(brokerUserId: string): BrokerUser | undefined{
    return _find(this.brokerUsers, brokerUser => brokerUser.brokerUserId == brokerUserId);
  }

}

export class UserPrivilege extends BaseData{
  privilege = "";
}

export class UserPreferences extends BaseData {
  positionColumns: string = ""; // CSV of position column keys
  watchlistColumns: string = ""; // CSV of watchlist column keys
  optionChainColumns: string = "";
  ocNoOfStrikesFilter = "All";
  // positionColumnsArr: string[] = Constants.DefaultPositionColumns;
}

/**
 * Each User can have multiple multiple BrokerUser profiles
 * Each broker profile can have multiple UserAccounts
 */
export class BrokerUser extends BaseData{
  broker: Broker | undefined;
  brokerUserId: string = "";  // userId maintained by the broker
  brokerUserProfileName: string = ""; // it's the name of the user at the broker (don't confuse with username/userid)
  accessToken: string = "";
  @Type( () => UserAccount)
  userAccounts: UserAccount[] = [];
}

export class UserAccount  extends BaseData {
  accountNumber: string | undefined; // accountNumber from the broker
  accountName : string | undefined;
  private _iconIndex = 0;  // this index identifies the icon/color combination to display for the UserAccount. Get the mapping color from EnumData.AccountIconIndexMapping
  iconColor: string | undefined;
  accountShortName: string | undefined;
  accountType : string | undefined;
  primary = false;
  brokerUserId: string | undefined; // refers back to the brokerUserId // not using BrokerUser object to avoid circular ref issue

  get iconIndex(): number {
    return this._iconIndex;
  }

  set iconIndex(value: number) {
    this._iconIndex = value;
    this.iconColor = AccountIconIndexMapping[this._iconIndex];
  }
}

// Cache of data for each BrokerUser
export class UserBrokerData {
  positions: PositionItem[] = [];
  quotableGroups: QuotableGroups = new QuotableGroups();
  accounts: BrokerAccount[] = [];
  orders: Order[] = [];
}

export class SettingsColumnConfig{
  key = "";  // column id used in the backend
  headerName = "";  // Name to display in the column header
  fullName = "";  // Name to display in the Column DnD selection dialog

  constructor(key:string,headerName:string, fullName:string){
    this.key = key;
    this.headerName = headerName;
    this.fullName = fullName;
  }
}

