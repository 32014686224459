





















































import {defineComponent, onMounted, reactive, ref, SetupContext, watch} from "@vue/composition-api";
import {PositionColumn, PositionItem} from "@/data/PositionsData";
import {SettingsColumnConfig, UserPreferences} from "@/data/UserData";
import {appGlobals} from "@/data/AppData";
import { Container, Draggable } from "vue-dndrop";

interface Props{
  showDialog: boolean,
  selectedCols: SettingsColumnConfig[],
  availableCols: SettingsColumnConfig[]
}

export default defineComponent({
  name: "ColumnSettings",
  components: {Container, Draggable},
  props: {
    showDialog: Boolean,
    selectedCols: Array,
    availableCols: Array
  },
  setup(props: Props, context: SetupContext) {

    function save(){
      let finalCols =props.selectedCols.map(col => col.key);
      context.emit("input", false, finalCols);
    }

    function onDrop(collection: SettingsColumnConfig[], dropResult:any) {
      collection.splice(0, collection.length, ...applyDrag(collection, dropResult));
    }

    // this event is called
    function cancelDialog(){
      console.log("Cancel called");
      context.emit("close");
    }

    function closeDialog(){
      console.log("Close called");
      context.emit("close");
    }

    watch(() => props.showDialog, (value) => {
      if (value){
        context.root.$modal.show("my-first-modal");
      }else{
        console.log("hiding the modal");
        context.root.$modal.hide("my-first-modal");
      }
    })

    return {
      onDrop,
      save,
      closeDialog,
      cancelDialog
    }
  }
})

const applyDrag = (arr: SettingsColumnConfig[], dragResult: any) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};



