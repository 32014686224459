






































import {defineComponent, onMounted, reactive, SetupContext} from "@vue/composition-api";
import {tradeService} from "@/services/TradeService";
import {appGlobals} from "@/data/AppData";
import {Portal} from "@linusborg/vue-simple-portal";
import {OptionChainColumn} from "@/data/TradeData";
import {userService} from "@/services/UserService";
import {AsyncStatus} from "@/data/EnumData";

interface Props{
  symbol: string
}

class ColumnProps{
  // boolean whether to show/hide column selector menu popup
  columnMenu = false;
  // id of the column (th) that the menu will use as activator
  columnMenuActivator = "";
  // columns available from selection from all columns
  availableColumns: OptionChainColumn[] = [];
  // indicates whether put or call side column is selected for change
  // valid values are put or call
  selectedColumnSide = "";
  // the index of the <th> column that is being changed
  selectedColumnIndex: number | undefined;
  // the column that is selected from the menu list, refers to OptionChainColumn.key
  newColumnKey: string | undefined;
  // an incrementor for the item-group-list without which the v-model on the item-group doesn't match the list-item that is highlighted
  // https://github.com/vuetifyjs/vuetify/issues/11405
  itemGroup = 0;
}

export default defineComponent({
  name: "OptionChainHeader",
  components: {Portal},
  props: {
  },
  setup(props: Props, context: SetupContext) {

    const tradeData = tradeService.tradeData;
    const columnsConfig = tradeData.optionChainColumnsConfig;
    const columnProps = reactive(new ColumnProps());

    function setupHeaders(){
      const userPreferences = appGlobals.user?.userPreferences;
      const optionChainColumnsStr = userPreferences!.optionChainColumns;
      const preferredColumns = optionChainColumnsStr.split(",").map(columnKey => columnsConfig.allColumnsDict[columnKey])
      columnsConfig.preferredColumns.splice(0, columnsConfig.preferredColumns.length, ...preferredColumns);
    }

    function showAvailableColumnsMenu(event: Event, selectedColumnSide: string, selectedColumnIndex: number, columnBeingChanged:OptionChainColumn){
      columnProps.columnMenuActivator = `#th-${selectedColumnSide}-${selectedColumnIndex}`;
      columnProps.availableColumns.splice(0, columnProps.availableColumns.length);
      columnProps.selectedColumnSide = selectedColumnSide;
      columnProps.selectedColumnIndex = selectedColumnIndex;
      // enable the menu to display it
      // columnProps.columnMenu = true;

      for(let column of columnsConfig.allColumnsList){
        // add all columns by excluding the current column
        if (column != columnBeingChanged){
          columnProps.availableColumns.push(column);
        }
      }
      // if event propagation is not stopped, menu activation behaves weirdly
      event.stopImmediatePropagation();
      event.stopPropagation();
      // wait for the nextTick before opening up the menu
      // otherwise sometimes the menu opens up at previous header instead of the current header
      context.root.$nextTick().then(function(){
        // enable the menu to display it
        columnProps.columnMenu = true;
      });
    }

    async function selectNewColumn(){
      // the selected column values are already set in columnsProps.selectNewColumn and columnProps.selectedColumnSide
      console.log("Selected column key - "+columnProps.newColumnKey);
      let optionChainColumns = appGlobals.user!.userPreferences!.optionChainColumns;
      const optionChainColumnsArray = optionChainColumns.split(",");
      if (columnProps.selectedColumnSide == "call"){
        optionChainColumnsArray.splice(columnProps.selectedColumnIndex!, 1, columnProps.newColumnKey!);
      }else if (columnProps.selectedColumnSide == "put"){
        // since the put columns are rendered in reverse, we need to splice from the end
        optionChainColumnsArray.splice(-1 - columnProps.selectedColumnIndex!, 1, columnProps.newColumnKey!);
      }
      optionChainColumns = optionChainColumnsArray.join(",");
      // let's save the userPreferences with
      appGlobals.user!.userPreferences!.optionChainColumns = optionChainColumns;
      const asyncResponse = await userService.updateUserPreferences();
      // whether userPreferences to successfully changed or not, just continue with local changes
      columnProps.newColumnKey = undefined;
      // just increment this value to force list-item-group and list-items to recognize the change in model value
      columnProps.itemGroup = columnProps.itemGroup + 1;
      setupHeaders();
    }

    // watch(() => columnMenu.value, () => {
    //   console.log("column menu value - "+columnMenu.value);
    //   if(!columnMenu.value){
    //     columnMenuActivator.value = "";
    //   }
    // });


    onMounted(() => {
      setupHeaders();
    })

    return {
      columnProps,
      columnsConfig,
      showAvailableColumnsMenu,
      selectNewColumn,
    }
  }
});


