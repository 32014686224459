import {Quotable} from "@/data/QuoteData";
import {TableColumnDatatype, TableColumnType} from "@/data/EnumData";
import _sortBy from "lodash/sortBy";

export class StockWatchlist {

    id: number = 0;
    groupName: string = "";
    name: string = "";
    private _symbols: string = "";
    public: boolean = false;
    quotables: Quotable[] = [];

    set symbols(value:string) {
        this._symbols = value;
        if(value) // this allows for watch lists without symbols
            this.quotables.splice(0,this.quotables.length, ...value.split(",").map(symbol => Quotable.getStockQuotable(symbol)));
    }
}

export class WatchlistColumnsConfig{
    allColumnsList: WatchlistColumn[] = []
    allColumnsDict : {[key: string]: WatchlistColumn} ={};
    allColumnsDictByProperty: {[key: string]: WatchlistColumn} ={};

    constructor(){
        this.allColumnsList = _sortBy(watchlistColumnsList, item => item.fullName);
        this.allColumnsList.forEach(watchlistColumn => this.allColumnsDict[watchlistColumn.key] = watchlistColumn);
        this.allColumnsList.forEach(watchlistColumn => this.allColumnsDictByProperty[watchlistColumn.property] = watchlistColumn);
    }
}

/**
 * Defines the properties of a dynamic column in the Watchlist grid
 */
export class WatchlistColumn{
    key = "";  // column id used in the backend
    headerName = "";  // Name to display in the column header
    fullName = "";  // Name to display in the Column DnD selection dialog
    //
    type: TableColumnType = TableColumnType.String;
    datatype: TableColumnDatatype = TableColumnDatatype.BigNumber; // datatype used in sorting. most of the columns are of type BigNumber
    showColor = true;
    property = "";          // property form PositionItem object that is used for comparison, sorting etc., In some cases both displayProperty and property could refer to the same property. For example - daysToExpiry
    displayProperty = "";   // property from PositionItem object that is actually displayed on screen. For example - displayProperty would point to 'deltaPerUnitStr' where as property would point to 'deltaPerUnit'
    showIcon?: boolean = false;
    icon?: string = "";
}

// Last("Last"),
// Change("Chg"),
// ChangePercentage("Chg%"),
// Volume("Vol"),
// Bid("Bid"),
// Ask("Ask");

const watchlistColumnsList : WatchlistColumn[] = [
    {
        key: "Last",
        headerName:"Last",
        fullName: "Last",
        type: TableColumnType.String,
        datatype: TableColumnDatatype.BigNumber,
        showColor: false,
        property: "last",
        displayProperty: "lastStr",
    },
    {
        key: "Change",
        headerName:"Chg",
        fullName: "Change",
        type: TableColumnType.String,
        datatype: TableColumnDatatype.BigNumber,
        showColor: true,
        property: "change",
        displayProperty: "changeStr",
    },
    {
        key: "ChangePercentage",
        headerName:"Chg%",
        fullName: "Change Percentage",
        type: TableColumnType.String,
        datatype: TableColumnDatatype.BigNumber,
        showColor: true,
        property: "changePercentage",
        displayProperty: "changePercentageStr",
    },
    {
        key: "Volume",
        headerName:"Vol",
        fullName: "Vol",
        type: TableColumnType.String,
        datatype: TableColumnDatatype.BigNumber,
        showColor: false,
        property: "volume",
        displayProperty: "volumeStr",
    },
    {
        key: "Bid",
        headerName:"Bid",
        fullName: "Bid",
        type: TableColumnType.String,
        datatype: TableColumnDatatype.BigNumber,
        showColor: false,
        property: "bid",
        displayProperty: "bidStr",
    },
    {
        key: "Ask",
        headerName:"Ask",
        fullName: "Ask",
        type: TableColumnType.String,
        datatype: TableColumnDatatype.BigNumber,
        showColor: false,
        property: "ask",
        displayProperty: "askStr",
    }
]

export const watchlistColumnsConfig = new WatchlistColumnsConfig();