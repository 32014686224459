




















import {defineComponent, onMounted, reactive, ref, SetupContext} from "@vue/composition-api";
import UserPreferences from "@/views/user/UserPreferences.vue";
import UserAccounts from "@/views/user/UserAccounts.vue";

export default defineComponent({
  name: "UserMain",
  components: {
    UserPreferences,
    UserAccounts
  },
  setup(props: undefined, context: SetupContext) {
    let selected_tab = ref<string>("user-accounts");
    return {
      selected_tab
    }
  }
});

