
































































































































import {defineComponent, onMounted, reactive, ref, SetupContext} from "@vue/composition-api";
import {DataTableHeader} from "vuetify";
import {watchlistService} from "@/services/WatchlistService";
import {Quotable} from "@/data/QuoteData";
import {StockWatchlist,watchlistColumnsConfig} from "@/data/WatchlistData";
import _filter from "lodash/filter";
import {quoteService} from "@/services/QuoteService";
import {eventEmitter} from "@/main";
import {useWatchlistColumns} from "@/views/watchlists/composables/useWatchlistColumns";
import ColumnSettings from "@/views/user/ColumnSettings.vue";
import SymbolItemDisplay from "@/views/positions/SymbolItemDisplay.vue";
import PositionTags from "@/views/positions/PositionTags.vue";



class AddEditWatchlistManager{
  show = false;
  symbolsToAdd = "";
  invalidSymbols = "";
  // the watchlist currently being edited/created
  // this is copy of the selectedWatchlist when user is editing a watchlist
  watchlist = new StockWatchlist();
  context: SetupContext;
  loadWatchlistsFn : () => void;

  constructor(context: SetupContext, loadWatchlistsFn: () => void) {
    this.context = context;
    this.loadWatchlistsFn = loadWatchlistsFn;
  }

  loadEditDialog(selectedWatchlist: StockWatchlist) {
    this.show = !this.show;
    // keep the original selectedWatchlist, copy fields
    this.watchlist.id = selectedWatchlist.id;
    this.watchlist.name = selectedWatchlist.name;
    this.watchlist.groupName = selectedWatchlist.groupName;
    this.watchlist.quotables.splice(0, this.watchlist.quotables.length,...selectedWatchlist.quotables);
  }

  deleteSymbol(symbol:string) {
    this.watchlist.quotables.splice(0, this.watchlist.quotables.length,..._filter(
        this.watchlist.quotables, quotable => quotable.symbol.toUpperCase() != symbol.toUpperCase()));
    console.log("length: "+this.watchlist.quotables.length);
  }

  /**
   * add symbols that are entered by user into watchlist
   */
  addSymbols() {
    //alert("adding symbols : " + this.symbolsToAdd);
    console.log("old size: " + this.watchlist.quotables.length);
    let quotables: Quotable[] = [];
    if (this.symbolsToAdd) {
      let symbolsInWatchList: string [] = this.watchlist.quotables.map(quotable => quotable.symbol);
      this.symbolsToAdd.split(",").forEach(symbol => {
        if (!symbolsInWatchList.includes(symbol)) {
          quotables.push(Quotable.getStockQuotable(symbol.trim()));
        }
      })
    }
    let invalidSymbolsArray: string[] = [];
    if(quotables.length >0) {
      quoteService.updateQuotables(quotables).then(() => {
        quotables.forEach(quotable => {
          if(quotable.rawQuote) {
            this.watchlist.quotables.push(quotable);
          } else {
            invalidSymbolsArray.push(quotable.symbol);
          }
        })
        if(invalidSymbolsArray.length > 0)
          this.invalidSymbols = invalidSymbolsArray.toString() + " are not valid symbols";
        this.symbolsToAdd = "";
        console.log("new size: "+this.watchlist.quotables.length);
      });
    }
  }

  deleteWatchlist() {
    // TODO confirmation dialog
    this.show = true;
    watchlistService.deleteWatchlist(this.watchlist).then(() => {
          this.loadWatchlistsFn();
          eventEmitter.emit("showMessage", ["Watchlist Deleted"]);
          this.show = !this.show;
        }
    ).catch(reason => eventEmitter.emit("showError", ["Error in deleting watchlist.Please try again later."]));
  }

  saveWatchlist() {
    // TODO validate for required fields
    this.show = true;
    watchlistService.updateStockWatchlist(this.watchlist).then(() => {
          this.loadWatchlistsFn();
          eventEmitter.emit("showMessage", ["Watchlist Updated"]);
          this.show = !this.show;
        }
    ).catch(reason => eventEmitter.emit("showError", ["Error in updating watchlist.Please try again later."]));
  }


}

class EditDialog {
  show = false;
  symbolsToAdd = "";
  invalidSymbols = "";

}

export default defineComponent({
  name: "StockWatchlist",
  components: {ColumnSettings},
  setup(props: undefined, context: SetupContext) {
    let dataLoadingInd = ref<boolean>(true);
    //let watchlistHeaders = reactive(buildHeaders());

    let allWatchlists: StockWatchlist[] = reactive([]);
    let editStockWatchlist = reactive<StockWatchlist>(new StockWatchlist());
    let selectedStockWatchlist = ref<StockWatchlist>(new StockWatchlist());
    let selectedWatchlistId = ref<number>();
    let editDialog = ref<EditDialog>(new EditDialog());
    let watchlistManager = reactive(new AddEditWatchlistManager(context, loadWatchLists));

    // composables
    const {watchlistHeaders, watchlistColumnsReactive, startColumnSettings,saveColumnSettings,showColumnSettings,availableColumns,selectedColumns} = useWatchlistColumns(context);

    /**
     * Load quotes for symbols in the selected watchlist.
     */
    function loadWatchlistQuotes(){
      const localSelectedWatchlist = allWatchlists.filter(watchlist => watchlist.id == selectedWatchlistId.value)[0];
      dataLoadingInd.value = true;
      watchlistService.loadWatchlistQuotes(localSelectedWatchlist).then(function() {
        // assign the ref variable only after quotes are loaded
        selectedStockWatchlist.value = localSelectedWatchlist;
        dataLoadingInd.value = false;
      });
    }

    /**
     * load list of watchlists to populate watchlist dropdown
     */
    function loadWatchLists() {
      dataLoadingInd.value = true;
      watchlistService.loadStockWatchlists().then(function(stockWatchLists:StockWatchlist[]){
        allWatchlists.splice(0, allWatchlists.length, ...stockWatchLists);
        dataLoadingInd.value = false;
        if (allWatchlists.length > 0){
          selectedWatchlistId.value = allWatchlists[0].id;
          loadWatchlistQuotes();
        }
      });
    }

    function closeWatchlists(){
      context.emit("close-watchlists");
    }

    onMounted(() => {
      loadWatchLists();
    });

    return {
      dataLoadingInd,
      loadWatchlistQuotes,
      allWatchlists,
      editStockWatchlist,
      selectedWatchlistId,
      selectedStockWatchlist,
      editDialog,
      watchlistManager,
      closeWatchlists,
      // from useWatchlistColumns
      showColumnSettings,
      selectedColumns,
      availableColumns,
      watchlistColumnsConfig,
      watchlistHeaders,
      watchlistColumnsReactive,
      startColumnSettings,
      saveColumnSettings
    }
  }
});

function  buildHeaders() : any[]{
  // these are the default headers
  let watchlistHeaders : DataTableHeader[] = [
    {text: "Symbol", value: "symbol", sortable: true, align:"start"},
    {text: "Last", value: "", sortable: true, align:"start"},
    {text: "Chg", value: "", sortable: true, align:"start"},
    {text: "Chg%", value: "", sortable: true, align:"start"},
    {text: "Vol", value: "", sortable: true, align:"start"},
    {text: "Bid", value: "", sortable: true, align:"start"},
    {text: "Ask", value: "", sortable: true, align:"start"},
  ];
 return watchlistHeaders;

// Last("Last"),
// Change("Chg"),
// ChangePercentage("Chg%"),
// Volume("Vol"),
// Bid("Bid"),
// Ask("Ask");

}

