import {GenericBaseService} from "@/services/generic/GenericBaseService";
import {BrokerOrderService} from "@/services/broker/BrokerOrderService";
import {BrokerUser, UserAccount} from "@/data/UserData";
import {Order, OrderLeg, OrderPreview} from "@/data/OrdersData";
import {AxiosResponse} from "axios";
import {genericAxiosInstance} from "@/util/AxiosUtil";
import _map from "lodash/map";
import {
    AsyncStatus,
    OrderClass,
    OrderDuration,
    OrderStatus,
    OrderType,
    PriceEffect,
    SecurityDirection,
    SecurityType,
    TransactionType
} from "@/data/EnumData";
import {BNUtil, PromiseUtil, SecurityUtil} from "@/util/Util";
import {GenericOrder, GenericOrderPreview} from "@/data/generic/GenericOrderData";
import {AsyncResponse} from "@/data/CommonData";
import {Quotable} from "@/data/QuoteData";
import {Logger} from "@/util/AppLogger";


export class GenericOrderService  extends GenericBaseService implements BrokerOrderService {

    logger = new Logger("GenericOrderService");
    private orderTypeMap  = new Map<string, OrderType>([
        ["market" , OrderType.Market],
        ["limit" , OrderType.Limit],
        ["stop" , OrderType.Stop],
        ["stop_limit" , OrderType.StopLimit],
        ["debit" , OrderType.NetDebit],
        ["credit" , OrderType.NetCredit],

        // Generic broker requires the OrderType to be set to 'even' to be able to set a price of zero
        // Other brokers allow '0' price with Net_credit or Net_debit
        // leave the even map entry to the end
        ["even" , OrderType.NetCredit]
    ]);

    private orderStatusMap = new Map<string, OrderStatus>([
        ["open" , OrderStatus.Open], //working
        ["partially_filled" , OrderStatus.PartiallyFilled], // working
        ["filled" , OrderStatus.Filled], //filled
        ["expired" , OrderStatus.Expired], //other
        ["canceled" , OrderStatus.Canceled], //canceled
        ["pending" , OrderStatus.Pending], //working
        ["rejected" , OrderStatus.Rejected], //other
        ["error" , OrderStatus.Error] //other
    ]);

    private orderClassMap = new Map<string, OrderClass>([
        ["equity" , OrderClass.Equity],
        ["option" , OrderClass.Option],
        ["combo" , OrderClass.Combo],
        ["multileg" , OrderClass.MultiLeg]
    ]);

    private orderDurationMap = new Map<string, OrderDuration>([
        ["day", OrderDuration.Day],
        ["pre", OrderDuration.Pre],
        ["post", OrderDuration.Post],
        ["gtc", OrderDuration.GTC]
    ]);

    async getOrders(brokerUser: BrokerUser) : Promise<AsyncResponse<Order[]>>{
        const orderPromises : Promise<AsyncResponse<Order[]>>[] = []
        for(let userAccount of brokerUser.userAccounts){
            // retrieve each account orders in parallel
            orderPromises.push(this.getOrdersByAccount(userAccount, brokerUser));
        }
        // const ordersArrayArray: Order[][]  = await Promise.all(orderPromises)
        const promiseResults: PromiseSettledResult<AsyncResponse<Order[]>>[]  =  await Promise.allSettled(orderPromises);
        const combinedResponse: AsyncResponse<Order[]> = PromiseUtil.combinePromiseArrayResults<Order>(promiseResults);
        return combinedResponse;
    }

    /**
     * Broker returns 'partially_filled' when the order is partially filled and order is still open
     * If the partially_filled order is cancelled, it's marked as 'Canceled' but you can detect if it was partially filled using the exec_quantity
     * I think same thing happens if a partially filled order expires
     * @param userAccount
     * @param brokerUser
     * @private
     */
    private async getOrdersByAccount(userAccount: UserAccount, brokerUser: BrokerUser) :  Promise<AsyncResponse<Order[]>>{
        ///v1/accounts/{account_id}/orders
        const asyncResponse: AsyncResponse<Order[]> = new AsyncResponse();
        asyncResponse.data = [];
        try{
            const response: AxiosResponse = await genericAxiosInstance.get<any>(`/accounts/${userAccount.accountNumber}/orders`, this.getAxiosConfig(brokerUser));
            // when there are no orders, response is {"orders":"null"}
            if (response.data.orders != "null"){
                let genericOrders: GenericOrder[] = response.data.orders.order as GenericOrder[];
                if (!Array.isArray(response.data.orders.order)){
                    genericOrders = [response.data.orders.order] as GenericOrder[];
                }
                const orders: Order[] = _map(genericOrders, genericOrder => {
                    return this.mapGenericOrderToOrder(genericOrder, userAccount);
                });
                asyncResponse.data = orders;
            }
        }catch (errorResponse) {
            asyncResponse.status = AsyncStatus.Error;
            this.captureUnknownErrors(errorResponse, asyncResponse);
            this.logger.error(errorResponse);
        }
        return asyncResponse;
    }

    private mapGenericOrderToOrder(genericOrder: GenericOrder, userAccount: UserAccount){
        const order = new Order();
        order.userAccount = userAccount;
        order.brokerOrderId = String(genericOrder.id);
        order.class = this.orderClassMap.get(genericOrder.class)!;
        order.orderType = this.orderTypeMap.get(genericOrder.type) as OrderType;
        order.symbol = genericOrder.symbol;
        // don't set the quantity from genericOrder because it's a sum of individual legs
        // order.quantity = Number(genericOrder.quantity);
        order.status = this.orderStatusMap.get(genericOrder.status) as OrderStatus;
        // if order status is PartiallyFilled, it means, it's still open
        if (order.status == OrderStatus.PartiallyFilled){
            order.subStatus = OrderStatus.Open;
        }
        order.duration = this.orderDurationMap.get(genericOrder.duration) as OrderDuration;
        if (order.orderType != OrderType.Market){
            order.price = BNUtil.of(genericOrder.price);
        }

        // order.quantityFilled = Number(genericOrder.exec_quantity);
        if (Number(genericOrder.exec_quantity) > 0){
            const averageFillPrice = BNUtil.of(genericOrder.avg_fill_price);
            order.averageFillPrice = averageFillPrice.abs();
            // fill prices are negative for credit and positive for debit, so we have to reverse it
            order.averageFillPriceEffect = averageFillPrice.gt(BNUtil.ZERO) ? PriceEffect.Debit : PriceEffect.Credit;
        }
        order.createdDate = new Date(genericOrder.create_date);
        order.updatedDate = new Date(genericOrder.transaction_date);
        order.stopPrice = BNUtil.of(genericOrder.stop_price);
        order.description = genericOrder.description;

        // the legs array is only returned if there are more at least 2 real legs
        if(genericOrder.num_legs && genericOrder.num_legs > 0 ) {
            genericOrder.leg.forEach(leg => {
                this.copyLegToOrder(order,leg);
            })
            if (order.orderType != OrderType.Limit){
                order.priceEffect = order.orderType == OrderType.NetDebit ? PriceEffect.Debit : PriceEffect.Credit;
            }
        } else {
            this.copyLegToOrder(order,genericOrder);
            let leg = order.legs[0];
            if (order.orderType == OrderType.Limit){
                order.priceEffect = leg.securityDirection == SecurityDirection.Long ? PriceEffect.Debit : PriceEffect.Credit;
            }
        }
        order.updatePriceDescription();
        return order;
    }

    async getOrder(brokerUser: BrokerUser, userAccount: UserAccount, orderId: string) : Promise<AsyncResponse<Order>>{
        const asyncResponse: AsyncResponse<Order> = new AsyncResponse();
        try {
            const response: AxiosResponse = await genericAxiosInstance.get<any>(`/accounts/${userAccount.accountNumber}/orders/${orderId}`, this.getAxiosConfig(brokerUser));
            if("order" in response.data){
                let genericOrder: GenericOrder = response.data.order as GenericOrder;
                asyncResponse.data = this.mapGenericOrderToOrder(genericOrder, userAccount);
            }
        }catch (errorResponse) {
            // 404 not found error if order_id is not found
            // unauthorized account, if the orderid is invalid but exists in their db
            asyncResponse.status = AsyncStatus.Error;
            this.captureUnknownErrors(errorResponse, asyncResponse);
        }
        return asyncResponse;
    }


    private copyLegToOrder(order:Order, genericOrder:GenericOrder) {
        const orderLeg = new OrderLeg();
        orderLeg.brokerLegId = genericOrder.id;
        if(genericOrder.class == "option"){
            const quotable = SecurityUtil.getQuotable(genericOrder.option_symbol, genericOrder.option_symbol);
            orderLeg.securityType = quotable.securityType;
            orderLeg.expiryDate = quotable.expiryDate;
            orderLeg.strikePrice = quotable.strikePrice;
            orderLeg.underlying = genericOrder.symbol;
            orderLeg.symbol = genericOrder.option_symbol;
            orderLeg.quotable = quotable;
        }else{
            orderLeg.underlying = genericOrder.symbol;
            orderLeg.symbol = genericOrder.symbol;
            orderLeg.securityType = SecurityType.Stock;
            orderLeg.quotable = Quotable.getStockQuotable(orderLeg.symbol);
        }

        orderLeg.quantity = Number(genericOrder.quantity);
        orderLeg.quantityFilled = Number(genericOrder.exec_quantity);
        orderLeg.averageFillPrice = BNUtil.of(genericOrder.avg_fill_price);
        if ((order.status == OrderStatus.Canceled || order.status == OrderStatus.Expired) && orderLeg.quantityFilled > 0){
            // if order status is 'Canceled' or 'Expired' but exec_quantity > 0, that means it's partially filled
            order.subStatus = OrderStatus.PartiallyFilled;
        }

        // Equity, One of: buy, buy_to_cover, sell, sell_short
        // Option, One of: buy_to_open, buy_to_close, sell_to_open, sell_to_close
        // sell on equity means sell to close
        // sell_short on equity means sell to open
        if(genericOrder.side == 'buy' || genericOrder.side == 'buy_to_open') {
            orderLeg.securityDirection = SecurityDirection.Long;
            orderLeg.transactionType = TransactionType.Open;
        } else if(genericOrder.side == 'buy_to_cover' || genericOrder.side == 'buy_to_close') {
            orderLeg.securityDirection = SecurityDirection.Long;
            orderLeg.transactionType = TransactionType.Close;
        } else if(genericOrder.side == 'sell_short' || genericOrder.side == 'sell_to_open') {
            orderLeg.securityDirection = SecurityDirection.Short;
            orderLeg.transactionType = TransactionType.Open;
            // for sell transactions, quantity has to be negated
            orderLeg.quantity = orderLeg.quantity* -1;
        } else if(genericOrder.side == 'sell' || genericOrder.side == 'sell_to_close') {
            orderLeg.securityDirection = SecurityDirection.Short;
            orderLeg.transactionType = TransactionType.Close;
            orderLeg.quantity = orderLeg.quantity* -1;
        }
        order.legs.push(orderLeg);
    }

    async previewOrder(brokerUser: BrokerUser, order: Order): Promise<AsyncResponse<OrderPreview>>{
        let params = this.buildOrderParams(order);
        // set the preview flag
        params.append("preview", "true");
        const config = this.getAxiosConfig(brokerUser)
        config.headers["Content-Type"] = "application/x-www-form-urlencoded";
        const asyncResponse: AsyncResponse = new AsyncResponse<OrderPreview>();
        try {
            let response: AxiosResponse = await genericAxiosInstance.post<any>(`/accounts/${order.userAccount?.accountNumber}/orders`, params, config);
            if("order" in response.data){
                let genericOrderPreview: GenericOrderPreview = response.data.order as GenericOrderPreview;
                asyncResponse.data = this.buildOrderPreview(genericOrderPreview);
            }else if("errors" in response.data){
                // broker returns validation errors with 200 response inside error object
                asyncResponse.status= AsyncStatus.Error;
                this.captureBusinessErrors(response, asyncResponse);
            }else{
                asyncResponse.status= AsyncStatus.Error;
                asyncResponse.businessErrors = ["There was error with your order"];
            }
        }catch (errorResponse) {
            asyncResponse.status = AsyncStatus.Error;
            this.captureUnknownErrors(errorResponse, asyncResponse);
            // in the case of 'Preview Order', let's consider all errors as business errors
            asyncResponse.businessErrors = asyncResponse.unknownErrors;
        }
        return asyncResponse;
    }

    async placeOrder(brokerUser: BrokerUser, order: Order): Promise<AsyncResponse<void>>{
        let params = this.buildOrderParams(order);
        const config = this.getAxiosConfig(brokerUser)
        config.headers["Content-Type"] = "application/x-www-form-urlencoded";
        const asyncResponse: AsyncResponse = new AsyncResponse<OrderPreview>();
        try {
            let response: AxiosResponse = await genericAxiosInstance.post<any>(`/accounts/${order.userAccount?.accountNumber}/orders`, params, config);
            if("order" in response.data){
                order.brokerOrderId = String(response.data.order.id);
            }else{
                asyncResponse.status = AsyncStatus.Error;
                this.captureBusinessErrors(response, asyncResponse);
            }
        }catch (errorResponse) {
            asyncResponse.status = AsyncStatus.Error;
            this.captureUnknownErrors(errorResponse, asyncResponse);
        }
        return asyncResponse;
    }

    private buildOrderPreview(genericOrderPreview: GenericOrderPreview): OrderPreview{
        const orderPreview = new OrderPreview();
        const tradeCost = BNUtil.of(genericOrderPreview.order_cost).negated();
        orderPreview.tradeCost = tradeCost.abs();
        orderPreview.tradeCostEffect = tradeCost.gte(BNUtil.ZERO) ? PriceEffect.Credit : PriceEffect.Debit;

        const buyingPowerChange = BNUtil.of(genericOrderPreview.cost).negated();
        orderPreview.buyingPowerChange = buyingPowerChange.abs();
        orderPreview.buyingPowerChangeEffect = buyingPowerChange.gte(BNUtil.ZERO) ? PriceEffect.Credit : PriceEffect.Debit;

        orderPreview.commission = BNUtil.of(genericOrderPreview.commission);
        orderPreview.fees = BNUtil.of(genericOrderPreview.fees);

        return orderPreview;
    }

    private buildOrderParams(order:Order){
        const params = this.buildModifiableOrderParams(order);
        params.append("class", this.getKeyFromMapValue(this.orderClassMap, order.class));
        params.append("symbol", order.symbol);

        if (order.legs.length == 1){
            const leg = order.legs[0];
            // broker requires quantity to be always positive
            params.append("quantity", Math.abs(order.legs[0].quantity).toString());
            params.append("side", this.getOrderSide(leg));
            if(order.class == OrderClass.Option){
                params.append("option_symbol", leg.symbol);
            }
        }else{
            order.legs.forEach((leg, legIndex) => {
                params.append(`quantity[${legIndex}]`, Math.abs(leg.quantity).toString());
                params.append(`side[${legIndex}]`, this.getOrderSide(leg));
                if(leg.securityType != SecurityType.Stock){
                    // if it's an option leg
                    params.append(`option_symbol[${legIndex}]`, leg.symbol);
                }
            })
        }
        return params;
    }

    /**
     * These params sent during Order creation as well as when modifying an order
     * @param order
     * @private
     */
    private buildModifiableOrderParams(order:Order){
        const params = new URLSearchParams();
        // there is one exception to the type
        // when the class is multileg or combo and the price is 0, we need to set the type to 'even' instead of 'net_credit' or 'net_debit'
        if(order.legs.length > 1 && order.price.eq(BNUtil.ZERO)){
            params.append("type", "even");
        }else{
            // use getOrderTypeForBroker() that handles RollingLimitOrders
            params.append("type", this.getKeyFromMapValue(this.orderTypeMap, order.getOrderTypeForBroker()));
        }
        params.append("duration", this.getKeyFromMapValue(this.orderDurationMap, order.duration));
        if (order.orderType != OrderType.Market){
            params.append("price", order.priceInput);
        }
        if (order.orderType == OrderType.Stop || order.orderType == OrderType.StopLimit){
            params.append("stop", order.stopPriceInput);
        }
        return params;
    }

    private getOrderSide(leg: OrderLeg): string{
        let side = "";
        if (leg.securityDirection == SecurityDirection.Long){
            if (leg.transactionType == TransactionType.Open){
                side = leg.securityType == SecurityType.Stock ? "buy" : "buy_to_open";
            }else{
                side = leg.securityType == SecurityType.Stock ? "buy_to_cover" : "buy_to_close";
            }
        }else{
            if (leg.transactionType == TransactionType.Open){
                side = leg.securityType == SecurityType.Stock ? "sell_short" : "sell_to_open";
            }else{
                side = leg.securityType == SecurityType.Stock ? "sell" : "sell_to_close";
            }
        }
        return side;
    }

    private getKeyFromMapValue(map: Map<string, any>, mapValue: any): string{
        let mapKey = "";
        for(const [key,value] of map){
            if (value == mapValue){
                mapKey = key;
                break;
            }
        }
        return mapKey;
    }

    async modifyOrder(brokerUser: BrokerUser, order: Order): Promise<AsyncResponse<void>>{
        // Broker returns a 404 if you try to modify an order that is in Pending status
        // You also cannot modify an order that is partially filled, you will have to cancel and re-order
        const modifyAsyncResponse: AsyncResponse = new AsyncResponse<OrderPreview>();
        const orderAsyncResponse = await this.getOrder(brokerUser, order.userAccount!, order.brokerOrderId);
        if (orderAsyncResponse.status == AsyncStatus.Ok){
            // if order status is pending, cancel it and place a new order
            const orderFromBroker = orderAsyncResponse.data;
            if (orderFromBroker?.status == OrderStatus.Pending){
                return this.cancelAndReorder(brokerUser, order);
            }else if(orderFromBroker?.status == OrderStatus.Open){
                // if order status is open, we can proceed with modifying
                this.doModifyOrder(brokerUser, order, modifyAsyncResponse);
            }else{
                // if order in any other status, throw an error with status
                modifyAsyncResponse.status = AsyncStatus.Error;
                modifyAsyncResponse.businessErrors = [`Order cannot be modified. It is in ${orderFromBroker?.statusStr} status`]
                return modifyAsyncResponse;
            }
        }else{
            // so some unknown problem retrieving the order
            modifyAsyncResponse.status = AsyncStatus.Error;
            modifyAsyncResponse.businessErrors = ["There was a problem modifying your order"];
        }
        return modifyAsyncResponse;
    }

    private async doModifyOrder(brokerUser: BrokerUser, order: Order, modifyAsyncResponse: AsyncResponse){
        let params = this.buildModifiableOrderParams(order);
        const config = this.getAxiosConfig(brokerUser)
        config.headers["Content-Type"] = "application/x-www-form-urlencoded";
        try {
            let response: AxiosResponse = await genericAxiosInstance.put<any>(`/accounts/${order.userAccount?.accountNumber}/orders/${order.brokerOrderId}`, params, config);
            if("order" in response.data){
                // the order id shouldn't change when modifying an order
                order.brokerOrderId = String(response.data.order.id);
            }else{
                modifyAsyncResponse.status = AsyncStatus.Error;
                this.captureBusinessErrors(response, modifyAsyncResponse);
            }
        }catch (errorResponse) {
            modifyAsyncResponse.status = AsyncStatus.Error;
            this.captureUnknownErrors(errorResponse, modifyAsyncResponse);
            Logger
        }
    }

    async cancelOrder(brokerUser: BrokerUser, order: Order): Promise<AsyncResponse<void>>{
        const config = this.getAxiosConfig(brokerUser)
        const asyncResponse: AsyncResponse = new AsyncResponse<void>();
        try {
            let response: AxiosResponse = await genericAxiosInstance.delete<any>(`/accounts/${order.userAccount?.accountNumber}/orders/${order.brokerOrderId}`, config);
            if("order" in response.data){
                order.brokerOrderId = String(response.data.order.id);
            }else{
                asyncResponse.status = AsyncStatus.Error;
                this.captureBusinessErrors(response, asyncResponse);
            }
        }catch (errorResponse) {
            asyncResponse.status = AsyncStatus.Error;
            this.captureUnknownErrors(errorResponse, asyncResponse)
        }
        return asyncResponse;
    }

    async cancelAndReorder(brokerUser: BrokerUser, order: Order): Promise<AsyncResponse<void>>{
        const asyncResponse: AsyncResponse = new AsyncResponse<void>();
         const cancelResponse = await this.cancelOrder(brokerUser, order);
         if (cancelResponse.status == AsyncStatus.Ok){
             // proceed with placing a new order
              const newOrderResponse = await this.placeOrder(brokerUser, order);
              if (newOrderResponse.status = AsyncStatus.Ok){
                  asyncResponse.status = AsyncStatus.Ok;
              }else{
                  asyncResponse.status = AsyncStatus.Error;
                  asyncResponse.businessErrors.push(...newOrderResponse.businessErrors);
                  asyncResponse.businessErrors.push("Previous order was canceled but there was a problem replacing the canceled order");
              }
         }else{
             asyncResponse.status = AsyncStatus.Error;
             asyncResponse.businessErrors.push(...cancelResponse.businessErrors);
             asyncResponse.businessErrors.push("Problem canceling existing order");
         }
        return asyncResponse;
    }

}

export const genericOrderService = new GenericOrderService();