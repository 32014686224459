import {BrokerUser} from "@/data/UserData";
import {appGlobals} from "@/data/AppData";
import {ConstantMessages, Constants} from "@/data/common/Constants";
import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {genericAxiosInstance} from "@/util/AxiosUtil";
import {AsyncResponse} from "@/data/CommonData";
import {AsyncStatus} from "@/data/EnumData";

export class GenericBaseService {
    getAxiosConfig(token: string | BrokerUser) : AxiosRequestConfig{
        if (token instanceof BrokerUser){
            token = token.accessToken;
        }
        return {
            headers:
                {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                    // Authorization: "Bearer " + appGlobals.token
                }
        }
    }

    /**
     * Parses Generic broker's response and extracts errors into an array
     * This is for situations where the response is still success (typically status code 200), but there are some valid business exceptions
     * If there are errors in payload or bad data is submitted, typically the status code is 400/500 with text response from Generic Broker
     * @param response
     */
    captureBusinessErrors(response: AxiosResponse, asyncResponse: AsyncResponse){
        let errors: string[] = [];
        if("errors" in response.data){
            let error = response.data.errors.error;
            Array.isArray(error) ? Array.prototype.concat(errors, error) : errors.push(error);
        }
        asyncResponse.businessErrors = errors;
        asyncResponse.status = AsyncStatus.Error;
    }

    captureUnknownErrors(exception: any, asyncResponse: AsyncResponse){
        if (exception && exception.response) {
            // // that means the exception is an AxiosError Object and exception.response is a AxiosResponse object
            const axiosErrorResponse = exception.response as AxiosResponse;
            const statusCode = axiosErrorResponse.status;
            if (statusCode == 400 || statusCode >= 500){
                // typically you get a String response from
                asyncResponse.unknownErrors = axiosErrorResponse.data ? [axiosErrorResponse.data] : [ConstantMessages.ProcessingError];
            }else if (statusCode == 404){
                asyncResponse.unknownErrors = [ConstantMessages.NotFoundError];
            }else if(statusCode == 401){
                asyncResponse.unknownErrors = [ConstantMessages.UnauthorizedError];
            }else{
                asyncResponse.unknownErrors = [ConstantMessages.ProcessingError];
            }
        }else{
            // this could be an error that could have happened in any other part of the method
            if (exception instanceof Error){
                asyncResponse.unknownErrors = [exception.message];
            }else if(typeof exception === "string"){
                asyncResponse.unknownErrors = [exception];
            }
        }
        asyncResponse.status = AsyncStatus.Error;
    }
}