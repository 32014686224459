










































































































































import {defineComponent, reactive, SetupContext, watch, onMounted, computed} from "@vue/composition-api";
import {OptionChain, OptionChainExpiry, OptionContract, OptionPair, Quotable, SecurityQuote} from "@/data/QuoteData";
import {quoteService} from "@/services/QuoteService";
import {TransactionType} from "@/data/EnumData";
import {appGlobals} from "@/data/AppData";
import {OrderLeg} from "@/data/OrdersData";
import {tradeService} from "@/services/TradeService";
import {DataTableHeader} from "vuetify";
import {AddLegPayload} from "@/data/TradeData";
import FlashText from "@/views/common/FlashText.vue";
import OptionChainHeaders from "@/views/trade/OptionChainHeaders.vue";

interface Props{
  componentHeight: number,
  symbol: string,
  optionChainExpiries: OptionChainExpiry[]
}

export default defineComponent({
  name: "OptionChainVue",
  components: {FlashText, OptionChainHeaders},
  props: {
    componentHeight: Number,
    symbol: String,
    optionChainExpiries: Array  // these optionChainExpiries only have expiry dates and strikes, they don't have quotes
  },
  emits: ["option-selected"],
  setup(props: Props, context: SetupContext) {

    let headers = reactive<DataTableHeader[]> ([
      {text: "", value: "", sortable: false },  //empty column for expand icon
      {text: "Theta", value: "theta", sortable: false, align: "end" },
      {text: "Delta", value: "theta", sortable: false, align: "end"  },
      {text: "Bid", value: "theta", sortable: false, align: "end"  },
      {text: "Ask", value: "theta", sortable: false, align: "end"  },
      {text: "", value: "removeCallLeg", width: "30px", sortable: false, align: "center" }, // column meant for the 'remove' leg icon
      {text: "Strike", value: "strike", align: "center", sortable: false },
      {text: "", value: "removePutLeg", width: "30px", sortable: false, align: "center" }, // column meant for the 'remove' leg icon
      {text: "Bid", value: "theta", sortable: false, align: "end"  },
      {text: "Ask", value: "theta", sortable: false, align: "end"  },
      {text: "Delta", value: "theta", sortable: false, align: "end"  },
      {text: "Theta", value: "theta", sortable: false, align: "end"  },
      {text: "", value: "", sortable: false }
    ])

    const tradeData = tradeService.tradeData;
    const columnsConfig = tradeData.optionChainColumnsConfig;
    // let optionChainItems : Array<OptionChainExpiry | OptionPair> = reactive([]);

    watch(() => props.symbol, () => {
      // when symbol is updated, optionChainExpiries is also updated by the parent component
      // optionChainItems.splice(0, optionChainItems.length);
      // optionChainItems.push(...props.optionChainExpiries);
      // symbol has been updated, so load new OptionChainExpiries
      // quoteService.getOptionExpiries(props.symbol).then(function(optionChainExpiries: OptionChainExpiry[]){
      //   tradeData.optionChainItems.splice(0, tradeData.optionChainItems.length, ...optionChainExpiries);
      // });

    })

    const optionChainGridHeight = computed(() => {
      return props.componentHeight;
    })

    function addOptionLeg(event: Event, selectedOptionContract: OptionContract, isLong: boolean){
      const addLegPayload = new AddLegPayload();
      addLegPayload.isEquityLeg = false;
      addLegPayload.selectedOptionContract = selectedOptionContract;
      addLegPayload.isLong = isLong;
      tradeService.addLegToOrder(addLegPayload);
      // prevent event propagation to prevent the selectLeg() event on the leg from being fired
      event.stopImmediatePropagation();
      // context.emit("option-selected", selectedOptionContract);
    }

    function removeOptionContract(selectedOptionContract: OptionContract){
      tradeService.removeOptionContractFromOrder(selectedOptionContract).then(function(){
        // nothing much to do here
      });
    }

    function toggleChainDisplay(optionChainExpiry: OptionChainExpiry){
      tradeData.optionChainGridLoadingIndicator = true;
      tradeService.toggleChainDisplay(optionChainExpiry).then(function(){
        context.root.$nextTick().then(function(){
          // wait for nextTick to make sure the data is rendered
          tradeData.optionChainGridLoadingIndicator = false;
          if (optionChainExpiry.expanded){
            scrollToAtmStrike(optionChainExpiry);
          }else{
            // if the expiry is collapsed, focus the expiry,
            // otherwise in some scenarios the focus shifts to somewhere else causing confusion
            document.getElementById(optionChainExpiry.key)!.scrollIntoView({block: "center", behavior: "smooth"});
          }

        })
      })
    }

    // Scroll to the atm strike based on the row key
    function scrollToAtmStrike(optionChainExpiry: OptionChainExpiry){
      const atmRowKey = OptionPair.constructKey(optionChainExpiry.expiryDate, optionChainExpiry.atmStrike);
      scrollToOptionPairRow(atmRowKey);
    }

    function scrollToOptionPairRow(optionPairKey: string){
      document.getElementById(optionPairKey)!.scrollIntoView({block: "center", behavior: "smooth"});
    }

    function moveLegToNextStrike(optionContract: OptionContract, step: number){
      tradeService.moveLegToNextStrike(optionContract, step).then(function(stepsMoved: number){
        // get the parent div that is set to scroll
        const gridWrapper = document.getElementById("optionchain-grid")!.firstElementChild;
        // this code can be used to move selected legs up or down
        gridWrapper!.scrollTop = gridWrapper!.scrollTop + (32*stepsMoved);
      });
    }

    function toggleLegSelection(optionContract: OptionContract){
      tradeService.toggleLegSelectionFromOC(optionContract);
    }

    function focusContract(optionContract: OptionContract){
      const rowKey: string = OptionPair.constructKey(optionContract.expiryDate, optionContract.strikePrice);
      scrollToOptionPairRow(rowKey);
    }

    onMounted(() => {
      tradeData.eventEmitter.on("focusContract", function(optionContract: OptionContract){
        focusContract(optionContract);
      });
      // for certain scenarios, this event is invoked before the Trade screen loads,
      // so, we have to wait before we can focus the contract
      tradeData.eventEmitter.on("focusContractWithDelay", function(optionContract: OptionContract){
        context.root.$nextTick().then(function(){
          focusContract(optionContract);
        });
        // window.setTimeout(function(){
        //   console.log("In Setimeout - "+new Date());
        //   focusContract(optionContract);
        // }, 1000);
      });

    });

    return {
      optionChainGridHeight,
      headers,
      columnsConfig,
      tradeData,
      toggleChainDisplay,
      addOptionLeg,
      removeOptionContract,
      moveLegToNextStrike,
      toggleLegSelection
    }

  }
})

