







import Vue from "vue";
import { defineComponent } from "@vue/composition-api";
import MessagesView from "@/views/common/Messages.vue";

export default defineComponent({
  // type inference enabled
  setup() {
    const name = "App";
  },
  components: {
    MessagesView
  }
});
