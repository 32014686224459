import {BaseEnum, SecurityDirection, SecurityType} from "@/data/EnumData";
import {Enumify} from "enumify";
import {AsyncResponse, VuetifySelection} from "@/data/CommonData";
import {eventEmitter} from "@/main";


export class BaseService{

    handleAsyncErrors(asyncResponse: AsyncResponse, overrideUnknownErrorMessage: string = ""){
        if (asyncResponse.businessErrors.length > 0){
            eventEmitter.emit("showError", asyncResponse.businessErrors);
        }else if(asyncResponse.unknownErrors.length > 0){
            if(overrideUnknownErrorMessage.length > 0){
                eventEmitter.emit("showError", [overrideUnknownErrorMessage]);
            }else{
                eventEmitter.emit("showError", asyncResponse.unknownErrors);
            }
        }
    }

}

export default class CommonService {
    constructor() {}

    getSelectionList<T>(enumType: any) : VuetifySelection[] {
        const selectionList: VuetifySelection[] = [];
        let iterator:Enumify[] = [];
        if (enumType === SecurityType){
            iterator = [...SecurityType];
        }else if(enumType === SecurityDirection){
            iterator = [...SecurityDirection];
        }
        iterator.forEach(enumVar => {
             selectionList.push(new VuetifySelection(enumVar.enumKey, enumVar.toString(), false));
        });
        return selectionList;
    }

    test(){
        this.getSelectionList(SecurityType);
    }


}

export const commonService = new CommonService();