import {User} from "@/data/UserData";
import {AxiosResponse} from "axios";
import {axiosInstance, axiosPublicInstance} from "@/util/AxiosUtil";
import {WaitlistLead} from "@/data/lead/LeadData";
import BaseBackendService from "@/services/backend/BaseBackendService";
import {AsyncResponse} from "@/data/CommonData";
import {RollingLimitParams} from "@/data/OrdersData";
import {Logger} from "@/util/AppLogger";


export default class LeadBackendService extends BaseBackendService {
    logger = new Logger("LeadBackendService");

    async createWaitlistLead(email: string): Promise<AsyncResponse<WaitlistLead>> {
        const waitlistLead = new WaitlistLead();
        waitlistLead.email = email;
        const asyncResponse: AsyncResponse = new AsyncResponse<WaitlistLead>();
        try {
            const axiosResponse: AxiosResponse = await axiosPublicInstance.post<WaitlistLead>("/leads/waitlist", waitlistLead);
            asyncResponse.data = axiosResponse.data;
        }catch (exception) {
            this.captureErrors(exception, asyncResponse);
            this.logger.error(exception);
        }
        return asyncResponse;
    }
}

export const leadBackendService = new LeadBackendService();