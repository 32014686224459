import {classToClass, Type} from "class-transformer";
import DateUtil, {BNUtil, NumberUtil, SecurityUtil} from "@/util/Util";
import {OptionChainItemType, SecurityDirection, SecurityType, TransactionType} from "@/data/EnumData";
import {VuetifySelection} from "@/data/CommonData";
import {SymbolItem} from "@/data/PositionsData";
import BigNumber from "bignumber.js";
import {OrderLeg} from "@/data/OrdersData";

/**
 *
 */
export class QuotableGroups{
    positions: Quotable[] = [];
    orders: Quotable[] = [];
    trade:  Quotable[] = [];
    watchlist: Quotable[] = [];
    symbolSummaries: Quotable[] = [];
    optionChain: Quotable[] = [];
    // Quotables from OptionChain that are far OTM
    // the idea is to not update them as frequently as the other options
    optionChainFarOTM: Quotable[] = [];
}

export class SymbolSummary{
    symbol: string = "";
    quotable?: Quotable;
}

/**
 * represents an object that can receive and store quotes for stock or options
 */
export class Quotable {
    symbol: string = ""; // stock symbol for stock and osisymbol for option
    underlying: string = ""; // underlying stock symbol
    brokerSymbol: string = ""; // symbol (stock or option) used by the broker for this security
    // For some options (like SPX or non-standard split securities), the rootSymbol is different from underlying symbol
    // So, rootSymbol will be same as underlying where it matches
    // So, we should always construct the option symbol using rootSymbol
    rootSymbol: string = "";
    securityType: SecurityType = SecurityType.Call;
    expiryDate: Date = new Date();    // format yyMMdd
    strikePrice  = BNUtil.ZERO;
    rawQuote: SecurityQuote = new SecurityQuote();   // stores stock quote for a stock security or option quote for an option security
    underlyingQuote? : SecurityQuote; // for option quotable, this variable stores the underlying stock quote
    lastUpdated? : Date;

    constructor(underlying: string, securityType: SecurityType,
                symbol: string, rootSymbol: string , brokerSymbol: string, expiryDate: Date | undefined, strikePrice :BigNumber | undefined ){
        this.underlying = underlying;
        this.symbol = symbol;
        this.rootSymbol = rootSymbol;
        this.brokerSymbol = brokerSymbol;
        this.securityType = securityType;
        if (expiryDate != undefined){
            this.expiryDate = expiryDate;
        }
        if(strikePrice != undefined){
            this.strikePrice = strikePrice;
        }
    }

    static getStockQuotable(symbol: string){
        const quotable = new Quotable(symbol, SecurityType.Stock, symbol, symbol, symbol,  undefined, undefined);
        return quotable;
    }

    static getOptionQuotable(underlying: string, symbol: string, rootSymbol: string, brokerSymbol: string, securityType: SecurityType, strikePrice: BigNumber, expiryDate: Date){
        const quotable = new Quotable(underlying, securityType, symbol, rootSymbol, brokerSymbol, expiryDate, strikePrice);
        return quotable;
    }

    // makes a duplicate quotable
    static getDuplicateQuotable(quotable: Quotable){
        if (quotable.securityType.isStock){
            const newQuotable = this.getStockQuotable(quotable.symbol);
            newQuotable.rawQuote = quotable.rawQuote;
            return newQuotable
        }else{
            const newQuotable = this.getOptionQuotable(quotable.underlying, quotable.symbol, quotable.rootSymbol, quotable.brokerSymbol,  quotable.securityType, quotable.strikePrice, quotable.expiryDate);
            newQuotable.rawQuote = quotable.rawQuote;
            newQuotable.underlyingQuote = quotable.underlyingQuote;
            return newQuotable
        }
    }

    // Returns a rawQuote that is signed based on quantity
    getSignedQuote(quantity: number){
        const quote = this.rawQuote?.clone();
        if (quote){
            if(quantity > 0) {
                quote.price = quote.price.negated();
                quote.bid = quote.bid.negated();
                quote.ask = quote.ask.negated();
            }else{
                quote.delta = quote.delta.negated();
                quote.theta = quote.theta.negated();
                quote.gamma = quote.gamma.negated();
                quote.vega = quote.vega.negated();
                // reverse bid and ask if you are selling
                const bid = quote.bid;
                quote.bid = quote.ask;
                quote.ask = bid;
            }
            return quote;
        }
    }

}

/***
 * represents a quote for a
 */
export class SecurityQuote{
    symbol = "";    // represents stock symbol for stock and osiSymbol for option
    underlying = "";    // this represents stock symbol (not option)
    brokerSymbol: string = "";  // symbol used by the broker for the security
    rootSymbol: string = "";    // ex - this will hold SPXW for SPX for other regular stocks it'll just hold underlying
    securityType?: SecurityType;

    exch: string = "";
    bidExch: string = "";
    askExch: string = "";
    private _lastVolume: number = 0;
    lastVolumeStr = "";

    private _bidSize: number = 0;
    bidSizeStr = "";
    private _askSize: number = 0;
    askSizeStr = "";

    private _volume: number = 0;
    volumeStr = "";

    private _averageVolume: number = 0;
    averageVolumeStr = "";

    description: string = "";

    expiryDate: Date = new Date();

    strikeStr = "";
    private _strike: BigNumber = BNUtil.ZERO;

    lastStr = "";
    private _last: BigNumber = BNUtil.ZERO; // represents last price

    openStr = "";
    private _open: BigNumber = BNUtil.ZERO; // represents open price in this session

    highStr = "";
    private _high: BigNumber = BNUtil.ZERO; // represents high price of this session

    lowStr = "";
    private _low: BigNumber = BNUtil.ZERO; // represents low price of this session

    private _price: BigNumber = BNUtil.ZERO; // represents mid price of option or stock
    priceStr = "";

    private _change:BigNumber = BNUtil.ZERO; // change from previous close in $
    changeStr = "";

    private _bid: BigNumber = BNUtil.ZERO;
    bidStr = "";

    private _ask: BigNumber = BNUtil.ZERO;
    askStr = "";

    private _changePercentage:BigNumber = BNUtil.ZERO;   // percentage change from previous close
    changePercentageStr = "";

    private _stockImpliedVolatility: BigNumber = BNUtil.ZERO;
    stockImpliedVolatilityStr = "";

    private _spread: BigNumber = BNUtil.ZERO;
    spreadStr = "";

    private _delta: BigNumber = BNUtil.ZERO;
    deltaStr = "";

    private _theta: BigNumber = BNUtil.ZERO;
    thetaStr = "";

    private _gamma: BigNumber = BNUtil.ZERO;
    gammaStr = "";

    private _vega: BigNumber = BNUtil.ZERO;
    vegaStr = "";

    private _optionImpliedVolatility: BigNumber = BNUtil.ZERO;
    optionImpliedVolatilityStr = "";

    private _openInterest = 0;
    openInterestStr = "";

    // additional properties for options

    private _intrinsicValue = BNUtil.ZERO;
    intrinsicValueStr = "";

    // this value is set in the setter of _intrinsicValue
    extrinsicValueStr = "";

    set lastVolume(value: number) {
        this._lastVolume = value;
        this.lastVolumeStr = NumberUtil.toShortString(value);
    }

    set volume(value: number) {
        this._volume = value;
        this.volumeStr = NumberUtil.toShortString(value);
    }

    get averageVolume(): number {
        return this._averageVolume;
    }

    set averageVolume(value: number) {
        this._averageVolume = value;
        this.averageVolumeStr = NumberUtil.toShortString(value);
    }

    get open(): BigNumber {
        return this._open;
    }

    set open(value: BigNumber) {
        this._open = value;
        this.openStr = BNUtil.formatBN(value);
    }

    get high(): BigNumber {
        return this._high;
    }

    set high(value: BigNumber) {
        this._high = value;
        this.highStr = BNUtil.formatBN(value);
    }

    get low(): BigNumber {
        return this._low;
    }

    set low(value: BigNumber) {
        this._low = value;
        this.lowStr = BNUtil.formatBN(value);
    }

    get last(): BigNumber {
        return this._last;
    }

    set last(value: BigNumber) {
        this._last = value;
        this.lastStr = BNUtil.formatBN(value);
    }

    get price(): BigNumber {
        return this._price;
    }

    set price(value: BigNumber) {
        this.priceStr = BNUtil.formatBN(value);
        this._price = value;
    }

    get bid(): BigNumber{
        return this._bid;
    }

    set bid(value: BigNumber) {
        this.bidStr = BNUtil.formatBN(value);
        this._bid = value;
    }

    get ask(): BigNumber{
        return this._ask;
    }

    set ask(value: BigNumber) {
        this.askStr = BNUtil.formatBN(value);
        this._ask = value;
    }

    get bidSize(): number {
        return this._bidSize;
    }

    set bidSize(value: number) {
        this._bidSize = value;
        this.bidSizeStr = String(value);
    }

    get askSize(): number {
        return this._askSize;
    }

    set askSize(value: number) {
        this._askSize = value;
        this.askSizeStr = String(value);
    }

    get change(): BigNumber{
        return this._change;
    }

    set change(value: BigNumber) {
        this._change = value;
        this.changeStr = BNUtil.formatBN(value);
    }

    get strike(): BigNumber {
        return this._strike;
    }

    set strike(value: BigNumber) {
        this._strike = value;
        this.strikeStr = BNUtil.formatBN(value);
    }

    get changePercentage(): BigNumber {
        return this._changePercentage;
    }

    set changePercentage(value: BigNumber) {
        this.changePercentageStr = BNUtil.formatBN(value);
        this._changePercentage = value;
    }

    get stockImpliedVolatility(): BigNumber {
        return this._stockImpliedVolatility;
    }

    set stockImpliedVolatility(value: BigNumber) {
        this._stockImpliedVolatility = value;
        this.stockImpliedVolatilityStr = BNUtil.formatBN(value);
    }

    get spread(): BigNumber {
        return this._spread;
    }

    set spread(value: BigNumber) {
        this._spread = value;
        this.spreadStr = BNUtil.formatBN(value);
    }

    get delta(): BigNumber {
        return this._delta;
    }

    set delta(value: BigNumber) {
        this._delta = value;
        this.deltaStr = BNUtil.formatBN3(value);
    }

    get theta(): BigNumber {
        return this._theta;
    }

    set theta(value: BigNumber) {
        this._theta = value;
        this.thetaStr = BNUtil.formatBN3(value);
    }

    get gamma(): BigNumber {
        return this._gamma;
    }

    set gamma(value: BigNumber) {
        this._gamma = value;
        this.gammaStr = BNUtil.formatBN3(value);
    }

    get vega(): BigNumber {
        return this._vega;
    }

    set vega(value: BigNumber) {
        this._vega = value;
        this.vegaStr = BNUtil.formatBN3(value);
    }

    get optionImpliedVolatility(): BigNumber {
        return this._optionImpliedVolatility;
    }

    set optionImpliedVolatility(value: BigNumber) {
        this._optionImpliedVolatility = value;
        this.optionImpliedVolatilityStr = BNUtil.formatBN(value);
    }

    get openInterest(): number {
        return this._openInterest;
    }

    set openInterest(value: number) {
        this._openInterest = value;
        this.openInterestStr = String(value);
    }

    get intrinsicValue(): BigNumber {
        return this._intrinsicValue;
    }

    set intrinsicValue(value: BigNumber) {
        this._intrinsicValue = value;
        this.intrinsicValueStr = BNUtil.formatBN(value);
        // extrinsic value is (price - intrinsicValue)
        this.extrinsicValueStr = BNUtil.formatBN(this.price.minus(value));
    }

    clone(){
        return classToClass(this)
    }

}


export class OptionChainExpiry  {
    // used as an identifier in the data table. value is set to expiryDateStr
    key = "";
    // symbol of the underlying
    symbol = "";
    // format is DateUtil.toExpiryViewStr
    expiryDateStr = "";

    // some indices (SPX, RUT) offer multiple root_symbols for options under the same symbol
    // so, an expiry_date in itself if not unique, you have to use it in combination with root symbol
    // for example, for SPX OptionChain you can have 20230118_SPX and 20230118_SPXW
    // also to retrieve quotes for OptionChainExpiries, we have to build the OSI Option Symbol using rootSymbol rather than symbol
    // set the rootSymbol after setting symbol
    private _rootSymbol = "";
    // set to true if rootSymbol is different from symbol
    private _rootSymbolDifferent = false;

    private _expiryDate: Date = new Date();

    // @Type(() => BigNumber)
    // @Transform(values => _map(values, value => new BigNumber(value)), {toClassOnly: true})
    strikeStrs: string[] = [];

    strikes: BigNumber[] = [];

    atmStrikeStr = "";
    private _atmStrike: BigNumber = BNUtil.ZERO;

    daysToExpiry = 0;

    // these four counts indicate if this expiry has any order leg contracts
    // update these values whenever OptionContract.securityDirection property is updated
    shortPutSelections = 0;
    shortCallSelections = 0;
    longPutSelections = 0;
    longCallSelections = 0;

    @Type(() => OptionPair)
    optionPairs: OptionPair[] = [];

    // used while rendering the table to figure out what kind of row to render
    // Values are 'OptionPair' as defined in OptionPair or 'Expiry' as defined here
    optionChainItemType: string = OptionChainItemType.Expiry;

    // used in OptionChain view to indicate if the that particular expiry date is expanded or not
    expanded = false;

    get expiryDate(): Date {
        return this._expiryDate;
    }

    set expiryDate(value: Date) {
        this.expiryDateStr = DateUtil.toExpiryViewStr(value);
        this._expiryDate = value;
        this.daysToExpiry = DateUtil.daysUntil(value);
    }

    get rootSymbol(): string {
        return this._rootSymbol;
    }

    set rootSymbol(value: string) {
        this._rootSymbol = value;
        if (value != this.symbol){
            this._rootSymbolDifferent = true;
        }
    }

    get rootSymbolDifferent(): boolean {
        return this._rootSymbolDifferent;
    }

    get atmStrike(): BigNumber {
        return this._atmStrike;
    }

    set atmStrike(value: BigNumber) {
        this._atmStrike = value;
        this.atmStrikeStr = BNUtil.formatBN(value);
    }

    getOptionPair(strike: BigNumber){
        return this.optionPairs.find(pair => pair.strike.eq(strike))!;
    }

    updateKey(){
        this.key = `${this.rootSymbol}_${DateUtil.toOsiFormatFromDate(this._expiryDate)}`;
    }

}

export class OptionPair{

    // used as an identifier in the data table. value is set to expiryDateStr_strike
    key = "";
    show = false;   // indicates if this OptionPair should be displayed or hidden from view
    strikeStr = "";
    private _strike = BNUtil.ZERO;

    @Type(() => Quotable)
    call?: OptionContract;

    @Type(() => Quotable)
    put?: OptionContract;

    // reference back to the parent expiry
    optionChainExpiry: OptionChainExpiry;

    isAtm: boolean = false;
    // indicates if the current stock price is above atm strike
    // even if stock price and atm strike are same, this flag is set to true
    stockAboveAtm: boolean = true;

    // used while rendering the table to figure out what kind of row to render
    optionChainItemType: string = OptionChainItemType.OptionPair;

    constructor(optionChainExpiry: OptionChainExpiry) {
        this.optionChainExpiry = optionChainExpiry;
    }

    get strike(): BigNumber {
        return this._strike;
    }

    set strike(value: BigNumber) {
        this._strike = value;
        this.strikeStr = BNUtil.formatBN(value);
    }

    static constructKey(expiryDate: Date, strike: BigNumber){
        return `${DateUtil.toOsiFormatFromDate(expiryDate)}_${BNUtil.formatBN(strike, false)}`;
    }
}

type RowClass ="";

// Represents a put or a call contract
export class OptionContract extends Quotable {
    // when the OptionContract is associated with a leg, this value is set, otherwise it should be undefined
    // the OrderLeg also maintains a refrence to OptionContract in OrderLeg.quotable
    private _orderLeg?: OrderLeg;

    // No public setter for the following 4 properties
    // their values are set when OptionLeg is set on this contract
    // this rowColor is set based on the securityDirection. checkout the setter
    private _rowColor: string = "";
    // when a contract is selected, this rowClass is set based on the securityDirection and used to render the column colors in OptionChain
    // valid values are short-selection or long-selection
    private _rowClass: string = "";
    // indicates if this optionContract is added to the Order legs
    private _selected = false;
    // when this contract is selected, should it provide controls to move the strike price or expiry
    private _canMove = false;


    // if set to true, means this contract is already part of the legs and the user has selected this leg from amongst multiple legs
    legSelected = false;

    // indicates if this option contract in ITM
    isItm = false;

    // refers to the OptionPair with put/call
    // this value is undefined if this Contract is not derived from OptionChain
    optionPair? : OptionPair;

    get orderLeg(): OrderLeg | undefined {
        return this._orderLeg;
    }

    set orderLeg(value: OrderLeg | undefined) {
        this._orderLeg = value;
        if(value){
            this._selected = true;
            const securityDirection = value.securityDirection;
            const transactionType = value.transactionType;
            if (securityDirection == SecurityDirection.Long) {
                this._rowColor = "green";
                this._rowClass = "long-selection";
            }else if(securityDirection == SecurityDirection.Short){
                this._rowColor = "red";
                this._rowClass = "short-selection";
            }
            this._canMove = transactionType == TransactionType.Open;
        }else{
            this._selected = false;
            this._rowColor = "";
            this._rowClass = "";
            this._canMove = false;
        }
    }

    get selected(): boolean {
        return this._selected;
    }
    get rowColor(): string {
        return this._rowColor;
    }

    get rowClass(): string {
        return this._rowClass;
    }

    get canMove(): boolean {
        return this._canMove;
    }

    static createFromQuotable(quotable: Quotable){
        const optionContract = new OptionContract(quotable.underlying, quotable.securityType,
            quotable.symbol, quotable.rootSymbol,quotable.brokerSymbol, quotable.expiryDate, quotable.strikePrice);
        optionContract.rawQuote = quotable.rawQuote;
        optionContract.underlyingQuote = quotable.underlyingQuote;
        return optionContract;
    }

}

export class OptionChain{
    @Type(() => OptionChainExpiry)
    optionChainExpiries : OptionChainExpiry[] = [];
}


export class OptionExpiriesView{
    // expiryDates: string[] = [];
    // values are in format <yyyy-mm-dd>, labels are in format <Jan 31, 2022>
    expiryDates: VuetifySelection[] = [];

    // map of expiryDate to strikes
    // map key is <yyyy-mm-dd>, Values are list of <str,str>
    strikesMap : Record<string, VuetifySelection[]> = {};

}