import {AxiosResponse} from "axios";
import {axiosInstance} from "@/util/AxiosUtil";
import {BrokerUser} from "@/data/UserData";
import {plainToClass, plainToClassFromExist} from "class-transformer";
import {
    OptionChain,
    OptionChainExpiry, OptionContract,
    OptionExpiriesView,
    OptionPair,
    Quotable, SecurityQuote,
    SymbolSummary
} from "@/data/QuoteData";
import Util, {BNUtil} from "@/util/Util";
import _map from "lodash/map";
import _sortBy from "lodash/sortBy";
import {AsyncResponse, VuetifySelection} from "@/data/CommonData";
import {BrokerFactory} from "@/services/broker/BrokerFactory";
import {appGlobals} from "@/data/AppData";
import {eventEmitter} from "@/main";
import BigNumber from "bignumber.js";
import {AsyncStatus} from "@/data/EnumData";
import {BaseService} from "@/services/CommonService";

export default class QuoteService extends BaseService {
    constructor() {
        super();
    }

    async getOptionExpiriesWithStrikes(symbol:string) : Promise<OptionChainExpiry[]>{
        const response: AxiosResponse = await axiosInstance.get<OptionChainExpiry>(
            "/quotes/options/expiries/"+symbol
        );
        const data: any[] = response.data
        const optionChainExpiries: OptionChainExpiry[] = plainToClass(OptionChainExpiry, data);
        return optionChainExpiries;
        // return this.buildOptionExpiriesView(optionChainExpiries)
    }

    buildOptionExpiriesView(optionChainExpiries: OptionChainExpiry[]): OptionExpiriesView{
        const optionExpiriesView :OptionExpiriesView = new OptionExpiriesView();
        const expiries: VuetifySelection[] = [];
        const strikesMap : Record<string, VuetifySelection[]> = {};
        optionChainExpiries.forEach(oce => {
            const dateStr = oce.expiryDateStr;
            const expiryViewStr = Util.toExpiryViewStr(oce.expiryDate)
            expiries.push(new VuetifySelection(expiryViewStr, dateStr, false));
            const strikes = _map(oce.strikeStrs, strike => new VuetifySelection(strike, strike.toString(), false ))
            strikesMap[dateStr] = strikes;
        })
        optionExpiriesView.expiryDates = expiries;
        optionExpiriesView.strikesMap = strikesMap;

        return optionExpiriesView;
    }

    async getOptionChain(symbol:string, existingChain: OptionChain | undefined) : Promise<OptionChain>{
        const response: AxiosResponse = await axiosInstance.get<OptionChain>(
            "/quotes/options/optionChain/"+symbol
        );
        const data: any = response.data
        if (existingChain){
            // fill the existing object
            return plainToClassFromExist(existingChain, data);
        }else{
            return plainToClass(OptionChain, data);
        }
    }

    async getOptionChainExpiry(symbol:string, expiryDate: string) : Promise<OptionChainExpiry>{
        const response: AxiosResponse = await axiosInstance.get<OptionChainExpiry>(
            `/quotes/options/optionChain/${symbol}/${expiryDate}`
        );
        const data: any = response.data
        return plainToClass(OptionChainExpiry, data);
    }

    /**
     *  updates the quotes recursively. emits 'quotesLoaded' event when done
      */
    refreshQuotablesRecursively(brokerUser: BrokerUser, delay: number){
        let _this = this;
        this.refreshQuotables(brokerUser).then(function(){
            eventEmitter.emit("quotesLoaded", "");
            window.setTimeout(function(){
                _this.refreshQuotablesRecursively(brokerUser, delay)
            }, delay);
        }, function(error){
            if(error){
                console.log("Error in quotes - "+error);
            }
            window.setTimeout(function(){
                _this.refreshQuotablesRecursively(brokerUser, delay)
            }, delay);
            // depending on the error continue the loop or exit
        })
    }

    /**
     * Refreshes quotes on the quotableGroups
     * @param brokerUser
     */
    async refreshQuotables(brokerUser: BrokerUser): Promise<void>{
        const brokerDataMap = appGlobals.getBrokerDataMap(brokerUser);
        const quotableGroups = brokerDataMap.quotableGroups;
        const quotables : Quotable[] = []
        quotables.push(...quotableGroups.positions);
        quotables.push(...quotableGroups.orders);
        quotables.push(...quotableGroups.trade);
        quotables.push(...quotableGroups.watchlist);
        quotables.push(...quotableGroups.symbolSummaries);
        quotables.push(...quotableGroups.optionChain);
        quotables.push(...quotableGroups.optionChainFarOTM);
        const brokerQuoteService = BrokerFactory.getQuoteService(brokerUser.broker!);
        if (quotables.length > 0){
            await brokerQuoteService.updateQuotes(brokerUser, quotables);
        }
    }

    async updateQuotables(quotables: Quotable[] ): Promise<void>{
        const brokerUser = appGlobals.selectedBrokerUser;
        const brokerQuoteService = BrokerFactory.getQuoteService(brokerUser.broker!);
        if (quotables.length > 0){
            await brokerQuoteService.updateQuotes(brokerUser, quotables);
        }
    }

    async updateSymbolSummary(symbolSummary:SymbolSummary): Promise<void>{
        const brokerUser = appGlobals.selectedBrokerUser;
        const quotable = Quotable.getStockQuotable(symbolSummary.symbol);
        symbolSummary.quotable = quotable;

        const quotables: Quotable[] = [];
        quotables.push(quotable);

        const symbolSummariesQuotables = appGlobals.userBrokerDataMap[brokerUser.brokerUserId].quotableGroups.symbolSummaries;
        symbolSummariesQuotables.splice(0, symbolSummariesQuotables.length, ...quotables);
        // wait for quotables to be refreshed
        await this.refreshQuotables(brokerUser);
    }

    async getOptionExpiries(symbol: string) : Promise<AsyncResponse<OptionChainExpiry[]>>{
        const brokerUser = appGlobals.selectedBrokerUser;
        const brokerQuoteService = BrokerFactory.getQuoteService(brokerUser.broker!);
        const optionChainExpiriesResponse: AsyncResponse<Map<string, OptionChainExpiry[]>> = await brokerQuoteService.getOptionExpiries(brokerUser, symbol);
        const response: AsyncResponse = new AsyncResponse<OptionChainExpiry[]>();
        if(optionChainExpiriesResponse.status == AsyncStatus.Ok){
            const optionChainExpiriesMap = optionChainExpiriesResponse.data!;
            //combine the expiries lists from multiple rootSymbols into one list
            let combinedOptionExpiries: OptionChainExpiry[] = [];
            for(let rootSymbolKey of optionChainExpiriesMap.keys()){
                combinedOptionExpiries.push(...optionChainExpiriesMap.get(rootSymbolKey)!)
            }
            if(optionChainExpiriesMap.size > 1){
                // sort the combinedOptionExpiries
                combinedOptionExpiries = _sortBy(combinedOptionExpiries, optionExpiry => optionExpiry.daysToExpiry, optionExpiry => optionExpiry.rootSymbol);
            }
            response.data = combinedOptionExpiries;
        }else{
            response.status = AsyncStatus.Error;
            response.businessErrors = optionChainExpiriesResponse.businessErrors;
        }
        return response;
    }

    /**
     * Builds OptionPairs for OptionChainExpiry by invoking Broker APIs
     * @param stockQuotable
     * @param optionChainExpiry
     */
    async updateOptionPairs(stockQuotable: Quotable, optionChainExpiry: OptionChainExpiry) : Promise<void>{
        const brokerUser = appGlobals.selectedBrokerUser;
        const brokerQuoteService = BrokerFactory.getQuoteService(brokerUser.broker!);
        await brokerQuoteService.updateOptionPairs(brokerUser, optionChainExpiry);
    }


}

export const quoteService = new QuoteService();