




















import { defineComponent, Ref, ref, SetupContext } from "@vue/composition-api";
import {axiosInstance, genericAxiosInstance} from "@/util/AxiosUtil";
import {AxiosError, AxiosInstance, AxiosResponse} from "axios";
import { BadRequest } from "@/data/AppData";
import {plainToClass} from "class-transformer";
import {eventEmitter} from "@/main";

interface MessageProps {
  errors: string[];
  message: string[];
}

export default defineComponent({
  name: "MessagesView",
  // props: "",
  setup(props: undefined, context: SetupContext) {
    const showErrorToast: Ref = ref<boolean>(false);
    const showMessageToast: Ref = ref<boolean>(false);
    const messageToast: Ref = ref<string>("");
    const errorToast: Ref = ref<string>("");

    const axiosInstances: AxiosInstance[] = [axiosInstance];
    for(let axiosInstanceItem of axiosInstances){
      // attach interceptor to axios instance to handle exceptions and display errors/messages
      axiosInstanceItem.interceptors.response.use(
          function(response) {
            return response;
          },
          function(error: AxiosError) {
            if (error && error.response) {
              const axiosResponse: AxiosResponse = error.response;
              if (axiosResponse) {
                if (
                    axiosResponse.status == 400 &&
                    (axiosResponse.data.messages || axiosResponse.data.errors)
                ) {
                  const badRequest: BadRequest = plainToClass(BadRequest, axiosResponse.data);
                  if (badRequest.messages && badRequest.messages.length > 0) {
                    show(badRequest.messages);
                  }
                  if (badRequest.errors && badRequest.errors.length > 0) {
                    show(badRequest.errors, false);
                  }
                } else {
                  show(["Something went wrong. Please try again later."], false);
                }
              }
            } else {
              show(["Something went wrong. Please try again later."], false);
            }
            return Promise.reject(error);
          }
      );
    }

    // listen to showMessage and showError events thrown from other components and display errors messages
    eventEmitter.on("showMessage", (messages: string[]) => {
      show(messages);
    });
    eventEmitter.on("showError", (errors: string[]) => {
      show(errors, false);
    });

    function show(messages: string[], isMessage = true): void {
      if (isMessage) {
        showMessageToast.value = true;
        messageToast.value = messages.join("\n");
      } else {
        showErrorToast.value = true;
        errorToast.value = messages.join("\n");
      }
    }

    return {
      showErrorToast,
      showMessageToast,
      errorToast,
      messageToast
    };
  }
});
