



















import {defineComponent, onMounted, reactive, ref, SetupContext, watch} from "@vue/composition-api";
import SymbolSummary from "@/views/trade/SymbolSummary.vue";
import OptionChain from "@/views/trade/OptionChain.vue";
import TradeSettingsBar from "@/views/trade/TradeSettingsBar.vue";
import TradeTicket from "@/views/trade/TradeTicket.vue";
import TradeControls from "@/views/trade/TradeControls.vue";
import StockFundamentals from "@/views/trade/StockFundamentals.vue";
import {tradeService} from "@/services/TradeService";
import {eventEmitter} from "@/main";
import {TradeAnalysis, TradeTab} from "@/data/TradeData";
import {Order} from "@/data/OrdersData";

class ComponentHeights{
  symbolSummaryHeight = 65;
  tradeSettingsHeight = 32;
  tradeTicketHeight  = 190;
  expandableAreaHeight = 600;
  tradeControlsHeight = 30;
}

export default defineComponent({
  name: "TradeMain",
  components: {
    'SymbolSummary': SymbolSummary,
    'OptionChain': OptionChain,
    "TradeSettingsBar": TradeSettingsBar,
    "TradeControls": TradeControls,
    "TradeTicket": TradeTicket,
    "StockFundamentals": StockFundamentals
  },
  setup(props: undefined, context: SetupContext) {

    const symbol = ref<string>("");
    const tradeExpandableAreaDiv = ref<HTMLDivElement>();
    const tradeData = tradeService.tradeData;
    const componentHeights = reactive(new ComponentHeights());

    // initialize the tradeService
    tradeService.init();

    function onSymbolChange(updatedSymbol: string){
      symbol.value = updatedSymbol;
    }

    function onResize(){
      if(tradeExpandableAreaDiv.value){
        // subtract the heights of symbolSummary, TradeTicket and Trade Settings
        let tradeControlsHeight = componentHeights.tradeControlsHeight;
        if (tradeData.tradeSettings.selectedTab != TradeTab.OptionChain){
          tradeControlsHeight = 0;
        }
        componentHeights.expandableAreaHeight = window.innerHeight - componentHeights.symbolSummaryHeight
                                  - componentHeights.tradeTicketHeight - componentHeights.tradeSettingsHeight - tradeControlsHeight;
      }
    }

    onMounted(() => {
      onResize();
      // set the event listeners in Vue, so that the listeners won't be triggered when the current view is not active
      eventEmitter.on("quotesLoaded", function(){
        tradeService.updateOrderTotal(tradeData.order as Order, tradeData.tradeAnalysis as TradeAnalysis);
      });
    })

    watch(()=> tradeData.mode, () => {
      console.log("Tradedata Mode watch triggered");
      if (tradeData.mode.isPreviewMode){
        componentHeights.tradeTicketHeight = 200;
      }else{
        componentHeights.tradeTicketHeight = 190;
      }
      onResize();
    })
    watch(() => tradeData.tradeSettings.selectedTab, () => {
      onResize();
    });

    return {
      symbol,
      onSymbolChange,
      tradeExpandableAreaDiv,
      onResize,
      tradeData,
      componentHeights,
      TradeTab
    }
  }
})

