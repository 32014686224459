













import {
  defineComponent,
  reactive,
  SetupContext,
  onMounted
} from "@vue/composition-api";
import { AmplifyEventBus } from "aws-amplify-vue";
import { Auth } from "aws-amplify";
import { CognitoUserSession, CognitoUser } from "amazon-cognito-identity-js";
import { userService } from "@/services/UserService";
import { appGlobals, ObjectLiteral } from "@/data/AppData";
import { User } from "@/data/UserData";
import AuthService, { authService } from "@/services/AuthService";

export default defineComponent({
  name: "CognitoAuth",
  setup(props: undefined, context: SetupContext) {
    const authConfig = reactive({
      signUpConfig: {
        hiddenDefaults: ["phone_number"], // hide the phone number field
        signUpFields: [
          {
            label: "Your Name",
            key: "name",
            placeholder: "Name",
            required: true,
            displayOrder: 3
          }
        ]
      },
      signInConfig: {
        isSignUpDisplayed: false  // this disables the option to create an account
      },
      usernameAttributes: "email"
    });
    const amplifyEventsQueue: string[] = [];

    onMounted(() => {
      setupAmplifyEvents(context);
    });

    return {
      authConfig
    };
  }
});

function setupAmplifyEvents(context: SetupContext) {
  AmplifyEventBus.$on("authState", async (event: string) => {
    console.log("Auth event ---- " + event);
    if (event == "signedIn") {
      const isValid: boolean = await authService.refreshSession();
      if (isValid) {
        userService.startUserSession();
        context.root.$router.push("main");
      }
    }else if(event == "signedOut"){
      context.root.$router.push("/");
    }
  });

}

async function getAuthenticatedUserInfo(): Promise<any> {
  // returns a valid user if present, otherwise returns null or empty object, doesn't reject with error
  const userInfo = await Auth.currentUserInfo();
  // no type defined for userInfo
  if (userInfo && userInfo.id) {
    return userInfo;
  }
  return null;
}

/**
 * Refreshes the session, stores the jwt in globals and returns if user is valid or not
 */
async function refreshSession(context: SetupContext): Promise<boolean> {
  try {
    const session: CognitoUserSession = await Auth.currentSession();
    if (session && session.getAccessToken()) {
      console.log(session.getAccessToken().getJwtToken());
      context.root.$appGlobals.token = session.getAccessToken().getJwtToken();
    }
    return session.isValid();
  } catch (e) {
    // is not valid if we get an error. Cognito throws errors sometimes indicating an invalid session
    console.log(e);
    return false;
  }
}
