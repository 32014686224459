









































import {defineComponent, SetupContext, PropType} from "@vue/composition-api";
import {PositionItem, SymbolItem} from "@/data/PositionsData";
import {SecurityType} from "@/data/EnumData";
import BigNumber from "bignumber.js";


interface Props{
  symbolItem: SymbolItem,
  quantityFilled: string, // identifies the amount quantity that is filled, used in orders screen
  editableQuantity: boolean
}

export default defineComponent({
  name: "SymbolItemDisplay",
  props: {
    symbolItem: Object as PropType<SymbolItem>,
    quantityFilled: {
      type: String,
      defaultValue: ""
    },
    editableQuantity: {
      type: Boolean,
      defaultValue: false
    }
  },
  setup(props: undefined, context: SetupContext) {

    function quantityChanged(){
      context.emit("quantity-changed");
    }

    return {
      quantityChanged
    }
  }
})


