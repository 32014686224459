








































































































































































































































































import {computed, defineComponent, onMounted, reactive, Ref, ref, set, SetupContext} from "@vue/composition-api";
import {positionColumnsConfig, PositionItem} from "@/data/PositionsData";
import {UserAccount,BrokerUser} from "@/data/UserData";
import {appGlobals} from "@/data/AppData";
import SymbolItemDisplay from "@/views/positions/SymbolItemDisplay.vue";
import usePositionsSortBy from "@/views/positions/composables/usePositionsSortBy";
import {useSelectPositions, PositionSelections} from "@/views/positions/composables/useSelectPositions";
import {usePositionColumns} from "@/views/positions/composables/usePositionColumns";
import ColumnSettings from "@/views/user/ColumnSettings.vue";
import PositionTags from "@/views/positions/PositionTags.vue";
import {positionService} from "@/services/PositionService";
import _map from "lodash/map";
import _min from "lodash/min";
import _sortBy from "lodash/sortBy";
import _groupBy from "lodash/groupBy";
import _sumBy from "lodash/sumBy";
import _every from "lodash/every";
import _filter from "lodash/filter";
import _split from "lodash/split";
import {BNUtil, NumberUtil} from "@/util/Util";
import {PositionItemType, AccountIconIndexMapping, PositionGrouping,SecurityTypeFilter} from "@/data/EnumData";
import {genericAxiosInstance} from "@/util/AxiosUtil";
import {eventEmitter} from "@/main";
import {tradeService} from "@/services/TradeService";

class PositionFilterCriteria{
  symbol?: string;
  accountId?: string;
  tags?: string[];
  positionTypes?: string[] = [];
  accounts: string[] = [];
}
interface Props{
  componentHeight: number;
}

export default defineComponent({
  name: "Positions",
  components: {SymbolItemDisplay, ColumnSettings, PositionTags},
  props: {
    componentHeight: Number
  },
  setup(props: Props, context: SetupContext) {
    let dataLoadingInd = ref<boolean>(true);
    let positionItems: PositionItem[] = reactive([]);
    let positionsHeaderItem = reactive(new PositionItem()); // this item holds the totals displayed in the Positions header

    let symbolsList: string[] = reactive([]);
    let showExpandAll = ref<boolean>(false); // used to show "expand all" icon when grouped by symbol
    let allExpanded = ref<boolean>(false); // used to track state of "expand all" icon. "Expand all" icon is a toggle
    let selections = new PositionSelections(); // tracks selected positions, which are used to enable tag and close buttons

    // filter and group
    let groupedBy = ref<string>(PositionGrouping.None);  // possible values are none or symbol
    let groupByItems = reactive(Object.values(PositionGrouping));
    let filterCriteria = reactive(new PositionFilterCriteria());
    filterCriteria.positionTypes = ["stocks", "options"];
    let allUserAccounts: UserAccount[] = reactive([]);  // allUserAccounts for filtering
    let allTags:string[] = reactive([]);

    let showAddTags = ref<boolean>(false);  // flag used to open up 'Add Tags' dialog

    // composables
    const {customSort} = usePositionsSortBy(groupedBy);
    const {selectPositions,enableCloseButton,enableTagButton,clearAllPositions,selectAllPositions} = useSelectPositions(selections);
    const {positionHeaders, positionColumnsReactive, startColumnSettings,saveColumnSettings,showColumnSettings,availableColumns,selectedColumns} = usePositionColumns(context);
    
    function updateSelections(positionItem: PositionItem){
      console.log("row clicked");
      if(positionItem.type=='SymbolPosition') {
        positionItem.expanded = !positionItem.expanded; 
        positionItem.toggleChildrenVisibility(positionItem.expanded);
      } else if(positionItem.type=='Position') {
        selectPositions(positionItem);
      } 
    }

    /**
     * load account list for filters
     */
    function loadFilterUserAccounts() {
      let brokerUsers: BrokerUser[] = appGlobals.user!.brokerUsers;
      if(appGlobals.user!.brokerUsers) {
        brokerUsers.forEach(brokerUser => allUserAccounts.push(...brokerUser.userAccounts));
      } 
    }

    function toggleAllChildDisplay(){
      //if(allExpanded) collapse and vice versa
      for(let positionItem of positionItems){
        positionItem.expanded = !allExpanded.value; 
        positionItem.toggleChildrenVisibility(positionItem.expanded);
      }
      allExpanded.value = !allExpanded.value; 
    }

    /**
     * Loads positions from the backend/remote server
     * This is invoked only the first time when the page is mounted
     */
    function loadPositions(){
      let remotePositions: PositionItem[] = positionService.getCachedPositions()
      // flatten the remote positions
      processRemotePositions(remotePositions);
      dataLoadingInd.value = false;
      let positionTags: string[] = [];
      positionItems.forEach(item => {
        symbolsList.push(item.underlying);
        if(item.tags.length > 0) {
          positionTags.push(...item.tags);
        }
      });
      let all:Set<string> =new Set();
      if(positionTags.length > 0) {
        positionTags.sort();
        all = new Set(positionTags);
      }
      allTags.push("Not Tagged");
      allTags.push("Any");
      allTags.push(...all);
    }

    function processRemotePositions(remotePositions : PositionItem[]){
      // sort them by symbol
      remotePositions = _sortBy(remotePositions, "symbol", position => position.daysToExpiry)
      // replace positionItems grid with remote positions
      remotePositions = filterAndGroup(remotePositions, groupedBy, filterCriteria);
      // set the newPositions in the grid
      positionItems.splice(0, positionItems.length, ...remotePositions);
      // apply quotes
      if (positionItems.length > 0){
        applyQuotes(positionItems);
      }
      // websockets();
    }

    function filterAndGroup(positions: PositionItem[], groupedBy: Ref<string>, filterCriteria: PositionFilterCriteria){
      if (filterCriteria.symbol) { 
        let symbols: string[] =[];
        let result: PositionItem[] = [];
        _split(filterCriteria.symbol,",").forEach(element => {
          result.push(..._filter(positions, position => position.underlying.toUpperCase() == element.trim().toUpperCase()));
        })
        positions.splice(0, positions.length,...result);
      };
  
      if(filterCriteria.positionTypes?.length == 0 || filterCriteria.positionTypes?.length == 2) {
        // no or all selections, do nothing
      } else if(filterCriteria.positionTypes?.length == 1){
              if(filterCriteria.positionTypes[0] ==SecurityTypeFilter.Options) {
               positions = _filter(positions, position => position.securityType.isOption);    
               //positions.splice(0, positions.length, ...options);   
              } else if(filterCriteria.positionTypes[0]==SecurityTypeFilter.Stocks) {
               positions = _filter(positions, position => position.securityType.isStock);  
               //positions.splice(0, positions.length, ...stocks);     
              }
      } else {
        console.log("Unhandled selections");
      }

      // filter by accounts
      if(filterCriteria.accounts && filterCriteria.accounts.length > 0){
        let result: PositionItem[] = [];
        filterCriteria.accounts.forEach(element => {
          result.push(..._filter(positions, position => position.userAccount!.accountNumber == element));
          //console.log("rs: "+result.length);
        });
        positions.splice(0, positions.length,...result);
      }

      // filter by tags
      let result: PositionItem[] = [];
      if(filterCriteria.tags && filterCriteria.tags.length > 0) {
        
        if(filterCriteria.tags.includes("Not Tagged")){
          positions.forEach(position => {
          if(!position.tags || position.tags.length == 0) {
            result.push(position);
          }
        });
        }
        if(filterCriteria.tags.includes("Any")){
          positions.forEach(position => {
          if(position.tags && position.tags.length > 0) {
            result.push(position);
          }
          });
        } else {
          positions.forEach(position => {
            if(position.tags && position.tags.length > 0) {
              position.tags.forEach(positionTag => {
                filterCriteria.tags?.forEach(tag => {
                  if(positionTag == tag && !result.includes(position)) result.push(position)
                })
              });
            }
          });
        }
        positions.splice(0, positions.length,...result);
      }

      // perform grouping
      if (groupedBy.value == PositionGrouping.Symbol){

        showExpandAll.value = true;
        allExpanded.value = false;

        const groupedPositions = _groupBy(positions, "underlying");
        for (let underlying in groupedPositions) {

          const symbolPositions = groupedPositions[underlying];
          // hide symbolPositions
          for(let symbolPosition of symbolPositions){
            symbolPosition.show = false;
          }
          // create new Position Item for the symbol grouping
          const firstChild = symbolPositions[0];
          const position = new PositionItem();
          position.type = PositionItemType.SymbolPosition;
          position.symbol = underlying;
          position.underlying = underlying;
          position.userAccount = firstChild.userAccount;
          var iconIndex = firstChild.userAccount!.iconIndex;
          var countUserAccount = getPostionUserAccount(symbolPositions)
          if(countUserAccount > 1) {
            position.multipleUserAccounts = true;
          } else {
            position.multipleUserAccounts = false;
            position.iconColor = AccountIconIndexMapping[iconIndex];
          }
          // position.userAccount   // TODO if there is only one userAccount set it, otherwise set multiple to true

          position.showToggle = true;
          position.expanded = false;
          position.show = true;
          // add all children
          position.children.splice(0, position.children.length, ...symbolPositions);

          // insert this position into positions just before the first child.. finds child index by object reference
          const indexOfFirstChild = positions.findIndex(item => item == firstChild);
          positions.splice(indexOfFirstChild, 0, position);
        }
      } else if (groupedBy.value == PositionGrouping.None){
        for(let position of positions){
          position.show = true;
        }
      }
      return positions;
    }

    function updateFiltersAndGrouping(){
      console.log("**Types: "+filterCriteria.positionTypes);
      if (!groupedBy.value){
        groupedBy.value = PositionGrouping.None;
      }
      if (groupedBy.value == PositionGrouping.Symbol){
        showExpandAll.value = true;
        allExpanded.value = false;
      } else {
        showExpandAll.value = false;
      }
      let remotePositions = positionService.getCachedPositions();
      processRemotePositions(remotePositions);
      clearAllPositions(positionItems);
    }

    /**
     * The initial positions that are retrieved have minimal information (symbol, tradePrice, quantity)
     * We enhance this positions data by applying the latest quotes obtained from backend
     * First apply quotes on individual position items that are not grouped yet
     * @param positions
     */
    function applyQuotes(positions: PositionItem[]){
      for(let position of positions){
        // children length zero indicates this item is not at group level
        if (position.children.length == 0){
          // apply quotes for individual positions
          const quote = position.quotable?.rawQuote;
          if (quote){
            let posQuantity = Math.abs(position.quantity);    // positive quantity
            position.currentPricePerUnit = position.quantity < 0 ? quote.price.negated() : quote.price;
            position.priceChangePerUnit = quote.change;

            position.deltaPerUnit = quote.delta;
            position.thetaPerUnit = quote.theta;
            position.gammaPerUnit = quote.gamma;
            position.vegaPerUnit = quote.vega;
            position.impliedVolatility = position.securityType.isOption ? quote.optionImpliedVolatility: quote.stockImpliedVolatility;
            
            if (position.quantity < 0){
              position.deltaPerUnit = quote.delta.negated();
              position.thetaPerUnit = quote.theta.negated();
              position.gammaPerUnit = quote.gamma.negated();
              position.vegaPerUnit = quote.vega.negated();
            }
            const multiplicationFactor = position.securityType.isOption ? posQuantity*100 : posQuantity;
            position.cost = position.tradePricePerUnit.multipliedBy(multiplicationFactor);
            position.netLiquidity = position.currentPricePerUnit.multipliedBy(multiplicationFactor);
            position.totalDelta = position.deltaPerUnit.multipliedBy(multiplicationFactor);
            position.totalTheta = position.thetaPerUnit.multipliedBy(multiplicationFactor);
            position.totalGamma = position.gammaPerUnit.multipliedBy(multiplicationFactor);
            position.totalVega = position.vegaPerUnit.multipliedBy(multiplicationFactor);

            position.updateITMFlag();

            // Add the position's values to the header/column totals
            // positionsHeaderItem tracks column totals
            positionsHeaderItem.addToCost(position.cost);
            positionsHeaderItem.addToNetLiquidity(position.netLiquidity);
            positionsHeaderItem.addToTotalDelta(position.totalDelta);
            positionsHeaderItem.addToTotalTheta(position.totalTheta);
            positionsHeaderItem.addToTotalGamma(position.totalGamma);
            positionsHeaderItem.addToTotalVega(position.totalVega);            
          }
        }
      }
      // for some unit columns, header level totals are not the total of unit values. the unit columns show same value as totals
      positionsHeaderItem.tradePricePerUnit = positionsHeaderItem.cost;
      positionsHeaderItem.currentPricePerUnit = positionsHeaderItem.netLiquidity;
      positionsHeaderItem.deltaPerUnit = positionsHeaderItem.totalDelta;
      positionsHeaderItem.thetaPerUnit = positionsHeaderItem.totalTheta;
      positionsHeaderItem.gammaPerUnit = positionsHeaderItem.totalGamma;
      positionsHeaderItem.vegaPerUnit = positionsHeaderItem.totalVega;

      applyQuotesForGroups(positions);    
    }

    /**
     * Updates quotes at grouping and header level
     * @param positions
     */
    function applyQuotesForGroups(positions: PositionItem[]){
      for(let position of positions){
        // get the parent rows
        if(position.children.length > 0){
          // get the first child
          const firstChild: PositionItem = position.children[0];
          // get the stock quote
          const stockQuote = firstChild.securityType.isOption ? firstChild.quotable?.underlyingQuote : firstChild.quotable?.rawQuote;
          position.stockPrice = stockQuote?.price || BNUtil.ZERO;
          position.stockPriceChange = stockQuote?.change || BNUtil.ZERO;

          let options = position.getOptionChildren();
          if(options.length > 0) {
            position.expiryDate = _min(options.map(child => child.expiryDate))!;  
            position.itm = _filter(options, child => child.itm).length > 0
          }
          
          let children = position.children;
          // check if children are all stock or if it's mixed, because that determines the gcd
          // if it is all stock, we can take average of prices
          // if it is all option - we can take gcd of option contract quantity
          // if it is a mix of stock and option - have to take gcd of stockQuantity and (option quantities multiplied by 100)
          let allStock = _every(children, child => !child.securityType.isOption);
          let allOption = _every(children, child => child.securityType.isOption);
          let mixedType = !allStock && !allOption;
            let gcd = 0;
            if (mixedType){
              gcd = NumberUtil.gcdArray(..._map(children, child => child.securityType.isOption ? Math.abs(child.quantity) *100 : Math.abs(child.quantity)));
            }else if (allOption){
              gcd = NumberUtil.gcdArray(..._map(children, child => Math.abs(child.quantity)));
            }
          let tradePricePerUnit = BNUtil.ZERO;      
          let currentPricePerUnit = BNUtil.ZERO;
          let deltaPerUnit = BNUtil.ZERO;
          let thetaPerUnit = BNUtil.ZERO;
          let gammaPerUnit = BNUtil.ZERO;
          let vegaPerUnit = BNUtil.ZERO;
          let totalDelta = BNUtil.ZERO;
          let totalTheta = BNUtil.ZERO;
          let totalGamma = BNUtil.ZERO;
          let totalVega = BNUtil.ZERO;
          for(let child of children){
            const posQuantity = Math.abs(child.quantity);
            let quantityFactor: number = posQuantity;  // for allStock
            if (allOption){
              quantityFactor = posQuantity/gcd;
            }else if(mixedType){
              // we have to normalize all security types to multiples of 100
              quantityFactor = child.securityType.isOption ? posQuantity*100/gcd : posQuantity/gcd;
            }
            tradePricePerUnit = tradePricePerUnit.plus(child.tradePricePerUnit.multipliedBy(quantityFactor));
            currentPricePerUnit =  currentPricePerUnit.plus(child.currentPricePerUnit.multipliedBy(quantityFactor));
            deltaPerUnit = deltaPerUnit.plus(child.deltaPerUnit.multipliedBy(quantityFactor));
            thetaPerUnit = thetaPerUnit.plus(child.thetaPerUnit.multipliedBy(quantityFactor));
            gammaPerUnit = gammaPerUnit.plus(child.gammaPerUnit.multipliedBy(quantityFactor));
            vegaPerUnit = vegaPerUnit.plus(child.vegaPerUnit.multipliedBy(quantityFactor));
            totalDelta =  totalDelta.plus(child.totalDelta);
            totalTheta = totalTheta.plus(child.totalTheta);
            totalGamma = totalGamma.plus(child.totalGamma);
            totalVega = totalVega.plus(child.totalVega);
          }
          if (allStock){
            // for allStock scenario, we'll just have to average out the price
            const allStockQuantity = _sumBy(children, child => Math.abs(child.quantity));
            position.tradePricePerUnit = tradePricePerUnit.dividedBy(allStockQuantity);
            position.currentPricePerUnit = currentPricePerUnit.dividedBy(allStockQuantity);
            position.deltaPerUnit = BNUtil.of("1");
            position.totalDelta = position.deltaPerUnit.multipliedBy(allStockQuantity);
          }else{
            position.tradePricePerUnit = tradePricePerUnit;
            position.currentPricePerUnit = currentPricePerUnit;
            position.deltaPerUnit = deltaPerUnit;
            position.thetaPerUnit = thetaPerUnit;
            position.gammaPerUnit = gammaPerUnit;
            position.vegaPerUnit = vegaPerUnit;
            position.totalDelta = totalDelta;
            position.totalTheta = totalTheta;
            position.totalGamma = totalGamma;
            position.totalVega = totalVega;

          }
        }
        
      }
    }

    function startTagsDialog() {
      // just enable the flag and rest is taken care of by the child component PositionTags
      showAddTags.value = true;
    }

    // let gridHeight = ref(500);
    let gridHeight = computed(() => {
      // subtract
      console.log("In Positions - setting height - "+props.componentHeight);
      return props.componentHeight - 80;
    })

    onMounted(() => {
      console.log("Positions View Mounted");
      eventEmitter.on("positionsLoaded", () => {
        console.log("Positions loaded   ");
        loadPositions();
      });
      loadFilterUserAccounts();
      eventEmitter.on("positionsUpdated", (event) => {
        // reload the entire grid
        updateFiltersAndGrouping();
      });
      eventEmitter.on("quotesLoaded", (event) => {
        applyQuotes(positionItems);
      });
      // if appGlobals flag indicates that positions are already loaded
      // this situations happens when moving between routes
      if (appGlobals.positionsLoaded){
        loadPositions();
      }
    });

    function closeSelectedPositions(){
      tradeService.addPositionsForClosing(selections.selectedPositions).then(function(proceedWithClosing: boolean){
        if (proceedWithClosing){
          // navigate to Trade Screen on
          context.root.$router.push("/main/trade");
        }
      });
    }

    let filterPositionType: string[]= reactive([]);

    return {
      gridHeight,
      dataLoadingInd,
      positionItems,
      positionsHeaderItem,
      groupedBy,
      groupByItems,
      filterCriteria,
      symbolsList,
      selections,
      updateSelections,
      toggleAllChildDisplay,
      updateFiltersAndGrouping,
      showAddTags,
      startTagsDialog,
      // filters
      allUserAccounts,
      allTags,
      // indicators for expand all positions
      showExpandAll,
      allExpanded,
      // from usePositionColumns
      showColumnSettings,
      selectedColumns,
      availableColumns,
      positionColumnsConfig,
      positionHeaders,
      positionColumnsReactive,
      startColumnSettings,
      saveColumnSettings,
      // from useSelectPositions
      enableCloseButton,
      enableTagButton,
      clearAllPositions,
      selectAllPositions,
      // from usePositionsSortBy
      customSort,
      // to update tags
      closeSelectedPositions
    }
  }
});

function getPostionUserAccount(symbolPositions:any){
  var flags = [], output = [], l = symbolPositions.length, i;
    for( i=0; i<l; i++) {
        if( flags[symbolPositions[i].userAccount.id]) continue;
        flags[symbolPositions[i].userAccount.id] = true;
        output.push(symbolPositions[i].userAccount.id);
    }
  return output.length
}

function websockets(){
  const brokerUser = appGlobals.user?.brokerUsers[0];
  const config = {
    headers:
        {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + brokerUser?.accessToken
        }
  }
  genericAxiosInstance.post("/markets/events/session", undefined, config).then(function(response){
    const sessionId =  response.data["stream"]["sessionid"];
    const payload = {
      sessionid: sessionId,
      symbols: ["SPG", "MELI220121P01700000"],
      linebreak: true,
      filter: ["quote"]
    }
    const ws = new WebSocket("wss://ws.generic.com/v1/markets/events")
    ws.onopen = function(event){
        ws.send(JSON.stringify(payload));
    }
    ws.onmessage = function(event){
      console.log(event.data);
    }
  })
}

