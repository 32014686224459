

















import {
  computed,
  defineComponent,
  reactive,
  SetupContext
} from "@vue/composition-api";
import router from "@/router";

export default defineComponent({
  name: "HomeToBar",
  setup(props: undefined, context: SetupContext) {
    const items: Array<{ icon: string; text: string; to: string }> = reactive([
      {
        icon: "mdi-view-dashboard",
        text: "Home",
        to: "/",
        key: "home"
      },
      {
        icon: "mdi-currency-usd",
        text: "Pricing",
        to: "/",
        key: "pricing"
      },
      {
        icon: "mdi-account-multiple-plus",
        text: "Register",
        to: "/auth",
        key: "login"
      },
      {
        icon: "mdi-fingerprint",
        text: "Login",
        to: "/auth",
        key: "login"
      }
    ]);
    const titles: { [key: string]: string } = {
      "/pages/lock": "Lock Page",
      "/pages/login": "Login Page",
      "/pages/pricing": "Pricing Page",
      "/pages/register": "Register Page"
    };
    const title = computed(() => titles[context.root.$route.path]);

    function navigate(navigationKey: string){
      const currentRouteName = router.currentRoute.name;
      //
      if (navigationKey == "pricing"){
        if (currentRouteName != "Landing"){
          router.push("/home");
          context.root.$nextTick().then(function(){
            scrollToElement("pricingSection");
          });
        }else{
          scrollToElement("pricingSection");
        }
      }else if(navigationKey == "home"){
        currentRouteName == "Landing" ? scrollToElement("summarySection") : router.push("/home");
      }else if(navigationKey == "login"){
        if (currentRouteName != "Auth"){
          router.push("/auth")
        }
      }
      // document.getElementById(optionPairKey)!.scrollIntoView({block: "center", behavior: "smooth"});
    }

    function scrollToElement(elementId: string){
      document.getElementById(elementId)!.scrollIntoView({block: "center", behavior: "smooth"});
    }

    return {
      titles,
      items,
      title,
      navigate
    };
  }
});


