import {BrokerUser, User, UserAccount, UserBrokerData, UserPreferences} from "@/data/UserData";
import {AxiosResponse} from "axios";
import {axiosInstance} from "@/util/AxiosUtil";
import {appGlobals} from "@/data/AppData";
import {classToPlain, plainToClass} from "class-transformer";
import {positionService} from "@/services/PositionService";
import {BrokerFactory} from "@/services/broker/BrokerFactory";
import {quoteService} from "@/services/QuoteService";
import {accountService} from "@/services/AccountService";
import {eventEmitter} from "@/main";
import {orderService} from "@/services/OrderService";
import {AsyncResponse, VuetifySelection} from "@/data/CommonData";
import {AsyncStatus} from "@/data/EnumData";

export default class UserService {

  async createUser(user: User): Promise<User> {
    const response: AxiosResponse = await axiosInstance.post<User>("/users");
    return response.data;
  }

  /**
   * Loads user data,
   */
  async startUserSession(): Promise<User>{
    const user:User =  await this.getUserDetails();
    // initialize BrokerDataMap
    const userBrokerDataMap = appGlobals.userBrokerDataMap;
    user.brokerUsers.forEach(brokerUser => {
      if(!userBrokerDataMap[brokerUser.brokerUserId]){
        userBrokerDataMap[brokerUser.brokerUserId] = new UserBrokerData();
      }
    })

    const primaryUserAccount = user.getPrimaryAccount();
    if (primaryUserAccount){
      appGlobals.selectedUserAccount = primaryUserAccount;
      appGlobals.selectedBrokerUser = user.getBrokerUser(primaryUserAccount.brokerUserId!)!;
      // let the data load in the background
      this.initializeData(user);
    }
    return user;
  }

  /**
   * Retrieves user details and sets up data
   * @param firstLoad
   */
  async getUserDetails(): Promise<User> {
    const response: AxiosResponse = await axiosInstance.get<User>(
      "/users/oauth"
    );
    const data: any = response.data;
    const user = plainToClass(User, data, { enableImplicitConversion: true });
    // for each userAccount, set the reference back to brokerUser because the remote data doesn't set this info
    user.brokerUsers.forEach(brokerUser => {
      brokerUser.userAccounts.forEach(userAccount => {
        userAccount.brokerUserId = brokerUser.brokerUserId;
      })
    })
    appGlobals.user = user;
    return user;
  }

  async getUserAccounts(): Promise<UserAccount[]>{
    const response: AxiosResponse = await axiosInstance.get<User>(
        "/users/accounts"
    );
    const data: any[] = response.data
    const userAccounts: UserAccount[] = plainToClass(UserAccount, data);
    return userAccounts;
  }

  async savePositionColumns(userPositionColumns: String[]): Promise<String[]>{
    const response: AxiosResponse = await axiosInstance.put<String[]>(
        `/users/preferences/${appGlobals.user?.id}/positionColumns`, userPositionColumns
    );
    const data: any = response.data;
    const userPreferences: UserPreferences = plainToClass(UserPreferences, data);
    appGlobals.user!.userPreferences = userPreferences;
    return userPreferences.positionColumns.split(",");
  }

  // async saveWatchlistColumns(userWatchlistColumns: String[]){
  //
  //   const userPreferences = appGlobals.user?.userPreferences; debugger
  //   // @ts-ignore
  //   userPreferences.watchlistColumns = userWatchlistColumns.toString();
  //   const putData = classToPlain(userPreferences);debugger
  //   const response: AxiosResponse = await axiosInstance.put(`/users/preferences/${userPreferences!.id}`, putData);
  //   const userPreferences1: UserPreferences = plainToClass(UserPreferences, response.data);
  //   appGlobals.user!.userPreferences = userPreferences1;
  //   //const asyncResponse = new AsyncResponse();
  //   try{
  //     const response: AxiosResponse = await axiosInstance.put(`/users/preferences/${userPreferences!.id}`, putData);
  //     const userPreferences1: UserPreferences = plainToClass(UserPreferences, response.data);
  //     appGlobals.user!.userPreferences = userPreferences1;
  //     asyncResponse.data = userPreferences1;
  //   }catch (errorResponse){
  //     // TODO handle errors properly
  //     asyncResponse.status = AsyncStatus.Error;
  //     asyncResponse.unknownErrors = [errorResponse];
  //   }
  //   return asyncResponse;
  //
  //   //
  //   // const response: AxiosResponse = await axiosInstance.put<String[]>(
  //   //     `/users/preferences/${appGlobals.user?.id}`, userWatchlistColumns
  //   // );
  //   // const data: any = response.data;
  //   // const userPreferences: UserPreferences = plainToClass(UserPreferences, data);
  //   // appGlobals.user!.userPreferences = userPreferences;
  //   // return userPreferences.watchlistColumns.split(",");
  // }

  async saveWatchlistColumns(userWatchlistColumns: String[]){

    const userPreferences = appGlobals.user?.userPreferences; debugger
    // @ts-ignore
    userPreferences.watchlistColumns = userWatchlistColumns.toString();
    const putData = classToPlain(userPreferences);debugger
    const response: AxiosResponse = await axiosInstance.put(`/users/preferences/${userPreferences!.id}`, putData);
    const userPreferences1: UserPreferences = plainToClass(UserPreferences, response.data);
    appGlobals.user!.userPreferences = userPreferences1;
  }

  /**
   * Load all the necessary data
   * @param user
   */
  async initializeData(user: User){
    console.log("Starting data initialization");
    await positionService.loadPositions();
    await accountService.loadAccounts();
    await orderService.loadOrders();

    appGlobals.positionsLoaded = true;
    appGlobals.accountsLoaded = true;
    appGlobals.ordersLoaded = true;
    // fire events to let components know that data has been loaded
    eventEmitter.emit("positionsLoaded", "");
    eventEmitter.emit("accountsLoaded", "");
    eventEmitter.emit("ordersLoaded", "");
    // don't await on setting up data refresh
    this.setupDataRefresh(user);
  }

  setupDataRefresh(user: User){
    console.log("Setting up data refresh");
    const brokerUsers = user.brokerUsers;
    brokerUsers?.forEach(brokerUser => {
      const broker = brokerUser.broker;
      if (broker){
        const brokerAccountService = BrokerFactory.getAccountService(broker);
        const brokerConfig = brokerAccountService.getConfig();
        const numberOfAccounts = brokerUser.userAccounts.length;
        const delay = Math.max(...[brokerConfig.minimumDelay, brokerConfig.delayPerAccount]);
        // we have to start several different timeouts to refresh positions, orders, accounts etc.,
        while (1 != 1){
          window.setTimeout(() => {
            console.log(`${new Date().toTimeString()} - refreshing positions for ${brokerUser.brokerUserId}`)
            positionService.refreshPositions(brokerUser);

            // refresh brokerAccounts
            const brokerAccounts = appGlobals.userBrokerDataMap[brokerUser.brokerUserId].accounts;
            accountService.refreshAccounts(brokerUser, brokerAccounts);
          }, delay);
        }
        const quotesDelay = brokerConfig.quotesDelay;
        let _this = this;
        window.setTimeout(function(){
          quoteService.refreshQuotablesRecursively(brokerUser, quotesDelay);
        }, quotesDelay, arguments);
      }
    })
  }

  getUserAccountsFromCache(){
    const allUserAccounts: UserAccount[] = [];
    let brokerUsers: BrokerUser[] = appGlobals.user!.brokerUsers;
    if(appGlobals.user!.brokerUsers) {
      brokerUsers.forEach(brokerUser => allUserAccounts.push(...brokerUser.userAccounts));
    }
    return allUserAccounts;
  }

  getUserAccountSelectionListFromCache(){
    const userAccounts = this.getUserAccountsFromCache();
    return userAccounts.map(userAccount => new VuetifySelection(userAccount.accountName!, userAccount));
  }

  async updateUserPreferences(){
    const userPreferences = appGlobals.user?.userPreferences;
    const putData = classToPlain(userPreferences);
    const asyncResponse = new AsyncResponse();
    try{
      const response: AxiosResponse = await axiosInstance.put(`/users/preferences/${userPreferences!.id}`, putData);
      const userPreferences1: UserPreferences = plainToClass(UserPreferences, response.data);
      appGlobals.user!.userPreferences = userPreferences1;
      asyncResponse.data = userPreferences1;
    }catch (errorResponse){
      // TODO handle errors properly
      asyncResponse.status = AsyncStatus.Error;
      asyncResponse.unknownErrors = [errorResponse];
      eventEmitter.emit("showError", ["There was some problem saving your preferences. Please try again later"]);
    }
    return asyncResponse;
  }


}

export const userService = new UserService();
