import {BrokerQuoteService} from "@/services/broker/BrokerQuoteService";
import {OptionChainExpiry, OptionContract, OptionPair, Quotable, SecurityQuote} from "@/data/QuoteData";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {genericQuotesAxiosInstance} from "@/util/AxiosUtil";
import _map from "lodash/map";
import _uniq from "lodash/uniq";
import _groupBy from "lodash/groupBy";
import {GenericQuote} from "@/data/generic/GenericQuoteData";
import {GenericBaseService} from "@/services/generic/GenericBaseService";
import {BrokerUser} from "@/data/UserData";
import {AsyncStatus, SecurityType} from "@/data/EnumData";
import DateUtil, {BNUtil, StringUtil} from "@/util/Util";
import BigNumber from "bignumber.js";
import {AsyncResponse} from "@/data/CommonData";
import {Logger} from "@/util/AppLogger";

export class GenericQuoteService extends GenericBaseService implements  BrokerQuoteService {

    logger = new Logger("GenericQuoteService");
    private getQuotesAxiosConfig(brokerUser: BrokerUser) : AxiosRequestConfig{
        return {
            headers:
                {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + brokerUser.accessToken,
                }
        }
    }

    async updateQuotes(brokerUser: BrokerUser, quotables: Quotable[]): Promise<void> {
        const params = new URLSearchParams();
        // remove duplicates and convert to command separated string
        const all_symbols : string[] = _map(quotables, quotable => quotable.symbol.toUpperCase());
        const all_underlyings: string [] = _map(quotables, quotable => quotable.underlying.toUpperCase());
        const symbolString = _uniq(Array.prototype.concat(all_underlyings, all_symbols)).join(",")

        params.append("symbols", symbolString);
        params.append("greeks", "true");
        const config = this.getQuotesAxiosConfig(brokerUser);
        config.headers["Content-Type"] = "application/x-www-form-urlencoded";
        const response: AxiosResponse = await genericQuotesAxiosInstance.post<any>("/markets/quotes", params, config);
        let genericQuotes: GenericQuote[] = response.data.quotes.quote as GenericQuote[];
        // if there is a single quote in the response, quote is not wrapped in an array, instead returned as an object
        // so wrap it in an array
        if (!Array.isArray(response.data.quotes.quote)){
            genericQuotes = [response.data.quotes.quote] as GenericQuote[];
        }

        const securityQuotesMap : {[key: string]: SecurityQuote} = {};
        for(let genericQuote of genericQuotes){
            const securityQuote = this.createSecurityQuote(genericQuote);
            securityQuotesMap[genericQuote.symbol] = securityQuote;
        }
        for(let quotable of quotables){
            quotable.rawQuote = securityQuotesMap[quotable.symbol.toUpperCase()];
            quotable.lastUpdated = new Date();
            if (quotable.securityType.isOption){
                quotable.underlyingQuote = securityQuotesMap[quotable.underlying.toUpperCase()];
            }
        }
    }

    createSecurityQuote(genericQuote: GenericQuote){
        const securityQuote = new SecurityQuote();
        securityQuote.bid = new BigNumber(genericQuote.bid);
        securityQuote.ask = new BigNumber(genericQuote.ask);
        securityQuote.symbol = genericQuote.symbol;
        securityQuote.brokerSymbol = genericQuote.symbol;
        securityQuote.underlying = genericQuote.underlying;
        securityQuote.rootSymbol = genericQuote.root_symbol;
        securityQuote.price = BNUtil.avg(genericQuote.bid, genericQuote.ask);
        securityQuote.last = BNUtil.of(genericQuote.last);
        securityQuote.open = BNUtil.of(genericQuote.open);
        securityQuote.high = BNUtil.of(genericQuote.high);
        securityQuote.low = BNUtil.of(genericQuote.low);
        securityQuote.volume = Number(genericQuote.volume);
        securityQuote.lastVolume = Number(genericQuote.last_volume);
        securityQuote.averageVolume = Number(genericQuote.average_volume);
        securityQuote.exch = genericQuote.exch;
        securityQuote.bidExch = genericQuote.bidexch;
        securityQuote.askExch = genericQuote.askexch;
        securityQuote.change = new BigNumber(genericQuote.change);
        securityQuote.changePercentage = new BigNumber(genericQuote.change_percentage);
        securityQuote.bidSize = Number(genericQuote.bidsize);
        securityQuote.askSize = Number(genericQuote.asksize);
        securityQuote.description = genericQuote.description;

        if (genericQuote.type !== "option"){
            securityQuote.securityType = SecurityType.Stock;
            securityQuote.delta = BNUtil.of("1");   // delta is one for long stock and -1 for short stock
            securityQuote.theta = BNUtil.ZERO;

        }else{
            securityQuote.securityType = SecurityType.getEnum(StringUtil.capitalizeFirst(genericQuote.option_type));
            securityQuote.expiryDate = DateUtil.toDate(genericQuote.expiration_date);
            securityQuote.strike = new BigNumber(genericQuote.strike);
            securityQuote.openInterest = Number(genericQuote.open_interest);

            if (genericQuote.greeks){
                const greeks = genericQuote.greeks;
                securityQuote.delta = BNUtil.of(greeks.delta);
                securityQuote.theta = BNUtil.of3(greeks.theta);
                securityQuote.gamma = BNUtil.of3(greeks.gamma);
                securityQuote.vega = BNUtil.of3(greeks.vega);
                securityQuote.optionImpliedVolatility = BNUtil.of(greeks.mid_iv);
            }
        }

        return securityQuote;
    }

    async getOptionExpiries(brokerUser: BrokerUser, symbol: string) : Promise<AsyncResponse<Map<string, OptionChainExpiry[]>>>{
        const config = this.getQuotesAxiosConfig(brokerUser);
        const params = new URLSearchParams();
        params.append("underlying", symbol);
        config.params = params;
        const asyncResponse: AsyncResponse = new AsyncResponse<Map<string, OptionChainExpiry[]>>();
        try {
            const response: AxiosResponse = await genericQuotesAxiosInstance.get<any>("/markets/options/lookup", config);
            let symbolOptionExpiriesMap = new Map<string, OptionChainExpiry[]>();
            if ("symbols" in response.data && response.data.symbols != "null"){
                let rootSymbolObjs = response.data.symbols as {rootSymbol: string, options: string[]}[];
                for (let rootSymbolObj of rootSymbolObjs){
                    const rootSymbol = rootSymbolObj.rootSymbol;
                    const optionExpiries: OptionChainExpiry[] = [];
                    symbolOptionExpiriesMap.set(rootSymbol, optionExpiries);
                    // the rootSymbolObj has options for all expiration dates
                    // so create a map of expiration date and strikes
                    let groupedSymbolsByExpiration =  _groupBy(rootSymbolObj.options, optionSymbol => optionSymbol.substr(rootSymbol.length, 6));
                    for (let expirationStr of Object.keys(groupedSymbolsByExpiration)){
                        const optionChainExpiry = new OptionChainExpiry();
                        optionChainExpiry.expiryDate = DateUtil.toDateFromOsiFormat(expirationStr);
                        optionChainExpiry.symbol = symbol;
                        optionChainExpiry.rootSymbol = rootSymbol;
                        // update key after setting expiryDate and rootSymbol
                        optionChainExpiry.updateKey();
                        // we have symbols for both puts and calls, but we only need strikes, so extract the strike part and place it in a set to remove duplciates
                        const strikesSet = new Set(groupedSymbolsByExpiration[expirationStr].map(optionSymbol => optionSymbol.substr(-8)));
                        const strikes = [...strikesSet].map(strike => new BigNumber(parseFloat(strike)/1000));
                        optionChainExpiry.strikes = strikes;
                        optionExpiries.push(optionChainExpiry);
                    }
                }
                asyncResponse.data = symbolOptionExpiriesMap;
            }else{
                asyncResponse.status = AsyncStatus.Error;
                asyncResponse.businessErrors = ["No options found for symbol - "+symbol];
            }
        }catch (errorResponse) {
            asyncResponse.status = AsyncStatus.Error;
            this.captureUnknownErrors(errorResponse, asyncResponse);
        }


        return asyncResponse;
    }

    async getOptionExpiriesOld(brokerUser: BrokerUser, symbol: string) : Promise<OptionChainExpiry[]>{
        const config = this.getQuotesAxiosConfig(brokerUser);
        const params = new URLSearchParams();
        params.append("symbol", symbol);
        params.append("includeAllRoots", "true");
        config.params = params;
        const response: AxiosResponse = await genericQuotesAxiosInstance.get<any>("/markets/options/expirations", config);
        const expirations: string[] = response.data.expirations.date as string[]
        const optionChainExpiries = expirations.map(expiration => {
            let optionChainExpiry = new OptionChainExpiry();
            optionChainExpiry.symbol = symbol;
            optionChainExpiry.rootSymbol = symbol; // TODO
            optionChainExpiry.expiryDate = DateUtil.toDate(expiration);
            // update key after setting expiryDate and rootSymbol
            optionChainExpiry.updateKey();

            return optionChainExpiry;
        });
        return optionChainExpiries;
    }

    async updateOptionPairs(brokerUser: BrokerUser, optionChainExpiry: OptionChainExpiry): Promise<void>{
        const config = this.getQuotesAxiosConfig(brokerUser);
        const params = new URLSearchParams();
        params.append("symbol", optionChainExpiry.symbol);
        params.append("expiration", DateUtil.toDateStr(optionChainExpiry.expiryDate));
        params.append("greeks", "true");
        config.params = params;
        const response: AxiosResponse = await genericQuotesAxiosInstance.get<any>("/markets/options/chains", config);
        let genericQuotes = response.data.options.option as GenericQuote[];
        // the broker returns quotes for multiple option rootSymbols in a single array,
        // So, even if we want quotes for Jan'21 SPXW options, we have to request for quotes for Jan'21 SPX options
        // the result will contain quotes for both SPX and SPXW
        if (optionChainExpiry.rootSymbolDifferent){
            // if the rootSymbol is different, then filter the securityQuotes based on rootSymbol
            genericQuotes = genericQuotes.filter(quote => quote.root_symbol == optionChainExpiry.rootSymbol);
        }

        // Have to create OptionPairs from quotes
        const securityQuotes: SecurityQuote[] = genericQuotes.map(genericQuote => this.createSecurityQuote(genericQuote));

        const optionPairs: OptionPair[] = [];
        const quotesByStrikes = _groupBy(securityQuotes, item => BNUtil.formatBN(item.strike));
        for (let strikeStr of Object.keys(quotesByStrikes)){
            const optionPair:OptionPair = new OptionPair(optionChainExpiry);
            let optionContractQuotes = quotesByStrikes[strikeStr];
            for (let optionContractQuote of optionContractQuotes){
                if (!optionContractQuote.symbol){
                    console.log("No symbol on quote");
                }
                const optionContract: OptionContract = new OptionContract(optionChainExpiry.symbol, optionContractQuote.securityType!,
                    optionContractQuote.symbol, optionContractQuote.rootSymbol, optionContractQuote.brokerSymbol,optionContractQuote.expiryDate, optionContractQuote.strike);
                optionContract.optionPair = optionPair;
                optionContract.rawQuote = optionContractQuote;
                if (optionContractQuote.securityType!.isPut){
                    optionPair.put = optionContract;
                }else{
                    optionPair.call = optionContract;
                }
                optionPair.strike = optionContractQuote.strike;
            }
            optionPairs.push(optionPair);
        }
        optionChainExpiry.optionPairs.splice(0, optionChainExpiry.optionPairs.length, ...optionPairs);
    }


}

export const genericQuoteService = new GenericQuoteService();