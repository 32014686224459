import {differenceInDays, format, parse} from 'date-fns'
import BigNumber from "bignumber.js";
import {Quotable} from "@/data/QuoteData";
import {AsyncStatus, SecurityType} from "@/data/EnumData";
import {Constants} from "@/data/common/Constants";
import {BigNumber3} from "@/main";
import {AsyncResponse} from "@/data/CommonData";

export default class DateUtil {

    // builds date from 'yyyy-MM-dd' string
    static toDate(dateStr: string) : Date {
        return parse(dateStr, Constants.DefaultDateFormat, new Date());
    }

    // converts to default format yyyy-MM-dd
    static toDateStr(date?: Date): string{
        return date ? format(date, Constants.DefaultDateFormat) : "";
    }

    // converts to default format yyyy-MM-dd
    static toShortDateTimeStr(date?: Date): string{
        return date ? format(date, Constants.ShortDateTimeFormat) : "";
    }

    // converts from yyyy-MM-dd to yyMMdd
    static convertToOsiFormat(dateInput: string){
        return format(this.toDate(dateInput), Constants.OSIDateFormat);
    }

    // converts from yyMMdd to yyyy-MM-dd
    static convertFromOsiFormat(dateInput: string): string{
        return this.toDateStr(parse(dateInput, Constants.OSIDateFormat, new Date()));
    }

    // input format is yyMMdd
    static toDateFromOsiFormat(dateInput: string): Date{
        return parse(dateInput, Constants.OSIDateFormat, new Date());
    }

    static toOsiFormatFromDate(dateInput: Date): string{
        return format(dateInput, Constants.OSIDateFormat);
    }

    static toExpiryViewStr(date?: Date | string): string{
        if (!date){
            return "";
        }
        if (typeof date == "string"){
            date = this.toDate(date);
        }
        return format(date, "MMM dd, yyyy");
    }

    static toExpiryViewStrNoYear(date?: Date | string): string{
        if (!date){
            return "";
        }
        if (typeof date == "string"){
            date = this.toDate(date);
        }
        return date ? format(date, "MMM dd") : "";
    }

    static daysUntil(date: Date | string): number {
        if (date instanceof String || typeof(date) == "string"){
            date = this.toDate(<string>date);
        }
        return differenceInDays(<Date>date, new Date());
    }


}
/*
export class BigNum extends BigNumber{
    // The defaults set using BigNumber.set() (in main.ts) are only applicable for arithmetic operations, they are not applicable for formatting
    // the toFormat() function requires a decimal places, otherwise it uses whatever decimals are in the underlying number. So, if you do new BigNumber("5").toFormat(), it would return "5".
    // We want to print out two decimal places all the time
    format(){
        return this.toFormat(2);
    }

    // call toFormat() from toString() is throwing a max call stack exception because toFormat internally calls toString
    toString(base?: number): string {
        return super.toString(base);
    }
}*/

export class SecurityUtil{

    static rootSymbolToUnderlyingMap:  { [key: string]: string } = {
        "SPXW": "SPX",
        "RUTW": "RUT",
        "NDXP": "NDX",
        "VIXW": "VIX"
    };

    // returns the osi symbol for an option contract
    static getOsiSymbol(quotable: Quotable): string{
        if (quotable.securityType.isOption){
            // we have to use the rootSymbol to construct osiSymbol
            const rootSymbol: string = quotable.rootSymbol.toUpperCase();
            const expiryDate: string = DateUtil.toOsiFormatFromDate(quotable.expiryDate);
            const optionType = quotable.securityType.shortLabel.toUpperCase();
            // the strike has to be - 3 digits after decimal and left padded with zeroes to make a total length of 8
            const strike = BNUtil.formatBN3(quotable.strikePrice, false).split(".").join("").padStart(8, "0");
            return rootSymbol+expiryDate+optionType+strike;
        }else{
            return quotable.underlying;
        }
    }

    // builds a quotable by splitting the osiSymbol
    static getQuotable(osiSymbol: string, brokerSymbol: string): Quotable{
        const strikePrice = new BigNumber(parseFloat(osiSymbol.substr(-8))/1000);
        const securityType = SecurityType.getEnumByShorKey(osiSymbol.substr(-9, 1));
        const datePart = osiSymbol.substr(-15,6);
        const expiryDate = DateUtil.toDateFromOsiFormat(datePart);
        const rootSymbol = osiSymbol.substring(0, osiSymbol.indexOf(datePart)).toUpperCase();
        const underlying = this.rootSymbolToUnderlyingMap[rootSymbol] ? this.rootSymbolToUnderlyingMap[rootSymbol] : rootSymbol;
        const quotable = Quotable.getOptionQuotable(underlying, osiSymbol,rootSymbol, brokerSymbol, securityType, strikePrice, expiryDate)
        return quotable;
    }
}

export class NumberUtil{
    static gcd(a : number, b: number): number{
        if (a == 0)
            return b;
        return this.gcd(b % a, a);
    }
    static gcdArray(...numbers: number[]){
        let result = numbers[0];
        for (let i = 1; i < numbers.length; i++)
        {
            result = this.gcd(numbers[i], result);
            if(result == 1)
            {
                return 1;
            }
        }
        return result;
    }
    static toShortString(value:number){
        let valueStr = "";
        if( value > (1000000-1)) {
            let val = value/1000000;
            valueStr = val.toFixed(2).toString()+"M";
        } else if( value > 1000-1) {
            let val = value/1000;
            valueStr = val.toFixed(2).toString()+"K";
        }else{
            valueStr = String(value);
        }
        return valueStr;
    }

    static getNearest(array: number[], target: number) : number{
        return array.reduce((previous, current) => Math.abs(current - target) < Math.abs(previous -target) ? current : previous);
    }
}

export class StringUtil{
    static capitalizeFirst(str: string)
    {
        return str && str[0].toUpperCase() + str.slice(1);
    }
}


export class BNUtil {
    /*
    static toBN(value: any) : BigNum | string {
        if (value){
            return new BigNum(value);
        }else{
            return "";
        }
    }*/

    static of(value: string | undefined | number): BigNumber{
        if (value){
            return new BigNumber(value);
        }
        return this.ZERO;
    }

    static of3(value: string | undefined | number): BigNumber{
        if (value){
            return new BigNumber3(value);
        }
        return new BigNumber3("0");
    }

    static get ZERO(): BigNumber {
        return new BigNumber(0);
    }

    static get HUNDRED(): BigNumber {
        return new BigNumber(100);
    }

    static format(value: string | undefined) : string{
        if (!value){
            return "";
        }
        return new BigNumber(value).toFormat(2);
    }

    static formatBN(bn: BigNumber, withSeparator: boolean = true) : string{
        if (withSeparator){
            return bn ? bn.toFormat(2) : "";
        }else{
            // set the decimal places, but don't insert the group separator
            return bn ? bn.toFormat(2,{decimalSeparator: ".", groupSeparator: ""}) : "";
        }

    }

    static formatBN3(bn: BigNumber, withSeparator: boolean = true) : string{
        if (withSeparator){
            return bn ? bn.toFormat(3) : "";
        }else{
            // set the decimal places, but don't insert the group separator
            return bn ? bn.toFormat(3,{decimalSeparator: ".", groupSeparator: ""}) : "";
        }
    }

    // Utility methods to reduce verbiage

    static mul(num1: string | number, num2: string | number): BigNumber{
        return new BigNumber(num1).multipliedBy(new BigNumber(num2));
    }

    static avg(num1: string | number, num2: string | number): BigNumber{
        return new BigNumber(num1).plus(new BigNumber(num2)).dividedBy(new BigNumber(2));
    }

    static div(num1: string | number, num2: string | number): BigNumber{
        return new BigNumber(num1).dividedBy(new BigNumber(num2));
    }

    static minus(num1: string | number | BigNumber, num2: string | number | BigNumber): BigNumber{
        return new BigNumber(num1).minus(new BigNumber(num2));
    }

    static plus(num1: string | number, num2: string | number): BigNumber{
        return new BigNumber(num1).plus(new BigNumber(num2));
    }

    static getPercentChange(initial: string | number | BigNumber, final: string | number | BigNumber) : BigNumber{
        return this.minus(final, initial).div(new BigNumber(initial)).multipliedBy(100);
    }

    static negate(num1: string | number) : BigNumber{
        return new BigNumber(num1).negated();
    }

    static getNearest(array: BigNumber[], target: BigNumber) : BigNumber{
        return array.reduce((previous, current) => current.minus(target).abs().lt(previous.minus(target).abs()) ? current : previous);
    }


}

export class PromiseUtil{

    /**
     * Combines multiple Promises, where each promise returns an array of object T (AsyncResponse<T[]>)
     * @param promiseResults
     */
    static combinePromiseArrayResults<T>(promiseResults: PromiseSettledResult<AsyncResponse<T[]>>[]) : AsyncResponse<T[]> {
        const combinedResponse: AsyncResponse<T[]> = new AsyncResponse();
        combinedResponse.data = [];
        const  promiseStatuses: AsyncStatus[] = [];
        let okCount = 0;
        promiseResults.forEach(result => {
            // we get only "fulfilled" statuses because we handle all exceptions and capture errors in AsyncResponse
            if (result.status == "fulfilled"){
                const individualResponse = result.value;
                promiseStatuses.push(individualResponse.status);
                if (individualResponse.status == AsyncStatus.Ok){
                    okCount++;
                    combinedResponse.data!.push(...individualResponse.data!);
                }else{
                    combinedResponse.unknownErrors.push(...individualResponse.unknownErrors);
                    combinedResponse.businessErrors.push(...individualResponse.businessErrors);
                }
            }
        });
        if (okCount == 0){
            combinedResponse.status = AsyncStatus.Error;
        }else{
            okCount == promiseResults.length ?
                combinedResponse.status = AsyncStatus.Ok : combinedResponse.status = AsyncStatus.Partial;
        }
        return combinedResponse;
    }
}

