import { onMounted, reactive, ref, SetupContext } from "@vue/composition-api";
import {PositionColumn, positionColumnsConfig} from "@/data/PositionsData";
import { UserPreferences, SettingsColumnConfig} from "@/data/UserData";
import { appGlobals} from "@/data/AppData";
import { userService } from "@/services/UserService";
import _difference from "lodash/difference";
import {DataTableHeader} from "vuetify";
import {eventEmitter} from "@/main";

export function usePositionColumns(context: SetupContext) {
    
    // used to track changes in column preferences
    let positionColumnsReactive = reactive(getPositionColumns()); // for data
    let positionHeaders = reactive(buildHeaders(positionColumnsReactive)); // for headers
    
    // used in position settings dialog
    let showColumnSettings = ref<boolean>(false);
    let selectedColumns: SettingsColumnConfig[] = reactive([]);
    let availableColumns: SettingsColumnConfig[] = reactive([]);

    /**
     * This method is invoked to open Column Settings Dialog
     */
    function startColumnSettings() {
        showColumnSettings.value = true;
        selectedColumns.splice(0, selectedColumns.length); // use splice to keep the reference when clearing an array
        let preferences = appGlobals.user?.userPreferences as UserPreferences;
        let userCols = preferences.positionColumns.split(",")
        userCols.forEach(colKey => {
            selectedColumns.push(positionColumnsConfig.allColumnsDict[colKey]);
        })
        availableColumns.splice(0, availableColumns.length, ..._difference(positionColumnsConfig.allColumnsList, selectedColumns));
    }

    /**
     * This method is invoked when Column Settings Dialog is closed
     */
    function saveColumnSettings(value: boolean, finalCols: String[]) {
        showColumnSettings.value = value;
        userService.savePositionColumns(finalCols)
            .then(savedColumns => {
                positionColumnsReactive.splice(0, positionColumnsReactive.length, ...getPositionColumns());
                showColumnSettings.value = false;
                console.log("Pos reactive after:" + positionColumnsReactive.length);
                positionHeaders.splice(0, positionHeaders.length, ...buildHeaders(positionColumnsReactive));
            }).catch(reason => eventEmitter.emit("showError", ["Error in saving Position Columns"]))
    }

    /**
     * Retrieves position columns from user's profile
     * @returns Array<PositionColumn>[]
     */
    function getPositionColumns(){
        let displayPositionColumns : Array<PositionColumn> = [];
        let preferences: UserPreferences = appGlobals.user?.userPreferences as UserPreferences;
        preferences.positionColumns.split(",").forEach(positionColumnKey => {
          if (!positionColumnsConfig.allColumnsDict[positionColumnKey]){
            console.log("Missing key - "+positionColumnKey);
          }
          displayPositionColumns.push(positionColumnsConfig.allColumnsDict[positionColumnKey])
        })
        return displayPositionColumns;
    }
    
    /**
     * builds headers for the data table based on positions columns in user's profile 
     */
    function buildHeaders(positionColumns: PositionColumn[]) : DataTableHeader[]{
        // these are the default headers
        let positionHeaders : DataTableHeader[] = [
          {text: "expandIcon", value: "expanded", sortable: false, width: "1%"},
          //{text: "", value: "", sortable: false},
          {text: "Symbol", value: "symbol", sortable: true, align: "end", width: "8%" },
          {text: "Last", value: "stockPrice", sortable: false, align:"end", width: "15%"},
        ];
        // push dynamic headers
        for (let positionColumn of positionColumns) {
          positionHeaders.push({
            text: positionColumn.headerName,
            value: positionColumn.property,
            sortable: true,
            align:"end"
          })
        }
        return positionHeaders;
    }

    return {
        startColumnSettings,
        saveColumnSettings,
        positionColumnsReactive,
        positionHeaders,
        showColumnSettings,
        availableColumns,
        selectedColumns
    }
}