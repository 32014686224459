




































































import {defineComponent, onMounted, reactive, ref, SetupContext} from "@vue/composition-api";
import {userService} from "@/services/UserService";
import {UserAccount} from "@/data/UserData";
import {Broker} from "@/data/EnumData";

export default defineComponent({
  name: "UserAccounts",
  setup(props: undefined, context: SetupContext) {

    let dialog = ref(false);
    let userAccount = reactive<UserAccount>(new UserAccount());
    let userAccounts: UserAccount[] = reactive([]);
    let accountHeaders: any[] = reactive([{
      text: "Name", value: "accountName"},
      {text: "Broker", value: "broker"},
      {text: "Risk Type", value:"riskType"},
      {text: "Primary", value: "primary"}
    ])
    let brokerItems: any[] = reactive(buildBrokerItems());
    onMounted(() => {
      loadAccounts(userAccounts);
    })

    let openNewAccountDialog = function(){
      dialog.value = true;
    }

    return {
      brokerItems,
      userAccount,
      userAccounts,
      accountHeaders,
      dialog,

      openNewAccountDialog,
      createNewAccount
    }
  }

});

function createNewAccount(newAccount: UserAccount){
  console.log(newAccount);
}

function loadAccounts(accountsReactive: UserAccount[]){
  userService.getUserAccounts().then(accounts => {
    accounts.forEach(account => accountsReactive.push(account));
  })
}

function buildBrokerItems() : any[]{
  let items: { text: string, value: string} [] = [];
  Object.keys(Broker).forEach(key => {
    items.push({
      text: Broker[key as Broker],
      value: key,
    })
  });
  return items;
}

