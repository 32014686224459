








import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  SetupContext
} from "@vue/composition-api";
import HomeTopBar from "./TopBar.vue";
import HomeFooter from "./Footer.vue";
import HomeView from "./View.vue";
import { appGlobals } from "@/data/AppData";
import { authService } from "@/services/AuthService";
import { User } from "@/data/UserData";
import { userService } from "@/services/UserService";

export default defineComponent({
  name: "Home",
  components: {
    HomeTopBar,
    HomeFooter,
    HomeView
  },
  setup(props: undefined, context: SetupContext) {
    context.root.$vuetify.theme.dark = true;
    onBeforeUnmount(function() {
      context.root.$vuetify.theme.dark = false;
    });

    onMounted(() => {
      revalidateUserSession(context);
    });
  }
});

// we are
async function revalidateUserSession(context: SetupContext) {
  if (appGlobals.user == null) {
    const isValid: boolean = await authService.refreshSession();
    if (isValid) {
      const user: User = await userService.getUserDetails();
      appGlobals.user = user;
      context.root.$router.push("/main");
    } else {
      context.root.$router.push("/");
    }
  }
}
