


























































































































import {defineComponent, onMounted, reactive, ref, SetupContext, watch} from "@vue/composition-api";
import {tradeService} from "@/services/TradeService";
import {OrderType, PriceEffect} from "@/data/EnumData";

export default defineComponent({
  name: "TradeTicketPriceSection",
  setup(props: undefined, context: SetupContext) {

    const tradeData = tradeService.tradeData;

    function previewOrder(){
      tradeService.previewOrder();
    }

    function changeUserAccount(){
      tradeService.changeUserAccount();
    }

    function priceStartChanged(){
      tradeService.priceStartChanged();
    }

    function priceChanged(){
      tradeService.priceChanged();
    }

    function stopPriceChanged(){
      tradeService.stopPriceChanged();
    }

    function orderTypeChanged(){
      tradeService.orderTypeChanged();
    }

    function adjustmentChanged(){
      const rollingLimitParams = tradeData.order.rollingLimitParams;
      if(!rollingLimitParams.adjustment1Str){
        rollingLimitParams.adjustment1Str = rollingLimitParams.adjustment1.toString();
      }else{
        // adjustment value has to be in between 1 and 100 cents
        let adjustmentValue = parseInt(rollingLimitParams.adjustment1Str);
        adjustmentValue = adjustmentValue > 100 ? 100 : adjustmentValue < 1 ? 1 : adjustmentValue;
        rollingLimitParams.adjustment1 = adjustmentValue;
      }
    }

    function timeIncrementChanged(){
      const rollingLimitParams = tradeData.order.rollingLimitParams;
      if(!rollingLimitParams.timeIncrement1Str){
        rollingLimitParams.timeIncrement1Str = rollingLimitParams.timeIncrement1.toString();
      }else{
        // Time incr value has to be in between 3 and 30 seconds
        let timeIncrementValue = parseInt(rollingLimitParams.timeIncrement1Str);
        timeIncrementValue = timeIncrementValue > 30 ? 30 : timeIncrementValue < 3 ? 3 : timeIncrementValue;
        rollingLimitParams.timeIncrement1 = timeIncrementValue;
      }


    }


    return {
      tradeData,
      OrderType,
      PriceEffect,

      previewOrder,
      changeUserAccount,
      priceChanged,
      stopPriceChanged,
      adjustmentChanged,
      timeIncrementChanged,
      orderTypeChanged,
      priceStartChanged
    }
  }
});

