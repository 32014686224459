












import { defineComponent, reactive, SetupContext } from "@vue/composition-api";

export default defineComponent({
  name: "HomeFooter",
  setup(props: undefined, context: SetupContext) {
    const links: Array<{ href: string; text: string }> = reactive([
      {
        href: "#",
        text: "Creative Tim"
      },
      {
        href: "#",
        text: "About Us"
      },
      {
        href: "#",
        text: "Blog"
      },
      {
        href: "#",
        text: "Licenses"
      }
    ]);
    return {
      links
    };
  }
});

/*
export default {
  name: 'HomeFooter',

  data: () => ({
    links: [
      {
        href: '#',
        text: 'Creative Tim',
      },
      {
        href: '#',
        text: 'About Us',
      },
      {
        href: '#',
        text: 'Blog',
      },
      {
        href: '#',
        text: 'Licenses',
      },
    ],
  }),
}*/
