






























































import {defineComponent, reactive, ref, Ref, SetupContext} from "@vue/composition-api";
import { appGlobals, ObjectLiteral } from "@/data/AppData";
import { AmplifyEventBus } from "aws-amplify-vue";
import { Auth } from "aws-amplify";
import {eventEmitter} from "@/main";

export default defineComponent({
  name: "MainNavigationBar",
  setup(props: undefined, context: SetupContext) {

    function signout(){
      Auth.signOut().then(function(){
        context.root.$router.push("/");
        AmplifyEventBus.$emit('authState', 'signedOut');
      })
    }

    function toggleWatchlists(){
      // this event is listened in Main.vue
      eventEmitter.emit("toggleWatchlists", "");
    }

    return {
      signout,
      toggleWatchlists
    }
  }
})

