




































import {defineComponent, onMounted, reactive, ref, SetupContext, watch} from "@vue/composition-api";
import {tradeTemplateService} from "@/services/TradeTemplateService";
import {SharedTradeTemplateData} from "@/data/TradeData";
import {tradeService} from "@/services/TradeService";

export default defineComponent({
  name: "TradeTemplateMenu",
  setup(props: undefined, context: SetupContext) {

    const templateData = tradeTemplateService.tradeTemplateData as SharedTradeTemplateData;
    const tradeData = tradeService.tradeData;
    // just increment this value to force list-item-group and list-items to recognize the change in model value
    const itemGroup = ref<number>(0);
    // we have set the 'close-on-content-click' to false on menu, so we have to control when to show and hide the menu
    const showMenu = ref<boolean>(false);

    async function selectTemplate(){
      console.log("selected template id - "+templateData.selectedTemplate!.id);
      await tradeService.applyTradeTemplate(templateData.selectedTemplate!);
      templateData.selectedTemplate = undefined;
      itemGroup.value = itemGroup.value + 1;
      showMenu.value = false;
    }

    function filterTemplates(){
      console.log("search text is - "+templateData.searchText);
      // first mark them all to show
      templateData.templates.forEach(template => template.show = true);
      // apply filter
      if (templateData.searchText){
        templateData.templates.forEach(template => template.name.indexOf(templateData.searchText) >= 0 ? template.show = true : template.show = false);
      }
    }

    onMounted(() => {
      tradeTemplateService.loadTemplates();
    });

    return{
      showMenu,
      itemGroup,
      tradeData,
      templateData,
      selectTemplate,
      filterTemplates
    }

  }
});


