import {defineComponent, onMounted, reactive, Ref, ref, SetupContext} from "@vue/composition-api";
import {PositionItemType, AccountIconIndexMapping, TableColumnType, TableColumnDatatype, PositionGrouping} from "@/data/EnumData";
import {positionColumnsConfig, PositionItem} from "@/data/PositionsData";
import _filter from "lodash/filter";
import _reverse from "lodash/reverse";
import Vuetify from "vuetify/lib";

export default function usePositionsSortBy(groupedBy: Ref<string>) {
  
  // This function is called by Vuetify data table to perform custom sorting
  function customSort(items: any[], sortBys: string[], sortDescs: boolean[], locale: string ){
    let sortBy: string = sortBys[0];
    let sortDesc: boolean = sortDescs[0];
    // console.log("in sort:");
    // console.log("sortBy: "+sortBy+" sortDesc: "+sortDesc);
    if (sortDesc == undefined){ //sortDesc has 3 states: true, false and undefined
    return items;
    }
    // items are a mix of parents and children. 
    if (groupedBy.value == PositionGrouping.Symbol) { // sort SymbolPositions/parent rows, then add children at the right place 
      let symbolPositions: PositionItem[] = _filter(items, item => item.type==PositionItemType.SymbolPosition);
      symbolPositions = sortPositions(symbolPositions, sortBy, sortDesc, locale);
      let sortedResult:PositionItem[] = [];
      symbolPositions.forEach(element => {
          sortedResult.push(element);
          sortedResult.push(...element.children);
      });
      items=sortedResult;
    } else if (groupedBy.value == PositionGrouping.None) { // sort all, parents are no dispalyed anyway
        items = sortPositions(items, sortBy, sortDesc, locale)
    } 
    return items;
  }
  
  function sortPositions(items: any[], sortBy: string, sortDesc: boolean, locale: string) {
    let positionColumn = positionColumnsConfig.allColumnsDictByProperty[sortBy]; // will be undefined if sortBy is symbol. symbol is not a dynamic column
    if(sortBy=="symbol" || positionColumnsConfig.allColumnsDictByProperty[sortBy].datatype == TableColumnDatatype.String){
        items.sort((a, b) => { 
            if (a[sortBy] > b[sortBy]) {
                return 1;
            }
            if (a[sortBy] < b[sortBy]) {
                return -1;
            }
            return 0;
            }
        );
    } else if(positionColumnsConfig.allColumnsDictByProperty[sortBy].datatype == TableColumnDatatype.BigNumber ||
        positionColumnsConfig.allColumnsDictByProperty[sortBy].datatype == TableColumnDatatype.Number) {
        items.sort((firstItem, secondItem) => firstItem[sortBy] - secondItem[sortBy]);
    } else if(positionColumnsConfig.allColumnsDictByProperty[sortBy].datatype == TableColumnDatatype.Date) {
        items.sort((firstItem, secondItem) => firstItem[sortBy] - secondItem[sortBy]);
    }
    if(sortDesc) { //default sort order is asc, reverse for desc
        _reverse(items);
    } 
    return items;
 }

  return {
    customSort
  }
}