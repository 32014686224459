







import { defineComponent, Ref, ref, SetupContext } from "@vue/composition-api";

interface Props {
  value: string
}

export default defineComponent({
  name: "FlashText",
  props: {value: String},
  setup(props: Props, context: SetupContext) {

    return {
    };
  }
});
