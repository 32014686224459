import {appGlobals} from "@/data/AppData";
import {BrokerUser} from "@/data/UserData";
import {BrokerFactory} from "@/services/broker/BrokerFactory";
import _flatMap from "lodash/flatMap";
import {Order} from "@/data/OrdersData";
import {Quotable} from "@/data/QuoteData";
import {quoteService} from "@/services/QuoteService";
import {eventEmitter} from "@/main";
import {AsyncStatus, OrderClass, OrderStatus} from "@/data/EnumData";
import {AsyncResponse} from "@/data/CommonData";
import {PromiseUtil} from "@/util/Util";
import {BaseService} from "@/services/CommonService";
import _sortBy from "lodash/sortBy";


export default class OrderService extends BaseService {

    getCachedOrders(): Order[]{
        return _flatMap(Object.values(appGlobals.userBrokerDataMap), brokerDataMap => brokerDataMap.orders)
    }

    /**
     * Loads orders for Orders screen
     */
    async loadOrders(): Promise<Order[]>{
        const allPromises: Promise<AsyncResponse<Order[]>>[] = [];
        // retrieve all user accounts
        const brokerUsers = appGlobals.user!.brokerUsers;
        for(let brokerUser of brokerUsers){
            // let each brokerUser get their positions in parallel, so don't await
            allPromises.push(this.refreshOrders(brokerUser));
        }
        // const orders: Order[][] = await Promise.all(allPromises);
        const promiseResults: PromiseSettledResult<AsyncResponse<Order[]>>[]  =  await Promise.allSettled(allPromises);
        const combinedResponse: AsyncResponse<Order[]> = PromiseUtil.combinePromiseArrayResults<Order>(promiseResults);
        return Array.prototype.concat(...combinedResponse.data!);
    }

    async refreshOrders(brokerUser: BrokerUser) : Promise<AsyncResponse<Order[]>>{
        const brokerOrderService = BrokerFactory.getOrderService(brokerUser.broker!);
        let _this = this;
        // we should also get quotes
        const asyncResponse = await brokerOrderService.getOrders(brokerUser);
        if (asyncResponse.status != AsyncStatus.Ok){
            eventEmitter.emit("showError", ["Error in loading all orders for user profile - "+brokerUser.brokerUserProfileName]);
        }
        if (asyncResponse.status == AsyncStatus.Error){
            return asyncResponse;
        }
        let orders: Order[] = asyncResponse.data!;
        // sort order legs
        this.sortOrderLegs(orders);

        let userBrokerData = appGlobals.userBrokerDataMap[brokerUser.brokerUserId];
        let cachedOrders = userBrokerData.orders;
        cachedOrders.splice(0, cachedOrders.length, ...orders);
        if (orders.length > 0){
            // build quotables
            this.updateOrderQuotables(brokerUser, orders);
            // refresh quotables with latest quotes
            await quoteService.refreshQuotables(brokerUser);
        }
        return asyncResponse;
    }

    private sortOrderLegs(orders: Order[]){
        for(let order of orders){
            if(order.class == OrderClass.MultiLeg){
                // first sort by expiryDate asc and then by strikePrice asc
                order.legs.splice(0, order.legs.length, ..._sortBy(order.legs, leg => leg.expiryDate, leg => leg.strikePrice));
            }
        }
    }

    updateOrderQuotables(brokerUser: BrokerUser, orders: Order[]){
        // build quotables and add to global cache
        const quotables: Quotable[] = [];
        for(let order of orders){
            for (let orderLeg of order.legs){
                // when orderLegs come from broker, they have Quotable (not OptionContract)
                quotables.push(orderLeg.quotable as Quotable);
            }
        }
        const orderQuotables = appGlobals.userBrokerDataMap[brokerUser.brokerUserId].quotableGroups.orders;
        orderQuotables.splice(0, orderQuotables.length, ...quotables);
    }

    async cancelOrder(order:Order){
        const brokerUser = appGlobals.user?.getBrokerUser(order.userAccount?.brokerUserId!);
        const brokerOrderService = BrokerFactory.getOrderService(brokerUser!.broker!);
        const asyncResponse = await brokerOrderService.cancelOrder(brokerUser!, order);
        if (asyncResponse.status = AsyncStatus.Ok){
            eventEmitter.emit("showMessage", ["Order Cancelled"]);
            order.status = OrderStatus.Canceled;
        }else{
            this.handleAsyncErrors(asyncResponse, "There was a problem cancelling order");
        }
    }

}


export const orderService = new OrderService();