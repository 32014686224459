




































import {defineComponent, reactive, ref, SetupContext, watch, onMounted} from "@vue/composition-api";
import SymbolSummary from "./SymbolSummary.vue";
import OptionChain from "./OptionChain.vue";
import {OptionChainExpiry} from "@/data/QuoteData";
import {tradeService} from "@/services/TradeService";
import {AddLegPayload} from "@/data/TradeData";
import {appGlobals} from "@/data/AppData";
import TradeTemplateMenu from "@/views/trade/TradeTemplateMenu.vue";

interface Props{
  componentHeight: number,
}
export default defineComponent({
  name: "TradeSettingsBar",
  props: {
    componentHeight: Number
  },
  components: {
    TradeTemplateMenu
  },
  setup(props: undefined, context: SetupContext) {
    const tradeData = tradeService.tradeData;

    function addStockLeg(long: boolean){
      const addLegPayload = new AddLegPayload();
      addLegPayload.isEquityLeg = true;
      addLegPayload.isLong = long;
      tradeService.addLegToOrder(addLegPayload)
    }

    function applyFilters(){
      tradeService.applyOptionChainFilters();
      appGlobals.user!.userPreferences!.ocNoOfStrikesFilter = tradeData.optionChainFilters.noOfStrikes;
    }

    onMounted(() => {
      tradeData.optionChainFilters.noOfStrikes = appGlobals.user!.userPreferences!.ocNoOfStrikesFilter;
    })

    return {
      addStockLeg,
      tradeData,
      applyFilters
    }
  }
})


