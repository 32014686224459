























































import {computed, defineComponent, reactive, ref, SetupContext, toRef, watch} from "@vue/composition-api";
import SymbolSummary from "./SymbolSummary.vue";
import OptionChain from "./OptionChain.vue";
import {appGlobals} from "@/data/AppData";
import {Order, OrderLeg} from "@/data/OrdersData";
import SymbolItemDisplay from "@/views/positions/SymbolItemDisplay.vue";
import {VuetifySelection} from "@/data/CommonData";
import {OrderClass, OrderType, PriceEffect, SecurityDirection} from "@/data/EnumData";
import {orderService} from "@/services/OrderService";
import {BrokerUser} from "@/data/UserData";
import {BNUtil, NumberUtil} from "@/util/Util";
import _map from "lodash/map";
import {TradeAnalysis} from "@/data/TradeData";
import {tradeService} from "@/services/TradeService";
import {eventEmitter} from "@/main";
import {DataTableHeader} from "vuetify";
import TradePreviewSection from "@/views/trade/TradePreviewSection.vue";
import TradeTicketPriceSection from "@/views/trade/TradeTicketPriceSection.vue";

export default defineComponent({
  name: "TradeSummary",
  components: {
    SymbolItemDisplay,
    TradePreviewSection,
    TradeTicketPriceSection
  },
  setup(props: undefined, context: SetupContext) {

    const tradeData = tradeService.tradeData;
    let tradeLegHeaders = reactive<DataTableHeader[]> ([
      {text: "", value: "", sortable: false, align:"center", width:"1px"},
      {text: "Legs", value: "symbol", sortable: false, align:"start", width: "30px"},
      {text: "Bid", value: "bidStr", sortable: false, align:"end", width:"1px"},
      {text: "Mid", value: "mid", sortable: false, align:"end", width:"1px"},
      {text: "Ask", value: "ask", sortable: false, align:"end", width:"1px"},
      {text: "", value: "", sortable: false, align:"center", width:"1px"}
    ]);



    function quantityChanged(leg: OrderLeg){
      // quantity on the order leg changed
      console.log("New quantity of leg - "+leg.quantity);
      tradeService.legQuantityChanged(leg);
    }


    function removeLeg(event: Event, leg: OrderLeg){
      tradeService.removeLegFromOrder(leg).then(function(){
        // nothing much to do here
      });
      // stop event propagation, otherwise the event bubbles and emits row clicked event
      event.stopImmediatePropagation();
    }

    function toggleLegSelection(leg: OrderLeg){
      tradeService.toggleLegSelection(leg);
    }

    function focusLeg(leg: OrderLeg){
      tradeService.focusLegInOptionChain(leg);
    }

    return {
      tradeData,
      tradeLegHeaders,
      quantityChanged,
      OrderType,
      removeLeg,
      focusLeg,
      toggleLegSelection
    }
  }
})


