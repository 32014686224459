import { render, staticRenderFns } from "./TradeTicket.vue?vue&type=template&id=29670805&scoped=true&"
import script from "./TradeTicket.vue?vue&type=script&lang=ts&"
export * from "./TradeTicket.vue?vue&type=script&lang=ts&"
import style0 from "./TradeTicket.vue?vue&type=style&index=0&id=29670805&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29670805",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VDataTable,VIcon,VSlideXReverseTransition,VSlideXTransition,VToolbar})
