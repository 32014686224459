import {Order, RollingLimitParams} from "@/data/OrdersData";
import {AxiosResponse} from "axios";
import {axiosInstance} from "@/util/AxiosUtil";
import {User} from "@/data/UserData";
import BaseBackendService from "@/services/backend/BaseBackendService";
import {classToPlain, plainToClass} from "class-transformer";
import {AsyncResponse} from "@/data/CommonData";
import {AsyncStatus} from "@/data/EnumData";
import {Logger} from "@/util/AppLogger";
import {appGlobals} from "@/data/AppData";


export default class OrderBackendService extends BaseBackendService {
    logger = new Logger("OrderBackendService");
    async createRollingLimitOrder(order: Order): Promise<AsyncResponse<RollingLimitParams>>{
        const asyncResponse: AsyncResponse = new AsyncResponse<RollingLimitParams>();
        const rollingLimitParams : RollingLimitParams = order.rollingLimitParams;
        rollingLimitParams.userAccount = order.userAccount;
        try {
            const rollingLimitParamsPlain = this.buildRollingLimitOrderPlain(rollingLimitParams);
            const axiosResponse: AxiosResponse = await axiosInstance.post<RollingLimitParams>("/rollingLimitOrders", rollingLimitParamsPlain);
            const responseData = axiosResponse.data;
            // the server just returns the entire input payload back with an additional field
            rollingLimitParams.rollingOrderId = responseData["rollingOrderId"]
        }catch (exception) {
            this.captureErrors(exception, asyncResponse);
            this.logger.error(exception);
        }
        return asyncResponse;
    }

    async getRollingLimitOrders(): Promise<AsyncResponse<RollingLimitParams[]>>{
        const asyncResponse: AsyncResponse = new AsyncResponse<RollingLimitParams[]>();
        try {
            const axiosResponse: AxiosResponse = await axiosInstance.get("/rollingLimitOrders");

        }catch (exception) {
            this.captureErrors(exception, asyncResponse);
            this.logger.error(exception);
        }
        return asyncResponse;
    }

    private buildRollingLimitParamsFromPlain(rollingLimitOrder:any): RollingLimitParams{
        const rollingLimitParams = new RollingLimitParams();
        rollingLimitParams.rollingOrderId = rollingLimitOrder.rollingOrderId;
        rollingLimitParams.brokerOrderId = rollingLimitOrder.brokerOrderId;
        // appGlobals.user?.getBrokerUser()
        // rollingLimitParams.userAccount =


        return rollingLimitParams;
    }

    private buildRollingLimitOrderPlain(rollingLimitParams: RollingLimitParams): any{
        const rollingLimitOrderPlain = {
            brokerOrderId: rollingLimitParams.brokerOrderId,
            userAccount: {
                id: rollingLimitParams.userAccount!.id
            },
            priceStart: rollingLimitParams.priceStartInput,
            priceStartEffect: rollingLimitParams.priceStartEffect.enumKey,
            priceStop1: rollingLimitParams.priceStop1Input,
            priceStop1Effect: rollingLimitParams.priceStop1Effect.enumKey,
            priceStop2: rollingLimitParams.priceStop2Input,
            priceStop2Effect: rollingLimitParams.priceStop2Effect.enumKey,
            adjustment1: rollingLimitParams.adjustment1,
            adjustment2: rollingLimitParams.adjustment2,
            timeIncrement1: rollingLimitParams.timeIncrement1,
            timeIncrement2: rollingLimitParams.timeIncrement2
        }
        return rollingLimitOrderPlain;
    }



}

export const orderBackendService = new OrderBackendService();