




































import { defineComponent, SetupContext } from "@vue/composition-api";
import { appGlobals } from "@/data/AppData";

export default defineComponent({
  name: "MainAppBar",

  setup(props: undefined, context: SetupContext) {
    function toggleDrawer() {
      appGlobals.navBarState.drawer = !appGlobals.navBarState.drawer;
    }

    return {
      toggleDrawer
    };
  }
});
