import {BrokerUser, User, UserAccount, UserBrokerData} from "@/data/UserData";
import {SharedTradeData} from "@/data/TradeData";
import {QuotableGroups} from "@/data/QuoteData";
import {reactive, ref} from "@vue/composition-api";
import {LRUMap} from 'lru_map'
import BigNumber from "bignumber.js";

// Global Cached data, only one instance needs to be there
// shared across all components
export default class AppGlobals {
  user?: User;
  token = "";
  navBarState: ObjectLiteral<any> = {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage:
      "https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg",
    drawer: true
  };
  // cached broker data per BrokerUser // key is the brokerUserId
  userBrokerDataMap : {[key: string]: UserBrokerData} = {};
  // cache of all flat list of position tags
  // needs to be updated whenever new tags are loaded from remote
  allPositionTags : string[] = [];
  // flags to indicate the state of data loading
  positionsLoaded: boolean = false;
  accountsLoaded: boolean = false;
  ordersLoaded: boolean = false;
  // the current userAccount and brokerUser in context
  selectedUserAccount: UserAccount = new UserAccount();
  selectedBrokerUser: BrokerUser = new BrokerUser();

  // caches recently used trade symbols data
  tradeDataCache: LRUMap<string, SharedTradeData> = new LRUMap(10);

  getBrokerDataMap(brokerUser: BrokerUser){
    return this.userBrokerDataMap[brokerUser.brokerUserId];
  }

}

export const appGlobals = new AppGlobals();

export class BadRequest {
  errors?: string[];
  messages?: string[];
}

export interface ObjectLiteral<T> {
  [key: string]: T;
}


/**
 * Broker specific configuration for API limits etc.,
 */
export interface BrokerConfig{
  minimumDelay: number;
  delayPerAccount: number;
  quotesDelay: number;
}


