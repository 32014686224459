























































import {defineComponent, onMounted, reactive, Ref, ref, SetupContext, nextTick} from "@vue/composition-api";
import {appGlobals} from "@/data/AppData";
import {DataTableHeader} from "vuetify";
import {BNUtil, NumberUtil} from "@/util/Util";
import BigNumber from "bignumber.js";
import {accountService} from "@/services/AccountService";
import {BrokerAccount} from "@/data/AccountData";
import {eventEmitter} from "@/main";

export default defineComponent({
  name: "Accounts",
  components: {},
  setup(props: undefined, context: SetupContext) {
    let dataLoadingInd = ref<boolean>(true);
    let accountHeaders = reactive(buildHeaders());
    let brokerAccounts = reactive<BrokerAccount[]>([]);

    function loadAccounts(){
      brokerAccounts.splice(0, brokerAccounts.length, ...accountService.getCachedAccounts());
      dataLoadingInd.value = false;
      console.log("brokerAccounts.length: "+ brokerAccounts.length);
    }

    onMounted(() => {
      eventEmitter.on("accountsLoaded", function(){
        loadAccounts();
      })
      if (appGlobals.accountsLoaded){
        loadAccounts();
      }

    });

    nextTick(() => {
      // trigger an event, so positions can adjust it's height
      context.emit("accounts-rendered");
    })


    return {
      dataLoadingInd,
      accountHeaders,
      brokerAccounts
    }
  }
});

function  buildHeaders() : any[]{
  // these are the default headers
  let accountHeaders : DataTableHeader[] = [
    {text: "", value: "colorIndex", sortable: false, align:"end"},
    {text: "Account", value: "accountNumber", sortable: false, align:"start"},
    {text: "", value: "More", sortable: false, align:"end"},
    // {text: "Class", value: "class", sortable: false,align:"start"},
    {text: "Type", value: "accountBalances.accountType", sortable: false,align:"start"},
    {text: "Total Account Value", value: "accountBalances.totalEquity", sortable: false,align:"end"},
    {text: "Option Buying Power", value: "accountBalances.optionBuyingPower", sortable: false,align:"end"},
    {text: "Stock Buying Power", value: "accountBalances.stockBuyingPower", sortable: false,align:"end"},
    {text: "Delta", value: "delta", sortable: false,align:"end"},
    {text: "Theta", value: "theta", sortable: false,align:"end"},
    {text: "PL Day", value: "pnlDay", sortable: false,align:"end"},
    {text: "Unrealized PL", value: "accountBalances.openPl", sortable: false,align:"end"},
    {text: "Net Liq", value: "accountBalances.netLiquidity", sortable: false,align:"end"},
  ];
 return accountHeaders;
}

