import {
    PositionItemType,
    SecurityType,
    TableColumnType,
    AccountIconIndexMapping,
    SecurityDirection,
    TableColumnDatatype,
    SymbolItemType
} from "@/data/EnumData";
import {BaseData, BrokerUser, UserAccount, UserPrivilege} from "@/data/UserData";
import {Quotable} from "@/data/QuoteData";
import DateUtil, {BNUtil} from "@/util/Util";
import {Constants} from "@/data/common/Constants";
import BigNumber from "bignumber.js";
import _sortBy from "lodash/sortBy";
import {Type} from "class-transformer";
import { positionService } from "@/services/PositionService";

/**
 * Basic Position Information from the broker
 */
export class PositionBasic{
    id?: string; // may or may not exist based on broker
    quantity: number= 0;
    symbol: string = "";
    unitPrice: string = "";
    userAccount?: UserAccount;
    brokerUser?: BrokerUser;
}

// represents an OrderLeg or a PositionItem
export interface SymbolItem{
    symbol: string; // stock symbol or option osiSymbol
    underlying: string; // stock symbol
    securityType: SecurityType;
    quantity: number;
    quantityStr: string;
    quantityFilledStr?: string; // only applicable to OrderLeg (not to position item)
    currentPricePerUnitStr?: string;    // An OrderLeg doesn't set this property
    expiryDateShort: string;
    expiryDate: Date;
    daysToExpiry: number;
    strikePrice: BigNumber;
    symbolItemType:SymbolItemType;
}

/**
 * Each Position entry with additional details
 */
export class PositionItem implements SymbolItem {

    symbolItemType = SymbolItemType.PositionItem;
    type: PositionItemType = PositionItemType.Position; // initializing to Position but change as needed. used to determine which of symbol/leg values are shown conditionally
    brokerPositionId = 0; // id of the Position with the broker
    symbol = "";    // stock symbol for stock and osiSymbol for option position
    underlying = ""; //
    // brokerSymbol = ""; // symbol used by broker for this position item
    multipleUserAccounts: boolean = false;  // indicates if this grouped item has children positions belonging to multiple accounts
    userAccount?: UserAccount;
    brokerUser?: BrokerUser;
    iconColor = "";

    stockPriceStr = "0.00";     // derived from stockPrice
    private  _stockPrice = BNUtil.ZERO;
    stockPriceColor = Constants.NeutralColor;   // derived from stockPrice

    stockPriceChangeStr = "0.00";
    private  _stockPriceChange  = BNUtil.ZERO;
    stockPriceChangeColor = Constants.NeutralColor;     // green if stockPriceChange > 0, red if stockPriceChange < 0, gray if stockPriceChange == 0

    quantityStr = "";
    private _quantity = 0;  //   could be pos/neg based on long/short

    itm = false;    // indicates if the option is In the Money
    expiringSoon = false;   // set to true if the option expires in the next two days
    
    private  _expiryDate = new Date(); // this could be
    expiryDateStr = "" ;    // format - yyyy-MM-dd
    expiryDateShort = ""; //    format - 'MMM dd'
    daysToExpiry = 0; //   number of days to expiry

    private  _strikePrice = BNUtil.ZERO; //
    strikePriceStr = ""     // derived from strikePrice

    private  _securityType : SecurityType = SecurityType.Call;    // defaulting to call
    securityTypeLabel = "";  // shows 'P', 'C' or 'Shares'

    securityDirection: SecurityDirection = SecurityDirection.Long;  // defaulting to Long

    private  _tradePricePerUnit = BNUtil.ZERO; //
    tradePricePerUnitStr = "0.00";

    private  _currentPricePerUnit = BNUtil.ZERO; //
    currentPricePerUnitStr = "0.00";

    private  _priceChangePerUnit = BNUtil.ZERO; //
    priceChangePerUnitStr = "0.00";

    private _cost = BNUtil.ZERO; // represents total tradePrice. For option contract this means (_tradePricePerUnit * quantity * 100)
    costStr = "0.00";

    private _netLiquidity = BNUtil.ZERO; // represents total currentPrice. For option contract this means (_currentPricePerUnit * quantity * 100)
    netLiquidityStr = "0.00";

    private  _deltaPerUnit = BNUtil.ZERO; //
    deltaPerUnitStr = "0.00";

    private  _thetaPerUnit = BNUtil.ZERO; //
    thetaPerUnitStr = "0.00";

    private  _gammaPerUnit = BNUtil.ZERO;
    gammaPerUnitStr = "0.00";

    private  _vegaPerUnit = BNUtil.ZERO;
    vegaPerUnitStr = "0.00";

    private  _totalDelta = BNUtil.ZERO;
    totalDeltaStr = "0.00";

    private  _totalTheta  = BNUtil.ZERO;
    totalThetaStr = "0.00";

    private  _totalGamma = BNUtil.ZERO;
    totalGammaStr = "0.00";

    private  _totalVega = BNUtil.ZERO;
    totalVegaStr = "0.00";

    private  _impliedVolatility = BNUtil.ZERO; //
    impliedVolatilityStr = "0.00";

    private  _tags : string[]  = [];
    tagsStr = "";

    private _pnlDayPerUnit = BNUtil.ZERO; //
    pnlDayPerUnitStr = "0.00";

    private _pnlOpenPerUnit = BNUtil.ZERO; //
    pnlOpenPerUnitStr = "0.00";

    private _pnlDayTotal = BNUtil.ZERO; //
    pnlDayTotalStr = "0.00";

    private _pnlOpenTotal = BNUtil.ZERO; //
    pnlOpenTotalStr = "0.00";

    private _openDate = new Date(); //
    openDateStr = "";


    // PnlDayPerUnit("/PL Day"),
    // PnlOpenPerUnit("/PL Open"),
    // OpenDate("Open Date");

    // the quotable that's updated with SecurityQuote
    quotable: Quotable | undefined;
    // stockQuotable: Quotable | undefined;    // for option positions, this is the stock quotable

    // reference to children
    // parent position holds child positions
    children: PositionItem[] = [];

    // used in Positions view to indicate if the that particular positionItem is expanded or not
    expanded = false;
    // indicates whether to show this element in the UI or not
    show = false;
    // whether to show toggle option
    showToggle = false;
    // indicates whether user clicked on this to select/de-select
    selected = false;

    get accountName(): string | undefined{
        return this.userAccount?.accountName;
    }

    get expiryDate():Date{
        return this._expiryDate;
    }
    set expiryDate(expiryDate: Date){
        this._expiryDate = expiryDate;
        // also set derived properties
        this.expiryDateShort = DateUtil.toExpiryViewStrNoYear(this._expiryDate);
        this.expiryDateStr = DateUtil.toDateStr(expiryDate);
        this.daysToExpiry = DateUtil.daysUntil(expiryDate);
        if(this.daysToExpiry < 230) {
            this.expiringSoon = true;
        }
    }

    get stockPrice(): BigNumber{
        return this._stockPrice;
    }

    set stockPrice(stockPrice){
        this._stockPrice = stockPrice;
        this.stockPriceStr = BNUtil.formatBN(stockPrice);
    }

    get quantity(): number{
        return this._quantity;
    }
    set quantity(quantity: number){
        this._quantity = quantity;
        this.quantityStr = String(quantity);
        this.securityDirection = quantity < 0 ? SecurityDirection.Short : SecurityDirection.Long;
    }

    get stockPriceChange(): BigNumber{
        return this._stockPriceChange
    }
    set stockPriceChange(change: BigNumber){
        this.stockPriceChangeStr = BNUtil.formatBN(change);
        this._stockPriceChange = change;
        this.stockPriceChangeColor = this.getColor(change);
        this.stockPriceColor = this.stockPriceChangeColor;
    }

    get strikePrice(): BigNumber {
        return this._strikePrice;
    }

    set strikePrice(value: BigNumber) {
        this.strikePriceStr = BNUtil.formatBN(value);
        this._strikePrice = value;
    }

    get securityType(): SecurityType {
        return this._securityType;
    }

    set securityType(value: SecurityType) {
        this.securityTypeLabel = value.shortLabel;
        this._securityType = value;
    }

    get tradePricePerUnit(): BigNumber {
        return this._tradePricePerUnit;
    }

    set tradePricePerUnit(value: BigNumber) {
        this.tradePricePerUnitStr = BNUtil.formatBN(value);
        this._tradePricePerUnit = value;
    }

    get currentPricePerUnit(): BigNumber {
        return this._currentPricePerUnit;
    }

    set currentPricePerUnit(value: BigNumber) {
        this.currentPricePerUnitStr = BNUtil.formatBN(value);
        this._currentPricePerUnit = value;
    }

    get priceChangePerUnit(): BigNumber {
        return this._priceChangePerUnit;
    }

    set priceChangePerUnit(value: BigNumber) {
        this.priceChangePerUnitStr = BNUtil.formatBN(value);
        this._priceChangePerUnit = value;
    }

    get cost(): BigNumber {
        return this._cost;
    }

    set cost(value: BigNumber) {
        this.costStr = BNUtil.formatBN(value);
        this._cost = value;
    }

    addToCost(value: BigNumber){
        this.cost = this.cost.plus(value);
    }

    get netLiquidity(): BigNumber {
        return this._netLiquidity;
    }

    set netLiquidity(value: BigNumber) {
        this.netLiquidityStr = BNUtil.formatBN(value);
        this._netLiquidity = value;
    }

    addToNetLiquidity(value: BigNumber){
        this.netLiquidity = this.netLiquidity.plus(value);
    }

    get deltaPerUnit(): BigNumber {
        return this._deltaPerUnit;
    }

    set deltaPerUnit(value: BigNumber) {
        this.deltaPerUnitStr = BNUtil.formatBN(value);
        this._deltaPerUnit = value;
    }

    get thetaPerUnit(): BigNumber {
        return this._thetaPerUnit;
    }

    set thetaPerUnit(value: BigNumber) {
        this.thetaPerUnitStr = BNUtil.formatBN3(value);
        this._thetaPerUnit = value;
    }

    get gammaPerUnit(): BigNumber {
        return this._gammaPerUnit;
    }

    set gammaPerUnit(value: BigNumber) {
        this.gammaPerUnitStr = BNUtil.formatBN3(value);
        this._gammaPerUnit = value;
    }

    get vegaPerUnit(): BigNumber {
        return this._vegaPerUnit;
    }

    set vegaPerUnit(value: BigNumber) {
        this.vegaPerUnitStr = BNUtil.formatBN3(value);
        this._vegaPerUnit = value;
    }

    get totalDelta(): BigNumber {
        return this._totalDelta;
    }

    set totalDelta(value: BigNumber) {
        this.totalDeltaStr = BNUtil.formatBN(value);
        this._totalDelta = value;
    }

    addToTotalDelta(value: BigNumber){
        this.totalDelta = this.totalDelta.plus(value);
    }

    get totalTheta(): BigNumber {
        return this._totalTheta;
    }

    set totalTheta(value: BigNumber) {
        this.totalThetaStr = BNUtil.formatBN3(value);
        this._totalTheta = value;
    }

    addToTotalTheta(value: BigNumber){
        this.totalTheta = this.totalTheta.plus(value);
    }

    get totalGamma(): BigNumber {
        return this._totalGamma;
    }

    set totalGamma(value: BigNumber) {
        this.totalGammaStr = BNUtil.formatBN3(value);
        this._totalGamma = value;
    }

    addToTotalGamma(value: BigNumber){
        this.totalGamma = this.totalGamma.plus(value);
    }

    get totalVega(): BigNumber {
        return this._totalVega;
    }

    set totalVega(value: BigNumber) {
        this.totalVegaStr = BNUtil.formatBN3(value);
        this._totalVega = value;
    }

    addToTotalVega(value: BigNumber){
        this.totalVega = this.totalVega.plus(value);
    }

    get impliedVolatility(): BigNumber {
        return this._impliedVolatility;
    }

    set impliedVolatility(value: BigNumber) {
        this.impliedVolatilityStr = BNUtil.formatBN(value);
        this._impliedVolatility = value;
    }

    get pnlDayPerUnit(): BigNumber {
        return this._pnlDayPerUnit;
    }

    set pnlDayPerUnit(value: BigNumber) {
        this.pnlDayPerUnitStr = BNUtil.formatBN(value);
        this._pnlDayPerUnit = value;
    }

    get pnlOpenPerUnit(): BigNumber {
        return this._pnlOpenPerUnit;
    }

    set pnlOpenPerUnit(value: BigNumber) {
        this.pnlOpenPerUnitStr = BNUtil.formatBN(value);
        this._pnlOpenPerUnit = value;
    }

    get pnlDayTotal(): BigNumber {
        return this._pnlDayTotal;
    }

    set pnlDayTotal(value: BigNumber) {
        this.pnlDayTotalStr = BNUtil.formatBN(value);
        this._pnlDayTotal = value;
    }

    get pnlOpenTotal(): BigNumber {
        return this._pnlOpenTotal;
    }

    set pnlOpenTotal(value: BigNumber) {
        this.pnlOpenTotalStr = BNUtil.formatBN(value);
        this._pnlOpenTotal = value;
    }

    get openDate(): Date {
        return this._openDate;
    }

    set openDate(value: Date) {
        this.openDateStr = DateUtil.toDateStr(value);
        this._openDate = value;
    }

    get tags(): string[] {
        return this._tags;
    }

    set tags(value: string[]) {
        this._tags = value;
        this.tagsStr = value.join(",");
    }

    getColor(data : string | BigNumber){
        if (data){
            data = new BigNumber(data);
            return data.isNegative()  ? Constants.NegativeColor : data.isPositive() ? Constants.PositiveColor : Constants.NeutralColor;
        }
        return Constants.NeutralColor;
    }

    toggleChildrenVisibility(show:boolean) {
        if (show){
            this.children.forEach(child => child.show = true);
        }else{
            // hide children
            this.children.forEach(child => {
                child.show = false;
                child.expanded = false;
                child.toggleChildrenVisibility(show);
            })
        }
    }

    getOptionChildren() {
        let optionChildren = this.children.filter(child => child.securityType.isOption);
        return optionChildren;
    }

    updateITMFlag(){
        //A call option is in the money (ITM) if the market price is above the strike price.
        //A put option is in the money if the market price is below the strike price.
        this.itm = false;
       if(this.securityType.isOption && this.securityDirection.isShort) {
            if(this.securityType.isCall){
                this.itm = (this.stockPrice > this.strikePrice)? true: false; 
            } else if(this.securityType.isPut) {
                this.itm = (this.stockPrice < this.strikePrice)? true: false; 
            } 
        }  
    }
}

/**
 * Aggregated totals of all positions currently displayed in the Positions grid
 * They can all be strings, as these are just used for display and not used in calculating other derived fields
 */
export class PositionTotals{
    totalDelta: string = "0.00";
    totalTheta: string = "0.00";
    totalGamma: string = "0.00";
    totalVega: string = "0.00";
    totalPlDay: string = "0.00";
    totalPlOpen: string = "0.00";
    totalLiquidity: string = "0.00";
}

export class PositionTag extends BaseData {
    userAccountId?: number;
    positionId = "";
    symbol = "";
    tag = "";
}

/**
 * Defines the properties of a dynamic column in the Positions grid
 */
export class PositionColumn{
    key = "";  // column id used in the backend
    headerName = "";  // Name to display in the column header
    fullName = "";  // Name to display in the Column DnD selection dialog
    //
    type: TableColumnType = TableColumnType.String;
    datatype: TableColumnDatatype = TableColumnDatatype.BigNumber; // datatype used in sorting. most of the columns are of type BigNumber
    showColor = true;
    property = "";          // property form PositionItem object that is used for comparison, sorting etc., In some cases both displayProperty and property could refer to the same property. For example - daysToExpiry
    displayProperty = "";   // property from PositionItem object that is actually displayed on screen. For example - displayProperty would point to 'deltaPerUnitStr' where as property would point to 'deltaPerUnit'
    // Whether to show this column in Preview or not
    showInPreview?: boolean = false;
    // Indicates whether this is a unit column or total column. Total column give take quantity and also multiply by 100.
    // In preview, we plan to show a check-box allowing users to choose whether they want to view unit columns or total columns
    // For preview tab, if the 'unitColumn' is undefined, the field is shown irrespective of the value of the checkbox
    unitColumn?: boolean | undefined;
    showHeaderTotal?: boolean = false;   // indicates if this column's totals should be shown in Position Grid Header
    defaultForPositions: boolean = false;
    showIcon?: boolean = false;
    icon?: string = "";
}

const positionColumnsList : PositionColumn[] = [    
    {
        key: "DaysToExpiry",
        headerName:"DTE",
        fullName: "Days To Expiry (DTE)",
        type: TableColumnType.Numeric,
        datatype: TableColumnDatatype.Number,
        showColor: false,
        property: "daysToExpiry",
        displayProperty: "daysToExpiry",
        showInPreview: false,
        unitColumn: true,
        defaultForPositions: true,
        showHeaderTotal: false
    },
    {
        key: "AccountName",
        headerName:"Acct Nm",
        fullName: "Account Name",
        type: TableColumnType.String,
        datatype: TableColumnDatatype.String,
        showColor: false,
        property: "accountName",
        displayProperty: "accountName",
        showInPreview: false,
        unitColumn: true,
        defaultForPositions: false,
        showHeaderTotal: false
    },
    {
        key: "TradePricePerUnit",
        headerName:"/Trd Prc",
        fullName: "Trade Price Per Unit",
        type: TableColumnType.Money,
        datatype: TableColumnDatatype.BigNumber,
        showColor: true,
        property: "tradePricePerUnit",
        displayProperty: "tradePricePerUnitStr",
        showInPreview: false,
        unitColumn: true,
        defaultForPositions: true,
        showHeaderTotal: true
    },
    {
        key: "CurrentPricePerUnit",
        headerName:"/Curr Prc",
        fullName: "Current Price Per Unit",
        type: TableColumnType.Money,
        datatype: TableColumnDatatype.BigNumber,
        showColor: true,
        property: "currentPricePerUnit",
        displayProperty: "currentPricePerUnitStr",
        showInPreview: false,
        unitColumn: true,
        defaultForPositions: true,
        showHeaderTotal: true
    },
    {
        key: "PriceChangePerUnit",
        headerName:"/Prc Chng",
        fullName: "Price Change Per Unit",
        type: TableColumnType.Money,
        datatype: TableColumnDatatype.BigNumber,
        showColor: true,
        property: "priceChangePerUnit",
        displayProperty: "priceChangePerUnitStr",
        showInPreview: false,
        unitColumn: true,
        defaultForPositions: false,
        showHeaderTotal: false
    },
    {
        key: "DeltaPerUnit",
        headerName:"/ \u0394",
        fullName: "Delta Per Unit",
        type: TableColumnType.Numeric,
        datatype: TableColumnDatatype.BigNumber,
        showColor: false,
        property: "deltaPerUnit",
        displayProperty: "deltaPerUnitStr",
        showInPreview: true,
        unitColumn: true,
        defaultForPositions: true,
        showHeaderTotal: true
    },
    {
        key: "ThetaPerUnit",
        headerName:"/ \u03F4",
        fullName: "Theta Per Unit",
        type: TableColumnType.Numeric,
        datatype: TableColumnDatatype.BigNumber,
        showColor: false,
        property: "thetaPerUnit",
        displayProperty: "thetaPerUnitStr",
        showInPreview: true,
        unitColumn: true,
        defaultForPositions: true,
        showHeaderTotal: true
    },
    {
        key: "GammaPerUnit",
        headerName:"/ \u0393",
        fullName: "Gamma Per Unit",
        type: TableColumnType.Numeric,
        datatype: TableColumnDatatype.BigNumber,
        showColor: false,
        property: "gammaPerUnit",
        displayProperty: "gammaPerUnitStr",
        showInPreview: true,
        unitColumn: true,
        defaultForPositions: true,
        showHeaderTotal: true
    },
    {
        key: "VegaPerUnit",
        headerName:"/ \u03BD",
        fullName: "Vega Per Unit",
        type: TableColumnType.Numeric,
        datatype: TableColumnDatatype.BigNumber,
        showColor: false,
        property: "vegaPerUnit",
        displayProperty: "vegaPerUnitStr",
        showInPreview: true,
        unitColumn: true,
        defaultForPositions: true,
        showHeaderTotal: true
    },
    {
        key: "TotalDelta",
        headerName:"\u0394",
        fullName: "Total Delta",
        type: TableColumnType.Numeric,
        datatype: TableColumnDatatype.BigNumber,
        showColor: false,
        property: "totalDelta",
        displayProperty: "totalDeltaStr",
        showInPreview: true,
        unitColumn: false,
        defaultForPositions: false,
        showHeaderTotal: true
    },
    {
        key: "TotalTheta",
        headerName:"\u03F4",
        fullName: "Total Theta",
        type: TableColumnType.Numeric,
        datatype: TableColumnDatatype.BigNumber,
        showColor: false,
        property: "totalTheta",
        displayProperty: "totalThetaStr",
        showInPreview: true,
        unitColumn: false,
        defaultForPositions: false,
        showHeaderTotal: true
    },
    {
        key: "TotalGamma",
        headerName:"\u0393",
        fullName: "Total Gamma",
        type: TableColumnType.Numeric,
        datatype: TableColumnDatatype.BigNumber,
        showColor: false,
        property: "totalGamma",
        displayProperty: "totalGammaStr",
        showInPreview: true,
        unitColumn: false,
        defaultForPositions: false,
        showHeaderTotal: true
    },
    {
        key: "TotalVega",
        headerName:"\u03BD",
        fullName: "Total Vega",
        type: TableColumnType.Numeric,
        datatype: TableColumnDatatype.BigNumber,
        showColor: false,
        property: "totalVega",
        displayProperty: "totalVegaStr",
        showInPreview: true,
        unitColumn: false,
        defaultForPositions: false,
        showHeaderTotal: true
    },
    {
        key: "ImpliedVolatility",
        headerName: "Impl Vol.",
        fullName: "Implied Volatility",
        type: TableColumnType.Numeric,
        datatype: TableColumnDatatype.BigNumber,
        showColor: false,
        property: "impliedVolatility",
        displayProperty: "impliedVolatilityStr",
        showInPreview: false,
        defaultForPositions: false,
        showHeaderTotal: false
    },
    {
        key: "PnlDay",
        headerName: "PL Day",
        fullName: "Today's PnL",
        type: TableColumnType.Money,
        datatype: TableColumnDatatype.BigNumber,
        showColor: true,
        property: "pnlDayTotal",
        displayProperty: "pnlDayTotalStr",
        showInPreview: false,
        defaultForPositions: true,
        showHeaderTotal: true
    },
    {
        key: "PnlOpen",
        headerName: "PL Open",
        fullName: "PnL Since Open",
        type: TableColumnType.Money,
        datatype: TableColumnDatatype.BigNumber,
        showColor: true,
        property: "pnlOpenTotal",
        displayProperty: "pnlOpenTotalStr",
        showInPreview: false,
        defaultForPositions: true,
        showHeaderTotal: true
    },
    {
        key: "PnlDayPerUnit",
        headerName: "/PL Day",
        fullName: "Today's Pnl Per Unit",
        type: TableColumnType.Money,
        datatype: TableColumnDatatype.BigNumber,
        showColor: true,
        property: "pnlDayPerUnit",
        displayProperty: "pnlDayPerUnitStr",
        showInPreview: false,
        defaultForPositions: false,
        showHeaderTotal: true
    },
    {
        key: "PnlOpenPerUnit",
        headerName: "/PL Open",
        fullName: "PnL Since Open Per Unit",
        type: TableColumnType.Money,
        datatype: TableColumnDatatype.BigNumber,
        showColor: true,
        property: "pnlOpenPerUnit",
        displayProperty: "pnlOpenPerUnitStr",
        showInPreview: false,
        defaultForPositions: false,
        showHeaderTotal: true
    },
    {
        key: "NetLiquidity",
        headerName: "Net Liq",
        fullName: "Net Liquidity",
        type: TableColumnType.Money,
        datatype: TableColumnDatatype.BigNumber,
        showColor: true,
        property: "netLiquidity",
        displayProperty: "netLiquidityStr",
        showInPreview: false,
        defaultForPositions: false,
        showHeaderTotal: true
    },
    {
        key: "Cost",
        headerName: "Cost",
        fullName: "Tot. Cost",
        type: TableColumnType.Money,
        datatype: TableColumnDatatype.BigNumber,
        showColor: false,
        property: "cost",
        displayProperty: "costStr",
        showInPreview: false,
        defaultForPositions: false,
        showHeaderTotal: true
    },
    {
        key: "OpenDate",
        headerName: "Open Dt",
        fullName: "Open Date",
        type: TableColumnType.String,
        datatype: TableColumnDatatype.Date,
        showColor: false,
        property: "openDate",
        displayProperty: "openDateStr",
        showInPreview: false,
        defaultForPositions: false,
        showHeaderTotal: false
    },
    {
        key: "Tags",
        headerName:"Tags",
        fullName: "Tags",
        type: TableColumnType.String,
        datatype: TableColumnDatatype.String,
        showColor: false,
        property: "tags",
        displayProperty: "tagsStr",
        showInPreview: false,
        unitColumn: false,
        defaultForPositions: true,
        showHeaderTotal: false,
        showIcon: true,
        icon: "local_offer"
    }
]

export class PositionColumnsConfig{
    allColumnsList: PositionColumn[] = []
    // this dictionary is by PositionColumn.key
    allColumnsDict : {[key: string]: PositionColumn} ={};
    // this dictionary is by PositionColumn.property
    allColumnsDictByProperty: {[key: string]: PositionColumn} ={};

    constructor(){
        this.allColumnsList = _sortBy(positionColumnsList, item => item.fullName);
        this.allColumnsList.forEach(positionColumn => this.allColumnsDict[positionColumn.key] = positionColumn);
        this.allColumnsList.forEach(positionColumn => this.allColumnsDictByProperty[positionColumn.property] = positionColumn);
    }

}

export const positionColumnsConfig = new PositionColumnsConfig();



