import {OptionChainExpiry, OptionContract, OptionPair, Quotable} from "@/data/QuoteData";
import {BaseData, BrokerUser, UserAccount} from "@/data/UserData";
import {Order, OrderPreview} from "@/data/OrdersData";
import BigNumber from "bignumber.js";
import {BNUtil} from "@/util/Util";
import {reactive} from "@vue/composition-api";
import {GlobalEvents, VuetifySelection} from "@/data/CommonData";
import {SecurityDirection, SecurityType, StrikeSelectionCriteria, TradeMode} from "@/data/EnumData";
import mitt, {Emitter} from "mitt";
import _find from "lodash/find";
import {LRUMap} from "lru_map";
import {Constants} from "@/data/common/Constants";
import {PositionColumn} from "@/data/PositionsData";
import _sortBy from "lodash/sortBy";


/**
 * An instance of this class is made available in AppData to be shared by different components that make up the TradeMain view
 */
export class SharedTradeData{

    // local event emitter for trade components
    eventEmitter : Emitter<TradeEvents> = mitt<TradeEvents>();
    //
    symbol: string = "";
    // used to keep track of the working symbol before it was changed, used in caching
    oldSymbol: string = "";
    // default to New
    mode: TradeMode = TradeMode.New;
    // // indicates if we are in preview mode, where changes cannot be made to the order
    // private _previewMode: boolean = false;
    // // set to true, when an existing order is being edited
    // private _editMode: boolean = false;
    // // if enabled, all leg changes are disabled, this happens when previewing an order or editing an existing order
    // disableLegChanges : boolean = false;
    // // if set, all price fields are disabled (orderType, Duration, Price, Account etc.,). this happens when Previewing an order
    // disablePriceChanges : boolean = false;

    quotable?: Quotable;    // quotable for the symbol
    // option chain grid indicator for data loading
    optionChainGridLoadingIndicator = false;
    // used in OptionChain display
    optionChainItems : Array<OptionChainExpiry | OptionPair> = [];
    // option expiry list. the items in this list are also added to optionChainItems
    optionExpiries : OptionChainExpiry[] = [];
    // keeps track of the last 4 (configurable) option expiries that were expanded
    // we want to keep only a limited set of expiries open at a time,
    // 1. so as to limit the number of quotes to update
    // 2. to make the UI doesn't get bogged down by too much data
    lruOptionExpiries: LRUMap<string, OptionChainExpiry> = new LRUMap<string, OptionChainExpiry>(Constants.OptionChainOpenExpiriesLimit);
    // Order that is being constructed to place an Order
    order: Order = new Order();
    // used to populate the ordertype dropdown
    orderTypes : VuetifySelection[] = [];
    // used to populate durations dropdown
    durationSelections: VuetifySelection[] = []

    submittingOrder: boolean = false;   // set to true when submitting order to display a spinner in preview area
    loadingPreview: boolean = false;    // set to true when loading order preview
    orderPreview: OrderPreview = new OrderPreview();
    // when user enters the trade screen, these values should be set to default values
    // or to the values previously selected
    // or to the account/user associated with the closing positions
    userAccount?: UserAccount;
    brokerUser?: BrokerUser;
    // list for userAccount selection dropdown
    userAccountSelections: VuetifySelection[] = []

    tradeAnalysis = new TradeAnalysis();

    tradeSettings = new TradeSettings();

    optionChainColumnsConfig = new OptionChainColumnsConfig();

    optionChainFilters = new OptionChainFilters();

    getOptionExpiryByDate(expiryDate: Date){
        return _find(this.optionExpiries, optionChainExpiry => optionChainExpiry.expiryDate.getTime() === expiryDate.getTime());
    }
}

export class AddLegPayload{
    isEquityLeg: boolean = false;   // indicates if equity leg is being added to the order or option leg
    isLong: boolean = false;        // is it a long or short leg
    isClose: boolean = false;       // is the leg being closed or opened
    selectedOptionContract?: OptionContract;    // for option leg, this is the optionContract
    // if quantity > 0, this is used otherwise default quantity is used
    // this quantity should always be positive, the 'isLong' boolean indicator will be used to set the quantity sign later on
    quantity: number = 0;
    // whether to refresh quotes on adding this leg or not.
    // when you add multiple legs at a time, you don't want to refresh quotes for each leg additions, hence this flag
    refreshQuotes = true;
    // if the leg being added should be marked as selected
    selected = false;

}

export enum TradeTab{
    OptionChain, Charts, Fundamentals
}

export class TradeSettings{
    selectedTab: TradeTab = TradeTab.OptionChain;
}

export class TradeAnalysis{

    delta = BNUtil.ZERO;
    theta = BNUtil.ZERO;
    gamma = BNUtil.ZERO;
    vega = BNUtil.ZERO;
    maxProfit = BNUtil.ZERO;
    maxLoss = BNUtil.ZERO;
}

// events that happen within the Trade screen
export type TradeEvents = {
    focusContract: OptionContract;       // this event is meant to scroll the contract in to view in OC
    focusContractWithDelay: OptionContract;
    focusExpiry: OptionChainExpiry;
}

export interface OptionChainColumn{
    key: string;  // column id used in the backend
    headerName: string;  // Name to display in the column header
    fullName: string;  // Name to display in the Column selection menu
    displayProperty : string; // property from OptionContract object that is actually displayed on screen
}

const optionChainColumnsList : OptionChainColumn[] = [
    {
        key: "AskSize",
        headerName: "Ask Sz",
        fullName: "Ask Size",
        displayProperty: "askSizeStr"
    },
    {
        key: "AverageVolume",
        headerName: "Avg Vol",
        fullName: "Average Volume",
        displayProperty: "averageVolumeStr"
    },
    {
        key: "BidSize",
        headerName: "Bid Sz",
        fullName: "Bid Size",
        displayProperty: "bidSizeStr"
    },
    {
        key: "Change",
        headerName: "Change",
        fullName: "Change",
        displayProperty: "changeStr"
    },
    {
        key: "ChangePercent",
        headerName: "Change%",
        fullName: "Change %",
        displayProperty: "changePercentageStr"
    },
    {
        key: "Delta",
        headerName: "Delta",
        fullName: "Delta",
        displayProperty: "deltaStr"
    },
    {
        key: "ExtrinsicValue",
        headerName: "Ext Val.",
        fullName: "Extrinsic Value",
        displayProperty: "extrinsicValueStr"
    },
    {
        key: "Gamma",
        headerName: "Gamma",
        fullName: "Gamma",
        displayProperty: "gammaStr"
    },
    {
        key: "High",
        headerName: "High",
        fullName: "High",
        displayProperty: "highStr"
    },
    {
        key: "ImpliedVolatility",
        headerName: "Impl Vol.",
        fullName: "Implied Volatility",
        displayProperty: "optionImpliedVolatilityStr"
    },
    {
        key: "Last",
        headerName: "Last",
        fullName: "Last",
        displayProperty: "lastStr"
    },
    {
        key: "LastVolume",
        headerName: "Last Vol",
        fullName: "Last Volume",
        displayProperty: "lastVolumeStr"
    },
    {
        key: "Low",
        headerName: "Low",
        fullName: "Low",
        displayProperty: "lowStr"
    },
    {
        key: "Open",
        headerName: "Open",
        fullName: "Open",
        displayProperty: "openStr"
    },
    {
        key: "OpenInterest",
        headerName: "Open Int",
        fullName: "Open Interest",
        displayProperty: "openInterestStr"
    },
    {
        key: "Theta",
        headerName: "Theta",
        fullName: "Theta",
        displayProperty: "thetaStr"
    },
    {
        key: "Vega",
        headerName: "Vega",
        fullName: "Vega",
        displayProperty: "vegaStr"
    },
    {
        key: "Volume",
        headerName: "Volume",
        fullName: "Volume",
        displayProperty: "volumeStr"
    },
];

export class OptionChainColumnsConfig{
    allColumnsList: OptionChainColumn[] = [];
    // this dictionary is by OptionChainColumn.key
    allColumnsDict : {[key: string]: OptionChainColumn} ={};
    // these are the columns that are as per user preferences
    preferredColumns: OptionChainColumn[] = [];
    // total no. of columns in the OptionChain including dynamic columns
    private _columnsLength: number = 0;

    private _preferredColumnsReversed: OptionChainColumn[] = [];

    constructor(){
        this.allColumnsList = _sortBy(optionChainColumnsList, item => item.fullName);
        this.allColumnsList.forEach(optionChainColumn => this.allColumnsDict[optionChainColumn.key] = optionChainColumn);
    }

    get preferredColumnsReversed(): OptionChainColumn[] {
        return this.preferredColumns.slice().reverse();
    }

    get columnsLength(): number {
        //(strike change column + dynamic columns + bid + ask + remove)* 2 + strike column
        return (1 + this.preferredColumns.length + 2 + 1)*2+1;
    }
}

export class OptionChainFilters{
    noOfStrikesList: string[] = ["8", "16", "32", "64", "All"]
    noOfStrikes : string = "All";
}


/**
 * TradeTemplate data shared by multiple components
 */
export class SharedTradeTemplateData{
    searchText: string = "";
    templates: TradeTemplate[] = [];
    selectedTemplate: TradeTemplate | undefined;
}


export class TradeTemplate extends BaseData{

    name: string = "";

    templateLegs: TemplateLeg[] = [];
    // whether to display it in the list for selection (based on search)
    show = true;


}



export class TemplateLeg extends BaseData{
    // 0 based index for legs
    legIndex = 0;
    // number of days to expiry
    daysToExpiry = 0;

    strikeSelectionCriteria: StrikeSelectionCriteria| undefined;

    securityType: SecurityType | undefined;

    securityDirection: SecurityDirection | undefined;

    delta: BigNumber = BNUtil.ZERO;

    // % of the underlying stock Price
    // for an equity at $100, 50% is $50 and 200% is $200 and 100% is $100
    percentOfUnderlying: BigNumber = BNUtil.ZERO;

    quantityStr: string = "";
    quantity: number = 1;

}