import BigNumber from "bignumber.js";


export class Constants {
    // refresh token every one hour
    public static get TokenRefreshTime(): number {
        return 60000;
    }
    public static get DefaultPositionColumns(): string[] {
        return ["tradePricePerUnit", "currentPricePerUnit", "deltaPerUnit", "thetaPerUnit", "gammaPerUnit", "vegaPerUnit",
            "totalDelta", "totalTheta", "totalGamma", "totalVega"];
    }

    public static get DefaultPreviewPaneColumns(): string[] {
        return ["markTotal", "tradePriceHistoricTotal", "riskTotal", "deltaPerUnit", "thetaPerUnit", "gammaPerUnit", "vegaPerUnit", "deltaTotal", "thetaTotal", "gammaTotal", "vegaTotal"];
    }

    public static get DefaultDateFormat(): string{
        return "yyyy-MM-dd";
    }

    public static get ShortDateTimeFormat(): string{
        return "MM/dd hh:mm aaaaa";
    }

    public static get OSIDateFormat(): string {
        return "yyMMdd";
    }

    public static get PositiveColor(): string {
        return "green";
    }

    public static get NegativeColor(): string {
        return "red";
    }

    public static get NeutralColor(): string {
        return "gray";
    }

    public static get DefaultOrderQuantityStock(): number{
        return 100;
    }

    public static get DefaultOrderQuantityOption(): number{
        return 1;
    }

    public static get LongSecurityColor(): string{
        return "red";
    }

    public static get ShortSecurityColor(): string{
        return "green";
    }

    // number of OptionExpiries to keep expanded at a time
    public static get OptionChainOpenExpiriesLimit(): number{
        return 4;
    }

    // For calculating the Rolling Limit Start and Stop prices
    // We use a factor on the Spread to set the start and stop prices
    // So, if the price mid is $5 debit and spread is $1, using a factor of 0.2 would set the starting price to $4.9 and ending price to $5.1
    public static  get RollingLimitSpreadPriceFactor(): BigNumber{
        return new BigNumber(0.5);  // we want to start at 10% less and go over by 10%
    }

    // The total difference between start and stop prices is taken in cents and multiplied by the increment factor to come up with increment in cents
    // So, if the diff between start and stop is 100 cents and the factor is 0.05, the increment would be 5 cents
    public static  get RollingLimitIncrementFactor(): BigNumber{
        return new BigNumber(0.05);
    }

    public static get RollingLimitTimeIncrement(): number{
        return 5;
    }

}

export class ConstantMessages{
    static readonly ProcessingError = "Something went wrong while processing your request";
    static readonly NotFoundError = "Requested resource was not found";
    static readonly IOError = "There was problem connecting to the backend server";
    static readonly UnauthorizedError = "User not authorized to access the resource";
}
