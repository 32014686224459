import { AxiosInstance, AxiosRequestConfig } from "axios";
import Axios from "axios";
import { Auth } from "aws-amplify";
import { appGlobals } from "@/data/AppData";



export const axiosInstance: AxiosInstance = Axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL
});

// use for making requests to public backend services without bearer token
export const axiosPublicInstance: AxiosInstance = Axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL
});

export const genericAxiosInstance: AxiosInstance = Axios.create({
  baseURL: process.env.VUE_APP_BASE_GENERIC_BROKER_URL
});

export const genericQuotesAxiosInstance: AxiosInstance = Axios.create({
  baseURL: process.env.VUE_APP_BASE_GENERIC_QUOTES_BROKER_URL
});

genericAxiosInstance.interceptors.request.use(function(request: AxiosRequestConfig) {
  // set default headers and token
  // request.headers = {
  //   Accept: "application/json",
  //   "Content-Type": "application/json"
  // };
  // request.headers.Authorization = "Bearer " + appGlobals.genericToken;
  return request;
});

axiosInstance.interceptors.request.use(function(request: AxiosRequestConfig) {
  // set default headers and token
  request.headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  request.headers.Authorization = "Bearer " + appGlobals.token;
  return request;
});
