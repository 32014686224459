import './installCompositionApi.js'
import 'reflect-metadata';
import Vue from "vue";
import App from "./App.vue";
import router from "@/router/index";
import vuetify from "./plugins/vuetify";
import awsconfig from "./aws-exports";
// import "./plugins/themebase";
import Amplify, * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import VueCompositionAPI from "@vue/composition-api";
import AppGlobals from "@/data/AppData";
import { appGlobals } from "@/data/AppData";
import BigNumber from "bignumber.js";
import "@/sass/os/main.scss";
// import "@/sass/variables.scss";
import mitt, {Emitter} from "mitt";
import {GlobalEvents} from "@/data/CommonData";
import VModal from 'vue-js-modal'
import VuetifyMask from "vuetify-mask";


Amplify.configure(awsconfig);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.config.productionTip = false;
Vue.prototype.$appGlobals = appGlobals;
Vue.use(VModal);
Vue.use(VuetifyMask);

// setting defaults for BigNumber - Rounding_Mode - Half_Even
BigNumber.set({ DECIMAL_PLACES: 2, ROUNDING_MODE: 6 })
// without this formatted strings have commas
BigNumber.config({FORMAT: {groupSeparator: ",", decimalSeparator: ".", groupSize: 3}})

export const BigNumber3 = BigNumber.clone({ DECIMAL_PLACES: 3, ROUNDING_MODE: 6 })
export const eventEmitter : Emitter<GlobalEvents> = mitt<GlobalEvents>();

// Create an empty instance of Shared data across the app
// var appGlobals: AppGlobals = new AppGlobals()
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
