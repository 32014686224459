import * as AmplifyAuth from "@aws-amplify/auth";
import { SignUpParams } from "@aws-amplify/auth/lib/types/index";
import { SignUpData } from "@/data/AuthData";
import * as Cognito from "amazon-cognito-identity-js";
import { SetupContext } from "@vue/composition-api";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { appGlobals } from "@/data/AppData";
import UserService from "@/services/UserService";

export default class AuthService {
  constructor() {}

  /**
   * Refreshes the session, stores the jwt in globals and returns if user is valid or not
   */
  async refreshSession(): Promise<boolean> {
    try {
      const session: CognitoUserSession = await Auth.currentSession();
      if (session && session.getAccessToken()) {
        console.log(session.getAccessToken().getJwtToken());
        appGlobals.token = session.getAccessToken().getJwtToken();
      }
      return session.isValid();
    } catch (e) {
      // is not valid if we get an error. Cognito throws errors sometimes indicating an invalid session
      console.log(e);
      return false;
    }
  }
}

export const authService = new AuthService();
