























import {
  computed,
  defineComponent,
  reactive,
  SetupContext
} from "@vue/composition-api";

export default defineComponent({
  name: "HomeView",
  setup(props: undefined, context: SetupContext) {
    const state: { srcs: { [key: string]: string } } = reactive({
      srcs: {
        "/pages/lock": "lock.jpg",
        "/pages/login": "login.jpg",
        "/pages/pricing": "pricing.jpg",
        "/pages/register": "register.jpg"
      }
    });
    const src = computed(() => state.srcs[context.root.$route.path]);
    const styles = computed(() => {
      const paddingTop = context.root.$vuetify.breakpoint.mdAndUp ? 120 : 100;
      const paddingBottom = context.root.$vuetify.breakpoint.mdAndUp
        ? 120
        : 150;
      return {
        padding: `${paddingTop}px 0 ${paddingBottom}px 0`,
        display: "flex",
        'justify-content': "center",
        'min-height': "100vh"
      };
    });

    return {
      src,
      styles
    };
  }
});
