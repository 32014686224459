import {BrokerAccountService} from "@/services/broker/BrokerAccountService";
import {PositionColumn, PositionItem} from "@/data/PositionsData";
import {genericAxiosInstance} from "@/util/AxiosUtil";
import {AxiosResponse} from "axios";
import {
    GenericAccountBalances,
    GenericAccountProfile,
    GenericPosition
} from "@/data/generic/GenericAccountData";
import _map from "lodash/map";
import {appGlobals, BrokerConfig} from "@/data/AppData";
import {BrokerUser, UserAccount} from "@/data/UserData";
import {AccountIconIndexMapping, Broker, PositionItemType, SecurityType} from "@/data/EnumData";
import DateUtil, {BNUtil, SecurityUtil} from "@/util/Util";
import {AccountBalances, AccountProfile, BrokerAccount} from "@/data/AccountData";
import {GenericBaseService} from "@/services/generic/GenericBaseService";
import {Quotable} from "@/data/QuoteData";

export class GenericAccountService  extends GenericBaseService implements BrokerAccountService {

    getConfig(): BrokerConfig {
        return {
            minimumDelay: 10000,
            delayPerAccount: 5000,
            quotesDelay: 10000
        }
    }

    /**
     * Iterates through all the brokerAccounts for the brokerUser and populates the account data from broker
     * @param brokerUser
     * @param brokerAccounts
     */
    async updateAccounts(brokerUser: BrokerUser, brokerAccounts : BrokerAccount[]) : Promise<void>{
        const response: AxiosResponse = await genericAxiosInstance.get<any>("/user/profile", this.getAxiosConfig(brokerUser));
        const genericAccountProfileList = response.data.profile.account as GenericAccountProfile[];
        // create an accountProfile map with accountNumber as key
        const genericAccountProfileDict : {[key: string]: GenericAccountProfile} ={};
        genericAccountProfileList.forEach(accountProfile => genericAccountProfileDict[accountProfile.account_number] = accountProfile);
        const accountPromises: Promise<void>[] = [];
        for(let brokerAccount of brokerAccounts){
            accountPromises.push(this.updateIndividualAccount(brokerUser, brokerAccount, genericAccountProfileDict));
        }
        // wait for all brokerAccounts to be updated
        await Promise.all(accountPromises);
    }

    /**
     * Updates individual broker account data by invoking the API
     * @param brokerUser
     * @param brokerAccount
     * @param genericAccountProfileDict
     * @private
     */
    private async updateIndividualAccount(brokerUser: BrokerUser, brokerAccount: BrokerAccount, genericAccountProfileDict : {[key: string]: GenericAccountProfile}){
        const response: AxiosResponse = await genericAxiosInstance.get<any>(`/accounts/${brokerAccount.accountNumber}/balances`, this.getAxiosConfig(brokerUser));
        const genericAccountBalances: GenericAccountBalances = response.data.balances  as GenericAccountBalances;

        // populate accountProfile from GenericAccountProfile
        const accountProfile: AccountProfile = brokerAccount.accountProfile;
        this.fillAccountProfile(genericAccountProfileDict[genericAccountBalances.account_number], accountProfile);

        const accountBalances: AccountBalances = brokerAccount.accountBalances
        // populate accountBalances from GenericAccountBalance
        this.fillAccountBalances(genericAccountBalances, accountBalances)
    }

    private fillAccountProfile(genericAccountProfile: GenericAccountProfile, accountProfile: AccountProfile){
        // TOdO
    }

    private fillAccountBalances(genericAccountBalances: GenericAccountBalances, accountBalances: AccountBalances){
        // TOdO
        accountBalances.accountType = genericAccountBalances.account_type;
        accountBalances.totalEquity = genericAccountBalances.total_equity;
        accountBalances.optionBuyingPower = genericAccountBalances.option_buying_power;
        accountBalances.stockBuyingPower = genericAccountBalances.stock_buying_power;
        accountBalances.openPl = genericAccountBalances.open_pl;
        accountBalances.totalCash = genericAccountBalances.total_cash;
        // TODO need to fix this
        //accountBalances.optionRequirement = genericAccountBalances.option_requirement;
        accountBalances.stockLongValue = genericAccountBalances.stock_long_value;
        accountBalances.optionLongValue = genericAccountBalances.option_long_value;
        accountBalances.stockShortValue = genericAccountBalances.stock_short_value;
        accountBalances.optionShortValue = genericAccountBalances.option_short_value;
        accountBalances.fedCall = genericAccountBalances.fed_call
        accountBalances.maintenanceCall = genericAccountBalances.maintenance_call;
        accountBalances.unsettledFunds = genericAccountBalances.unsettled_funds;
        accountBalances.pendingCash = genericAccountBalances.pending_cash;
    }

    async getPositions(brokerUser: BrokerUser) : Promise<PositionItem[]>{
        const positionPromises : Promise<PositionItem[]>[] = []
        for(let userAccount of brokerUser.userAccounts){
            // retrieve each account positions in parallel
            positionPromises.push(this.getPositionsByAccount(userAccount, brokerUser));
        }
        const positionsArrayArray: PositionItem[][]  = await Promise.all(positionPromises)
        return Array.prototype.concat(...positionsArrayArray);
    }

    private async getPositionsByAccount(userAccount: UserAccount, brokerUser: BrokerUser) :  Promise<PositionItem[]>{
        const response: AxiosResponse = await genericAxiosInstance.get<any>(`/accounts/${userAccount.accountNumber}/positions`, this.getAxiosConfig(brokerUser));
        const genericPositions: GenericPosition[] = response.data.positions.position as GenericPosition[]
        return _map(genericPositions, genericPosition => {
            const position = new PositionItem();
            position.type = PositionItemType.Position;
            position.brokerPositionId = genericPosition.id;
            position.symbol = genericPosition.symbol;
            position.openDate = new Date(genericPosition.date_acquired);
            // divide by quantity and negate
            position.tradePricePerUnit = BNUtil.div(genericPosition.cost_basis, Math.abs(genericPosition.quantity)).negated();
            if (genericPosition.symbol.length > 8){
                // it's an option symbol
                // For this broker, both osiSymbol and brokerSymbol are the same
                const quotable = SecurityUtil.getQuotable(genericPosition.symbol, genericPosition.symbol);
                position.securityType = quotable.securityType;
                position.underlying = quotable.underlying;
                position.quotable = quotable;
                position.expiryDate = quotable.expiryDate;
                position.strikePrice = quotable.strikePrice;
                // divide by 100 for options
                position.tradePricePerUnit = position.tradePricePerUnit.dividedBy(100);
            }else{
                position.underlying = genericPosition.symbol;
                position.quotable = Quotable.getStockQuotable(position.underlying);
                position.securityType = SecurityType.Stock;
            }
            position.userAccount = userAccount;
            position.iconColor = AccountIconIndexMapping[position.userAccount.iconIndex]
            position.brokerUser = brokerUser;
            position.quantity = genericPosition.quantity;

            return position;
        })
    }

}

export const genericAccountService = new GenericAccountService();

