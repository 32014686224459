import axios, {AxiosResponse} from "axios";
import {axiosInstance} from "@/util/AxiosUtil";
import {classToPlain, plainToClass} from 'class-transformer';
import {appGlobals} from "@/data/AppData";
import {BrokerUser, User, UserAccount, UserBrokerData, UserPreferences} from "@/data/UserData";
import {BrokerFactory} from "@/services/broker/BrokerFactory";
import {Quotable} from "@/data/QuoteData";
import {quoteService} from "@/services/QuoteService";
import _groupBy from "lodash/groupBy";
import _uniq from "lodash/uniq";
import _flatMap from "lodash/flatMap";
import {PositionItem, PositionTag} from "@/data/PositionsData";
import {VuetifySelection} from "@/data/CommonData";
import {eventEmitter} from "@/main";


export default class PositionService {
    constructor() {

    }

    getCachedPositions(): PositionItem[]{
        return _flatMap(Object.values(appGlobals.userBrokerDataMap), brokerDataMap => brokerDataMap.positions)
    }

    async loadPositions(): Promise<PositionItem[]>{
        const allPromises: Promise<PositionItem[]>[] = [];
        // retrieve all user accounts
        const brokerUsers = appGlobals.user!.brokerUsers;
        for(let brokerUser of brokerUsers){
            // let each brokerUser get their positions in parallel, so don't await
            allPromises.push(this.refreshPositions(brokerUser));
        }

        const positionItems: PositionItem[][] = await Promise.all(allPromises);
        // update tags on positions
        await this.getPositionTagsAndMerge();
        return Array.prototype.concat(...positionItems);
    }

    async refreshPositions(brokerUser: BrokerUser) : Promise<PositionItem[]>{
        const brokerAccountService = BrokerFactory.getAccountService(brokerUser.broker!);
        let _this = this;
        // we should also get quotes
        let positions: PositionItem[] = []
        try{
            positions = await brokerAccountService.getPositions(brokerUser);
        }catch (e) {
            console.log(e.stack);
            eventEmitter.emit("showError", ["Error in loading positions for user profile - "+brokerUser.brokerUserProfileName]);
        }

        let userBrokerData = appGlobals.userBrokerDataMap[brokerUser.brokerUserId];
        let cachedPositions = userBrokerData.positions
        if (cachedPositions.length == 0){
            // update the cached positions
            cachedPositions.splice(0, cachedPositions.length, ...positions);
        }else{
            // compare new positions to cached positions
            if (positions.length != cachedPositions.length){
                // so, positions have been updated
            }else{
                // compare individually
            }
        }

        if(cachedPositions.length > 0){
            // build quotables
            this.updatePositionQuotables(brokerUser, positions);

            // refresh quotables with latest quotes
            await quoteService.refreshQuotables(brokerUser);
        }

        return positions;

    }

    updatePositionQuotables(brokerUser: BrokerUser, positions: PositionItem[]){
        // build quotables and add
        const quotables: Quotable[] = [];
        for(let position of positions){
            // the quotables are already set when the Position is built by broker
            quotables.push(position.quotable!);
        }
        const positionQuotables = appGlobals.userBrokerDataMap[brokerUser.brokerUserId].quotableGroups.positions;
        positionQuotables.splice(0, positionQuotables.length, ...quotables);
    }

    async getPositionTagsAndMerge(){
        const response: AxiosResponse = await axiosInstance.get<any>(
            "/users/positions/tags"
        );
        const data: any[] = response.data
        const allPositionTags: PositionTag[] = plainToClass(PositionTag, data);
        this.mergeTagsWithPositions(allPositionTags);
    }

    mergeTagsWithPositions(allPositionTags: PositionTag[]){
        // group tags by accountId_positionId
        // dictionary of <accountId_positionId,PositionTag[]>
        const groupedPositionTags = _groupBy(allPositionTags, item => item.userAccountId+"_"+item.positionId);

        // merge positionTags with Positions
        for(let userBrokerData of Object.values(appGlobals.userBrokerDataMap)){
            userBrokerData.positions.forEach(position => {
                position.tags.splice(0,position.tags.length);
                const accountPositionTags = groupedPositionTags[ position.userAccount!.id +"_"+ position.brokerPositionId]
                if(accountPositionTags){
                    position.tags = accountPositionTags.map(item => item.tag);
                }
            })
        }

        // also store all the tags as a flat list in appGlobals
        appGlobals.allPositionTags = _uniq(allPositionTags.map(positionTag => positionTag.tag)).sort();
        console.log("Sorted and unique tags "+appGlobals.allPositionTags);
    }

    async updatePositionTagsAndMerge(selectedPositions: PositionItem[], selectedTags: any[] ){
        interface UpdateTagsPayload{
            positions: {[key: string]: any}[];
            tagsToAdd: string[];
            tagsToKeep: string[]
        }

        let payload: UpdateTagsPayload = {
            positions: [],
            tagsToAdd: [],
            tagsToKeep: []
        }

        selectedPositions.forEach(positionItem => {
            payload.positions.push({
                "userAccountId": positionItem.userAccount!.id,
                "symbol": positionItem.symbol,
                "positionId": positionItem.brokerPositionId
            })
        })

        selectedTags.forEach(tag => {
            console.log("Is instance of VSelection - "+ (tag instanceof VuetifySelection));
            if (tag instanceof VuetifySelection) {
                let tagSelection : VuetifySelection = tag as VuetifySelection;
                tagSelection.indeterminate ? payload.tagsToKeep.push(tag.value) : payload.tagsToAdd.push(tag.value);
            } else{
                payload.tagsToAdd.push(tag as string);
            }

        })

        const response: AxiosResponse = await axiosInstance.put<any>(
            "/users/positions/tags", payload
        );
        const data: any[] = response.data
        console.log("response: "+response.data);
        const allPositionTags: PositionTag[] = plainToClass(PositionTag, data);
        this.mergeTagsWithPositions(allPositionTags);
    }

}


export const positionService = new PositionService();