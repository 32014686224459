/**
 *
 */
import {UserAccount} from "@/data/UserData";
import {BNUtil} from "@/util/Util";

export class BrokerAccount{
    accountNumber: string = "";
    userAccount: UserAccount = new UserAccount();
    accountProfile: AccountProfile = new AccountProfile();
    accountBalances: AccountBalances = new AccountBalances();
}

/**
 *
 */
export class AccountProfile{



}

/**
 *
 */
export class AccountBalances{
    accountType: string = "";
    totalEquity: number = 0;
    optionBuyingPower: number = 0;
    stockBuyingPower: number = 0;
    openPl: number = 0;
    totalCash: number = 0;
    optionRequirement: string = "";
    stockLongValue: number = 0;
    optionLongValue: number = 0;
    stockShortValue: number = 0;
    optionShortValue: number = 0;
    fedCall: number = 0;
    maintenanceCall: number = 0;
    unsettledFunds: number = 0;
    pendingCash: number = 0;
    delta: number = 0;
    theta: number = 0;
    netLiquidity: number = 0;
    pnlDay: number = 0;
}