



























import MainFooter from "./Footer.vue";
import MainAppBar from "./AppBar.vue";
import MainNavigationBar from "@/views/main/NavigationBar.vue";
import {defineComponent, ref, SetupContext} from "@vue/composition-api";
import StockWatchLists from "@/views/watchlists/StockWatchlists.vue";
import {eventEmitter} from "@/main";

export default defineComponent({
  name: "MainView",
  components: {
    // MainNavBar,
    MainNavigationBar,
    MainAppBar,
    MainFooter,
    'StockWatchLists': StockWatchLists
  },

  setup(props: undefined, context: SetupContext) {
    console.log("In Main");
    const showWatchlists = ref(true);
    eventEmitter.on("toggleWatchlists", function(){
      showWatchlists.value = !showWatchlists.value;
    })

    function closeWatchlists(){
      showWatchlists.value = false;
    }

    return {
      showWatchlists,
      closeWatchlists
    }
  }
});


