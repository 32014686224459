import {Enumify} from "enumify";
import {Order} from "@/data/OrdersData";

export enum PositionItemType{
    SymbolPosition = "SymbolPosition",  // item grouped at symbol level
    Position = "Position",   // represents individual item without any grouping
    PositionsHeader = "PositionsHeader" // represents the grand totals that show on top of positions grid
}

export enum PositionGrouping{
    None="None", Symbol="Symbol", Strategy="Strategy"
}

export enum SecurityTypeFilter{
    Stocks="stocks", Options="options"
}

export enum TransactionType{
    Open = "Open",
    Close = "Close"
}

export class BaseEnum extends Enumify{

    readonly label: string;
    protected constructor(label: string) {
        super();
        this.label = label;
    }
    toString(): string {
        return this.label;
    }

    /*
    static enumValueOf<T extends Enumify>(str: string) : T {
        return super.enumValueOf(str) as T;
    }*/
}

export class SecurityType extends BaseEnum{
    static Put = new SecurityType("Put", "P", true, false);
    static Call = new SecurityType("Call", "C", true, false);
    static Stock = new SecurityType("Stock", "Shares", false, true);
    static _ = SecurityType.closeEnum()
    readonly shortLabel: string;
    readonly isStock: boolean;
    readonly isOption: boolean;
    readonly isCall: boolean;
    readonly isPut: boolean;
    protected constructor(label: string, shortLabel: string, isOption: boolean, isStock: boolean){
        super(label);
        this.shortLabel = shortLabel;
        this.isOption = isOption;
        (this.shortLabel == 'C')? (this.isCall = true, this.isPut = false): (this.isCall=false, this.isPut= true);
        this.isStock = isStock;
         
    }
    static getEnum(enumKey: string) : SecurityType{
        return this.enumValueOf(enumKey) as SecurityType;
    }
    static getEnumByShorKey(enumChar: string): SecurityType{
        enumChar = enumChar.toLowerCase();
        return enumChar === "c" ? this.Call : enumChar == "p" ? this.Put : this.Stock;
    }


}

export class SecurityDirection extends BaseEnum{
    static Long: SecurityDirection = new SecurityDirection("Long", "L",true, false);
    static Short = new SecurityDirection("Short", "S", false, true);
    readonly shortLabel: string;
    isShort: boolean;
    isLong: boolean;

    static _ = SecurityDirection.closeEnum()
    protected constructor(label: string, shortLabel: string, isLong: boolean, isShort: boolean){
        super(label);
        this.shortLabel = shortLabel;
        this.isShort = isShort;
        this.isLong = isLong;
    }
    static getEnum(enumKey: string) : SecurityDirection{
        return this.enumValueOf(enumKey) as SecurityDirection;
    }
}

export class TradeSide extends BaseEnum{
    static BTO = new TradeSide("BTO", SecurityDirection.Long, TransactionType.Open, "green");
    static STO = new TradeSide("STO", SecurityDirection.Short, TransactionType.Open, "red");
    static BTC = new TradeSide("BTC", SecurityDirection.Long, TransactionType.Close, "green");
    static STC = new TradeSide("STC", SecurityDirection.Short, TransactionType.Close, "red");

    static _ = TradeSide.closeEnum();
    readonly color: string;
    readonly securityDirection: SecurityDirection;
    readonly transactionType: TransactionType;

    protected constructor(label: string, securityDirection: SecurityDirection, transactionType: TransactionType, color: string){
        super(label);
        this.securityDirection = securityDirection;
        this.transactionType = transactionType;
        this.color = color;
    }

    static buildTradeSide(securityDirection: SecurityDirection, transactionType: TransactionType) : TradeSide{
        if (securityDirection == SecurityDirection.Long){
            return transactionType == TransactionType.Open ? this.BTO : this.BTC;
        }else{
            return transactionType == TransactionType.Open ? this.STO : this.STC;
        }
    }
}

/**
 * Enum that maps UserAccount.iconIndex to a color
 * To get a color - AccountIconIndex[userAccount.iconIndex]
 */
export enum AccountIconIndexMapping{
    red = 0,
    blue = 1,
    green = 2,
}


export enum TableColumnType{
    Numeric = "Numeric",
    String = "String",
    Money = "Money",
    ProfitLoss = "ProfitLoss"
}

// used in sorting of positions
export enum TableColumnDatatype{
    BigNumber = "BigNumber", // decimals
    Number =  "Number", //DTE, quantity 
    String = "String",
    Date = "Date"
}

export enum Broker{
    General = "General",
    TD = "TD",
    Generic = "Generic",
    TW = "TW"
}

export enum OptionChainItemType{
    Expiry= "Expiry",
    OptionPair = "OptionPair"
}

export class OrderType extends BaseEnum{
    static Market = new OrderType("Market", "MKT", true, false);
    static Limit = new OrderType("Limit", "LMT", true, false);
    static Stop = new OrderType("Stop Market", "STP", true, false);
    static StopLimit = new OrderType("Stop Limit", "STP LMT", true, false);
    static NetDebit = new OrderType("Net Debit","NET DBT", false, true);
    static NetCredit = new OrderType("Net Credit","NET CRT", false, true);
    static RollingLimit = new OrderType("Rolling Limit","ROLL LMT", true, true);

    static _ = OrderType.closeEnum();
    readonly shortLabel: string;
    readonly applicableForSingleLeg: boolean;
    readonly applicableForMultiLeg: boolean;

    protected constructor(label: string, shortLabel: string, applicableForSingleLeg: boolean, applicableForMultiLeg: boolean){
        super(label);
        this.shortLabel = shortLabel;
        this.applicableForSingleLeg = applicableForSingleLeg;
        this.applicableForMultiLeg = applicableForMultiLeg;
    }

    static getEnum(enumKey: string) : OrderType{
        return this.enumValueOf(enumKey) as OrderType;
    }
}

export class OrderDuration extends BaseEnum{
    static Day = new OrderDuration("Day", true);
    static GTC = new OrderDuration("GTC", true);
    static Pre = new OrderDuration("Pre", false);
    static Post = new OrderDuration("Post", false);
    static _ = OrderDuration.closeEnum();
    readonly applicableForOptions: boolean;
    protected constructor(label: string, applicableForOptions: boolean){
        super(label);
        this.applicableForOptions = applicableForOptions;
    }
    static getEnum(enumKey: string) : OrderDuration{
        return this.enumValueOf(enumKey) as OrderDuration;
    }
}

export class OrderStatus extends BaseEnum{
    static Open = new OrderStatus("Open", true, true, "blue", false);
    static Filled = new OrderStatus("Filled", false, false, "green", false);
    static PartiallyFilled = new OrderStatus("Partially Filled", false, true, "green", true);
    static Expired = new OrderStatus("Expired", false, false, "gray", true);
    static Canceled = new OrderStatus("Canceled", false, false, "red", true);
    static Pending = new OrderStatus("Pending", true, true, "gray", false);
    static Rejected = new OrderStatus("Rejected", false, false, "red", false);
    static Error = new OrderStatus("Error", false, false, "red", false);

    static _ = OrderStatus.closeEnum();
    readonly editable: boolean;
    readonly cancellable: boolean;
    readonly color: string;
    readonly canHaveSubStatus: boolean;  // indicates if a particular Status can have a sub-status. For example, cancelled but partially-filled
    protected constructor(label: string, editable: boolean, cancellable: boolean, color: string, canHaveSubStatus: boolean){
        super(label);
        this.editable = editable;
        this.cancellable = cancellable;
        this.color = color;
        this.canHaveSubStatus = canHaveSubStatus;
    }
    static getEnum(enumKey: string) : OrderStatus{
        return this.enumValueOf(enumKey) as OrderStatus;
    }
}

export class OrderClass extends BaseEnum{
    static Equity = new OrderClass("Equity", false);
    static Option = new OrderClass("Option", false);
    static MultiLeg = new OrderClass("Multi Leg", true);
    static Combo = new OrderClass("Combo", true);
    static None = new OrderClass("None", false);

    static _ = OrderClass.closeEnum();
    readonly hasTwoOrMoreLegs: boolean = false;
    protected constructor(label: string, hasTwoOrMoreLegs: boolean){
        super(label);
        this.hasTwoOrMoreLegs = hasTwoOrMoreLegs;
    }
    static getEnum(enumKey: string) : OrderClass{
        return this.enumValueOf(enumKey) as OrderClass;
    }
}

export class PriceEffect extends BaseEnum{
    static Debit = new PriceEffect("Db", false, "red--text", );
    static Credit = new PriceEffect("Cr", true, "green--text");
    static None = new PriceEffect("", undefined,"");    // defaulted to this value when no value

    readonly increase: boolean | undefined; // true for credit, false for debit - boolean easy to use in template
    readonly displayColor: string;
    static _ = PriceEffect.closeEnum();
    protected constructor(label: string, increase: boolean | undefined, displayColor: string){
        super(label);
        this.increase = increase;
        this.displayColor = displayColor;
    }
    static getEnum(enumKey: string) : PriceEffect{
        return this.enumValueOf(enumKey) as PriceEffect;
    }
}

export class TradeMode extends BaseEnum{
    static New = new TradeMode("New", false,false, false, false, true);
    static Edit = new TradeMode("Edit",false, true, false, true, false);
    static Preview = new TradeMode("Preview", true,true, true, true, true);
    static EditPreview = new TradeMode("EditPreview", true,true, true, true, false);
    // when you start a trade with Closing legs (this mode is set), the trade could have other new opening legs too
    // Rest of the statuses (Edit, Preview and EditPreview) are applicable to both 'Close' and 'New' modes
    static Close = new TradeMode("Close", false,false, false, true,false);

    readonly isPreviewMode :boolean;
    readonly disableLegChanges: boolean;    // legs can't be added, removed, quantities, strikes etc., can't be updated
    readonly disablePriceProperties: boolean;   // properties like Price, StopPrice, OrderType and Duration
    readonly disableAccountChange: boolean;
    readonly isCacheable: boolean;    // indicates if TradeData in the current mode can be cached
    static _ = TradeMode.closeEnum();
    protected constructor(label: string, isPreviewMode:boolean, disableLegChanges: boolean,
                          disablePriceProperties: boolean, disableAccountChange: boolean, isCacheable: boolean){
        super(label);
        this.isPreviewMode = isPreviewMode;
        this.disableLegChanges = disableLegChanges;
        this.disablePriceProperties = disablePriceProperties;
        this.disableAccountChange = disableAccountChange;
        this.isCacheable = isCacheable;
    }
    static getEnum(enumKey: string) : TradeMode{
        return this.enumValueOf(enumKey) as TradeMode;
    }
}

export enum SymbolItemType{
    PositionItem = "positionItem",
    OrderLeg = "orderLeg"
}

export enum AsyncStatus{
    Ok="Ok",
    Error="Error",
    Partial="Partial"
}

export class StrikeSelectionCriteria extends BaseEnum{
    static Delta = new StrikeSelectionCriteria("Delta");
    static PercentOfUnderlying = new StrikeSelectionCriteria("% Underlying");

    static _ = StrikeSelectionCriteria.closeEnum();
    protected constructor(label: string){
        super(label);
    }
    static getEnum(enumKey: string) : StrikeSelectionCriteria{
        return this.enumValueOf(enumKey) as StrikeSelectionCriteria;
    }
}

export class PriceAdjustmentSide extends BaseEnum{
    static Increment = new PriceAdjustmentSide("Increment");
    static Decrement = new PriceAdjustmentSide("Decrement");

    static _ = PriceAdjustmentSide.closeEnum();
    protected constructor(label: string){
        super(label);
    }
    static getEnum(enumKey: string) : PriceAdjustmentSide{
        return this.enumValueOf(enumKey) as PriceAdjustmentSide;
    }
}

export class RollingOrderStatus extends BaseEnum{
    static Working = new RollingOrderStatus("Working");
    static Paused = new RollingOrderStatus("Paused");
    static Finished = new RollingOrderStatus("Finished");
    static Stopped = new RollingOrderStatus("Stopped");
    static Error = new RollingOrderStatus("Error");
    static UserStopped = new RollingOrderStatus("Canceled by User");

    static _ = RollingOrderStatus.closeEnum();
    protected constructor(label: string){
        super(label);
    }
    static getEnum(enumKey: string) : RollingOrderStatus{
        return this.enumValueOf(enumKey) as RollingOrderStatus;
    }
}

