































































import {defineComponent, reactive, ref, SetupContext, watch} from "@vue/composition-api";
import {tradeService} from "@/services/TradeService";

interface Props{
  componentHeight: number,
}
export default defineComponent({
  name: "TradeControls",
  props: {
    componentHeight: Number
  },
  components: {
  },
  setup(props: undefined, context: SetupContext) {

    const tradeData = tradeService.tradeData;

    function moveLegsToNextStrike(step: number){
      tradeService.moveLegsToNextStrike(step);
    }

    function moveLegsToAdjacentExpiry(step: number){
      tradeService.moveLegsToAdjacentExpiry(step);
    }

    function resetOrder(){
      tradeService.resetOrder();
    }

    function adjustQuantity(change: number){
      tradeService.adjustQuantity(change);
    }

    return {
      tradeData,
      moveLegsToNextStrike,
      moveLegsToAdjacentExpiry,
      adjustQuantity,
      resetOrder
    }
  }
})


