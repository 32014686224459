import {BaseService} from "@/services/CommonService";
import TradeService from "@/services/TradeService";
import {SharedTradeTemplateData, TemplateLeg, TradeTemplate} from "@/data/TradeData";
import {reactive} from "@vue/composition-api";
import {SecurityDirection, SecurityType, StrikeSelectionCriteria} from "@/data/EnumData";
import {BNUtil} from "@/util/Util";


export default class TradeTemplateService extends BaseService{

    tradeTemplateData = reactive(new SharedTradeTemplateData());


    async loadTemplates()  {
        let templates : TradeTemplate[] = [];
        let template = new TradeTemplate();
        template.name = "Put Credit Spread";
        template.id = 1;
        let leg1 = new TemplateLeg();
        leg1.id = 1;
        leg1.legIndex = 0;
        leg1.daysToExpiry = 30;
        leg1.strikeSelectionCriteria = StrikeSelectionCriteria.Delta;
        leg1.delta = BNUtil.of("30");
        leg1.securityType = SecurityType.Put;
        leg1.securityDirection = SecurityDirection.Long;
        leg1.quantity = 1;

        let leg2 = new TemplateLeg();
        leg2.legIndex = 1;
        leg2.id = 2;
        leg2.daysToExpiry = 30;
        leg2.strikeSelectionCriteria = StrikeSelectionCriteria.Delta;
        leg2.delta = BNUtil.of("50");
        leg2.securityType = SecurityType.Put;
        leg2.securityDirection = SecurityDirection.Short;
        leg2.quantity = 1;

        template.templateLegs.push(leg1);
        template.templateLegs.push(leg2);
        templates.push(template);

        template = new TradeTemplate();
        template.id = 2;
        template.name = "Call Calendar";
        leg1 = new TemplateLeg();
        leg1.id = 3;
        leg1.legIndex = 0;
        leg1.daysToExpiry = 30;
        leg1.strikeSelectionCriteria = StrikeSelectionCriteria.Delta;
        leg1.delta = BNUtil.of("50");
        leg1.securityType = SecurityType.Call;
        leg1.securityDirection = SecurityDirection.Short;
        leg1.quantity = 1;

        leg2 = new TemplateLeg();
        leg2.id = 4;
        leg2.legIndex = 1;
        leg2.daysToExpiry = 60;
        leg2.strikeSelectionCriteria = StrikeSelectionCriteria.Delta;
        leg2.delta = BNUtil.of("50");
        leg2.securityType = SecurityType.Call;
        leg2.securityDirection = SecurityDirection.Long;
        leg2.quantity = 1;

        template.templateLegs.push(leg1);
        template.templateLegs.push(leg2);
        templates.push(template);

        template = new TradeTemplate();
        template.id = 3;
        template.name = "Long Straddle";
        leg1 = new TemplateLeg();
        leg1.id = 5;
        leg1.legIndex = 0;
        leg1.daysToExpiry = 45;
        leg1.strikeSelectionCriteria = StrikeSelectionCriteria.PercentOfUnderlying;
        leg1.percentOfUnderlying = BNUtil.HUNDRED;
        leg1.securityType = SecurityType.Call;
        leg1.securityDirection = SecurityDirection.Long;
        leg1.quantity = 2;

        leg2 = new TemplateLeg();
        leg2.id = 6;
        leg2.legIndex = 1;
        leg2.daysToExpiry = 45;
        leg2.strikeSelectionCriteria = StrikeSelectionCriteria.PercentOfUnderlying;
        leg2.percentOfUnderlying = BNUtil.HUNDRED;
        leg2.securityType = SecurityType.Put;
        leg2.securityDirection = SecurityDirection.Long;
        leg2.quantity = 2;

        template.templateLegs.push(leg1);
        template.templateLegs.push(leg2);
        templates.push(template);

        template = new TradeTemplate();
        template.id = 4;
        template.name = "Covered Call";
        leg1 = new TemplateLeg();
        leg1.id = 7;
        leg1.legIndex = 0;
        leg1.securityType = SecurityType.Stock;
        leg1.securityDirection = SecurityDirection.Long;
        leg1.quantity = 200;

        leg2 = new TemplateLeg();
        leg2.id = 8;
        leg2.legIndex = 1;
        leg2.daysToExpiry = 30;
        leg2.strikeSelectionCriteria = StrikeSelectionCriteria.PercentOfUnderlying;
        leg2.percentOfUnderlying = BNUtil.of(115);
        leg2.securityType = SecurityType.Call;
        leg2.securityDirection = SecurityDirection.Short;

        template.templateLegs.push(leg1);
        template.templateLegs.push(leg2);
        templates.push(template);

        this.tradeTemplateData.templates.splice(0, 0, ...templates);
    }



}


export const tradeTemplateService = new TradeTemplateService();