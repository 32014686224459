import {AxiosResponse} from "axios";
import {axiosInstance} from "@/util/AxiosUtil";
import {StockWatchlist} from "@/data/WatchlistData";
import {appGlobals} from "@/data/AppData";
import {quoteService} from "@/services/QuoteService";
import {plainToClass} from "class-transformer";
import _toString from "lodash/toString";


export default class WatchlistService {
    constructor() {

    }

    /**
     * Loads available watchlists with symbols and quotes
     */
    async loadStockWatchlists(): Promise<StockWatchlist[]>{
        const response: AxiosResponse = await axiosInstance.get<any>(`/watchlists/`);
        const data: any[] = response.data
        const stockWatchlists: StockWatchlist[] =  plainToClass(StockWatchlist, data);
        return stockWatchlists;
    }

    /**
     * Loads/refreshes quotes for symbols in one watchlist
     * @param stockWatchlist
     */
    async loadWatchlistQuotes(stockWatchList:StockWatchlist): Promise<void>{
        // refer to the same Quotables[] in quotableGroups and stockWatchlist
        const brokerUser = appGlobals.selectedBrokerUser;
        appGlobals.userBrokerDataMap[brokerUser.brokerUserId].quotableGroups.watchlist = stockWatchList.quotables;
        await quoteService.refreshQuotables(brokerUser);
    }

    /**
     * Updates watchlist
     * @param stockWatchlist
     */
    async updateStockWatchlist(stockWatchlist:StockWatchlist): Promise<void>{
        //stockWatchlist.symbols is not set at this point, get symbols from quotables
        const json = JSON.stringify({id:stockWatchlist.id,name:stockWatchlist.name, symbols:_toString(stockWatchlist.quotables.map(quotable => quotable.symbol))});
        const response: AxiosResponse = await axiosInstance.put<any>(`/watchlists/`+stockWatchlist.id, json);
        const data: any[] = response.data;
    }

    async deleteWatchlist(stockWatchlist:StockWatchlist): Promise<void>{
        const response: AxiosResponse = await axiosInstance.delete<any>(`/watchlists/`+stockWatchlist.id);
    }
}

export const watchlistService = new WatchlistService();