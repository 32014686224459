














































import {defineComponent, onMounted, reactive, ref, SetupContext, watch, computed} from "@vue/composition-api";
import {PositionColumn, positionColumnsConfig, PositionItem} from "@/data/PositionsData";
import {SettingsColumnConfig, UserPreferences} from "@/data/UserData";
import {appGlobals} from "@/data/AppData";
import {eventEmitter} from "@/main";
import _difference from "lodash/difference";
import {userService} from "@/services/UserService";
import {VuetifySelection} from "@/data/CommonData";
import {positionService} from "@/services/PositionService";

interface Props{
  showAddTags: boolean,
  selectedPositions: PositionItem[]
}

export default defineComponent({
  name: "PositionTags",
  props: {
    showAddTags: Boolean,
    selectedPositions: Array,
  },
  setup(props: Props, context: SetupContext) {
    let allTags:VuetifySelection[] = reactive([]);
    let checkedTags = ref<VuetifySelection[]>([]);

    function updateItemState(item:any, something: any){
      // if an indeterminate item is clicked, that means user has made a selection, so make it a normal checkbox
      if(item.indeterminate){
        item.indeterminate = false;
      }
    }

    function save(){
      // let finalCols =props.selectedCols.map(col => col.key);
      // context.emit("input", false, finalCols);
      // console.log("checked tags - "+checkedTags.value.map(item => item.value));
      // console.log("checked tags indeterminate - "+checkedTags.value.map(item => item.indeterminate));
      console.log("flat tags - "+flatCheckedTags.value);
      let positionColumnsReactive = reactive([]); // for data
      positionService.updatePositionTagsAndMerge(props.selectedPositions,checkedTags.value)
        .then(Void => {
          context.emit("save");
        }).catch(reason => {
          console.log(reason);
          eventEmitter.emit("showError", ["Error in saving tags"]);
        }
      )
      console.log("saved");

    }

    // this event is called
    function cancelDialog(){
      context.emit("close");
    }

    function closeDialog(){
      context.emit("close");
    }

    watch(() => props.showAddTags, (value) => {
      if (value){
        startTagsDialog();
      }else{
        console.log("hiding the modal");
        context.root.$modal.hide("my-second-modal");
      }
    })

    function startTagsDialog() {
      console.log("In start Tags dialog");
      // clear out the tags
      // checkedTags.splice(0,checkedTags.length);
      checkedTags.value = [];
      allTags.splice(0,allTags.length);

      const localCheckedTags: VuetifySelection[] = [];
      let allPosTagStrings:string[] = appGlobals.allPositionTags;
      console.log("all sorted uniq tags - "+allPosTagStrings);
      allPosTagStrings.forEach(tag => {
        let tagCount = 0;
        const vuetifySelection = new VuetifySelection(tag,tag,false)
        allTags.push(vuetifySelection);
        props.selectedPositions.forEach(selection => {
          if(selection.tags.includes(tag)){
            tagCount++;
          }
        });
        if(tagCount > 0) {
          if (tagCount < props.selectedPositions.length){
            // not all selected positions have this tag, so set indeterminate to true
            vuetifySelection.indeterminate = true;
          }
          localCheckedTags.push(vuetifySelection);
        }
      });
      checkedTags.value = localCheckedTags;
    }

    // const flatCheckedTags = computed(() => checkedTags.value.map(item => item.value));
    const flatCheckedTags = computed({
      get: () => checkedTags.value.map(item => item.value),
      set: val => {
        console.log("val in setter - "+val);
      }
    });

    return {
      allTags,
      checkedTags,
      flatCheckedTags,
      save,
      closeDialog,
      cancelDialog,
      updateItemState,
    }
  }
})

