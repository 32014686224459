













import {defineComponent, reactive, ref, SetupContext, watch, onMounted, Ref} from "@vue/composition-api";
import {tradeService} from "@/services/TradeService";
interface Props{
  componentHeight: number,
}
export default defineComponent({
  name: "StockFundamentals",
  props: {
    componentHeight: Number
  },
  components: {
  },
  setup(props: Props, context: SetupContext) {

    const tradeData = tradeService.tradeData;
    const fundamentalsDiv = ref<HTMLDivElement>();
    const companyProfileDiv = ref<HTMLDivElement>();
    const fundamentalsTVLink = ref<string>("");
    const profileTVLink = ref<string>("");
    const companyProfileOptions = {
      symbol: "",
      width: "100%",
      height: "100%",
      colorTheme: "dark",
      isTransparent: true,
      locale: "en"
    }
    const fundamentalsOptions =   {
      symbol: "",
      colorTheme: "dark",
      isTransparent: true,
      largeChartUrl: "",
      displayMode: "regular",
      width: "100%",
      height: "100%",
      locale: "en"
    }

    function appendScript(id: string, src: string, options: any, parentDiv: Ref<HTMLDivElement | undefined>){
      const script = document.createElement('script');
      script.id = id;
      script.type = 'text/javascript';
      script.async = true;
      script.src = src;
      script.innerHTML = JSON.stringify(options);
      parentDiv.value!.insertBefore(script, parentDiv.value!.firstChild);
    }

    function unloadScript(scriptId: string){
      const elem = document.getElementById(scriptId);
      if (elem){
        elem.parentNode!.removeChild(elem);
      }
    }


    function loadData(){
      console.log("Loading Widgets");
      unloadScript("tv-profile-script");
      unloadScript("tv-financials-script");
      if (tradeData.symbol){
        fundamentalsTVLink.value = `https://www.tradingview.com/symbols/${tradeData.symbol}/financials-overview/`;
        profileTVLink.value = `https://www.tradingview.com/symbols/${tradeData.symbol}/`;
        companyProfileOptions.symbol = tradeData.symbol;
        fundamentalsOptions.symbol = tradeData.symbol;
        appendScript("tv-profile-script", "https://s3.tradingview.com/external-embedding/embed-widget-symbol-profile.js",
                              companyProfileOptions, companyProfileDiv);
        appendScript("tv-financials-script", "https://s3.tradingview.com/external-embedding/embed-widget-financials.js",
            fundamentalsOptions, fundamentalsDiv);
      }
    }

    watch(() => tradeData.symbol, () => {
      loadData();
    });

    onMounted(() => {
      loadData();
    })

    return {
      tradeData,
      fundamentalsDiv,
      companyProfileDiv,
      profileTVLink,
      fundamentalsTVLink
    }
  }
})


