












































import {defineComponent, onMounted, reactive, ref, SetupContext} from "@vue/composition-api";
import {BrokerUser, SettingsColumnConfig, UserAccount} from "@/data/UserData";
import {appGlobals} from "@/data/AppData";
import {eventEmitter} from "@/main";
import {quoteService} from "@/services/QuoteService";
import {Quotable, SymbolSummary} from "@/data/QuoteData";
import {DataTableHeader} from "vuetify";
import {tradeService} from "@/services/TradeService";

interface Props{
  symbol: string
}


export default defineComponent({
  name: "SymbolSummary",
  components: {},
  props:{
    symbol: String
  },
  setup(props: Props, context: SetupContext) {
    let dataLoadingInd = ref<boolean>(false);
    let summaryHeaders = reactive(buildHeaders());
    let tradeData = tradeService.tradeData;
    let summaryResults: Quotable[] = reactive([]);


    function changeSymbol(){
      dataLoadingInd.value = true;
      summaryResults.splice(0,summaryResults.length);
      // invoke this to have symbol quote updated and option chain loaded
      tradeService.changeSymbol().then(function(){
        dataLoadingInd.value = false;
        // don't have to wait for the quote to be loaded - quotable is already set in tradeData, which will be updated when with rawQuote
        summaryResults.splice(0, summaryResults.length, tradeData.quotable as Quotable);
        // emit an event for the parent component
        context.emit("symbol-changed", tradeData.symbol);
      });
    }

    onMounted(() => {
      if(tradeData.symbol){
        // if this page loads with a symbol, the quotable should have already been created and set in tradeData
        summaryResults.splice(0, summaryResults.length, tradeData.quotable as Quotable);
      }
    });

    return {
      dataLoadingInd,
      changeSymbol,
      summaryHeaders,
      summaryResults,
      tradeData
    }
  }
});

function  buildHeaders() : any[]{
  // these are the default headers
  let accountHeaders : DataTableHeader[] = [
    {text: "Last X Size", value: "volume", sortable: false, align:"end"},
    {text: "Chg", value: "volume", sortable: false, align:"end"},
    {text: "Bid X", value: "volume", sortable: false,align:"end"},
    {text: "Ask X", value: "volume", sortable: false,align:"end"},
    {text: "Size", value: "volume", sortable: false,align:"end"},
    {text: "Volume", value: "volume", sortable: false,align:"end"},
    {text: "", value: "description", sortable: false,align:"start"},
  ];
  return accountHeaders;
}

