
const LOG_LEVELS : {[key: string]: number} = {
    VERBOSE: 1,
    DEBUG: 2,
    INFO: 3,
    WARN: 4,
    ERROR: 5,
};

/**
 * Write logs
 * @class Logger
 */
export class Logger {
    name: string;
    level: string;

    /**
     * @constructor
     * @param {string} name - Name of the logger
     */
    constructor(name: string, level = 'WARN') {
        this.name = name;
        this.level = level;
    }

    static LOG_LEVEL : string | undefined;

    _padding(n: number) {
        return n < 10 ? '0' + n : '' + n;
    }

    _ts() {
        const dt = new Date();
        return (
            [this._padding(dt.getMinutes()), this._padding(dt.getSeconds())].join(
                ':'
            ) +
            '.' +
            dt.getMilliseconds()
        );
    }

    /**
     * Write log
     * @method
     * @memeberof Logger
     * @param {string} type - log type, default INFO
     * @param {string|object} msg - Logging message or object
     */
    _log(type: string, ...msg : any[]) {
        let logger_level_name: string = this.level;
        if (Logger.LOG_LEVEL) {
            logger_level_name = Logger.LOG_LEVEL;
        }
        if (typeof (<any>window) !== 'undefined' && (<any>window).LOG_LEVEL) {
            logger_level_name = (<any>window).LOG_LEVEL;
        }
        const logger_level: number = LOG_LEVELS[logger_level_name];
        const type_level = LOG_LEVELS[type];
        if (!(type_level >= logger_level)) {
            // Do nothing if type is not greater than or equal to logger level (handle undefined)
            return;
        }

        let log = console.log.bind(console);
        if (type === 'ERROR' && console.error) {
            log = console.error.bind(console);
        }
        if (type === 'WARN' && console.warn) {
            log = console.warn.bind(console);
        }

        const prefix = `[${type}] ${this._ts()} ${this.name}`;

        if (msg.length === 1 && typeof msg[0] === 'string') {
            log(`${prefix} - ${msg[0]}`);
        } else if (msg.length === 1) {
            log(prefix, msg[0]);
        } else if (typeof msg[0] === 'string') {
            let obj = msg.slice(1);
            if (obj.length === 1) {
                obj = obj[0];
            }
            log(`${prefix} - ${msg[0]}`, obj);
        } else {
            log(prefix, msg);
        }
    }

    /**
     * Write General log. Default to INFO
     * @method
     * @memeberof Logger
     * @param {string|object} msg - Logging message or object
     */
    log(...msg: any[]) {
        this._log('INFO', ...msg);
    }

    /**
     * Write INFO log
     * @method
     * @memeberof Logger
     * @param {string|object} msg - Logging message or object
     */
    info(...msg: any[]) {
        this._log('INFO', ...msg);
    }

    /**
     * Write WARN log
     * @method
     * @memeberof Logger
     * @param {string|object} msg - Logging message or object
     */
    warn(...msg: any[]) {
        this._log('WARN', ...msg);
    }

    /**
     * Write ERROR log
     * @method
     * @memeberof Logger
     * @param {string|object} msg - Logging message or object
     */
    error(...msg: any[]) {
        this._log('ERROR', ...msg);
    }

    /**
     * Write DEBUG log
     * @method
     * @memeberof Logger
     * @param {string|object} msg - Logging message or object
     */
    debug(...msg: any[]) {
        this._log('DEBUG', ...msg);
    }

    /**
     * Write VERBOSE log
     * @method
     * @memeberof Logger
     * @param {string|object} msg - Logging message or object
     */
    verbose(...msg: any[]) {
        this._log('VERBOSE', ...msg);
    }
}