import { defineComponent, onMounted, reactive, Ref, ref, SetupContext } from "@vue/composition-api";
import { positionColumnsConfig, PositionItem } from "@/data/PositionsData";
import _filter from "lodash/filter";
import _remove from "lodash/remove";
import _reverse from "lodash/reverse";
import Vuetify from "vuetify/lib";

export class PositionSelections {
    selectedStocks = 0;
    selectedOptions = 0;
    selectedPositions: PositionItem[] = [];
    selectedPosAccntSet = new Set();
    selectedPosSymbolSet = new Set();
}

export function useSelectPositions(selections: PositionSelections) {
    let enableCloseButton = ref<boolean>(false); 
    let enableTagButton = ref<boolean>(false);

    function selectAllPositions(positions: PositionItem[]) {
        selections = new PositionSelections();
        positions.forEach(position => {
            position.selected = true;
            trackSelections(position);
        });
    }

    function clearAllPositions(positions: PositionItem[]) {
        positions.forEach(position => position.selected = false)
        selections = new PositionSelections();
    }

    // Rules to enable Close Positions Button
    function selectPositions(position: PositionItem) {
        position.selected = !position.selected;
        trackSelections(position);
    }

    function trackSelections(position: PositionItem) {
        // set the values based on select/de-select on the position
        if (position.selected == true) { // First time click on the row, results in a selection
            selections.selectedPositions.push(position);
            selections.selectedPosAccntSet.add(position.userAccount?.accountNumber);
            selections.selectedPosSymbolSet.add(position.underlying);
            if (position.securityType.isOption) {
                selections.selectedOptions++;
            } else {
                selections.selectedStocks++;
            }
        } else { // second time  click on the row, results in a de-selection
            _remove(selections.selectedPositions, item => item.brokerPositionId === position.brokerPositionId)
            if (selections.selectedPositions.filter(item => item.underlying === position.underlying).length == 0) {
                selections.selectedPosSymbolSet.delete(position.underlying);
            }
            if (selections.selectedPositions.filter(item => item.userAccount?.accountNumber === position.userAccount?.accountNumber).length == 0) {
                selections.selectedPosAccntSet.delete(position.userAccount?.accountNumber);
            }
            if (position.securityType.isOption) {
                selections.selectedOptions--;
            } else {
                selections.selectedStocks--;
            }
        }
        
        // console.log("ENABLE CLOSE? " + enableCloseButton.value + " SEL POS #: " + posSelections.selectedPositions.length);
        selections.selectedPositions.forEach((item, index) => {
            console.log(index + " " + item.underlying + ", " + item.securityType.shortLabel + " " + item.quantity);
        })

        enableClosePositions(selections);
        enableTagPositions(selections);
    }

    function enableTagPositions(selections: PositionSelections) {
        enableTagButton.value = false;
        if(selections.selectedPositions.length > 0)
            enableTagButton.value = true;
    }

    function enableClosePositions(selections: PositionSelections) {
        /* Check for valid combinations allowed to close. All selected positions must
            1. belong to same account and symbol
            2. can be 1 stock or 1 option
            3. can be a combination of 1 stock and 1 option
            4. can be upto 4 options without any stocks
         */
        enableCloseButton.value = false;
        if (selections.selectedPosAccntSet.size == 1 && selections.selectedPosSymbolSet.size == 1) {
            if (selections.selectedPositions.length == 1) { // one option or one stock
                enableCloseButton.value = true;
            } else if (selections.selectedPositions.length == 2
                && (selections.selectedOptions == 1 && selections.selectedStocks == 1)) { //one option and one stock
                enableCloseButton.value = true;
            } else if (selections.selectedPositions.length < 5 && selections.selectedOptions == selections.selectedPositions.length) { // all options
                enableCloseButton.value = true;
            }
        }
    }

    return {
        selectPositions,
        enableCloseButton,
        enableTagButton,
        selectAllPositions,
        clearAllPositions
    }
}