











import {defineComponent, reactive, onMounted, ref, SetupContext, watch} from "@vue/composition-api";
import Accounts from "@/views/positions/Accounts.vue";
import Positions from "@/views/positions/Positions.vue";

export default defineComponent({
  name: "PositionsMain",
  components: {
    'Accounts': Accounts,
    'PositionsGrid': Positions
  },
  setup(props: undefined, context: SetupContext) {
    let positionsGridHeight = ref(800) ;
    let accountsGridDiv = ref<HTMLDivElement>();
    const positionsGridDiv = ref<HTMLDivElement>();

    function onResize(){
      if(positionsGridDiv.value){
        positionsGridHeight.value = window.innerHeight - positionsGridDiv.value.getBoundingClientRect().y;
      }
    }
    onMounted(() => {
      onResize();
    })

    return {
      onResize,
      positionsGridHeight,
      positionsGridDiv,
      accountsGridDiv
    }
  }
})

