import { onMounted, reactive, ref, SetupContext } from "@vue/composition-api";
import { UserPreferences, SettingsColumnConfig} from "@/data/UserData";
import { appGlobals} from "@/data/AppData";
import { userService } from "@/services/UserService";
import _difference from "lodash/difference";
import {DataTableHeader} from "vuetify";
import {eventEmitter} from "@/main";
import {WatchlistColumn, watchlistColumnsConfig} from "@/data/WatchlistData";

export function useWatchlistColumns(context: SetupContext) {
    
    // used to track changes in column preferences
    let watchlistColumnsReactive = reactive(getWatchlistColumns()); // for data
    let watchlistHeaders = reactive(buildHeaders(watchlistColumnsReactive)); // for headers
    
    // used in watchlist settings dialog
    let showColumnSettings = ref<boolean>(false);
    let selectedColumns: SettingsColumnConfig[] = reactive([]);
    let availableColumns: SettingsColumnConfig[] = reactive([]);

    /**
     * This method is invoked to open Column Settings Dialog
     */
    function startColumnSettings() {
        alert("start...");
        showColumnSettings.value = true;
        selectedColumns.splice(0, selectedColumns.length); // use splice to keep the reference when clearing an array
        let preferences = appGlobals.user?.userPreferences as UserPreferences;
        let userCols = preferences.watchlistColumns.split(",")
        userCols.forEach(colKey => {
            selectedColumns.push(watchlistColumnsConfig.allColumnsDict[colKey]);
        })
        availableColumns.splice(0, availableColumns.length, ..._difference(watchlistColumnsConfig.allColumnsList, selectedColumns));
        alert("test"); debugger
    }

    /**
     * This method is invoked when Column Settings Dialog is closed
     */
    function saveColumnSettings(value: boolean, finalCols: String[]) {
        showColumnSettings.value = value;
        userService.saveWatchlistColumns(finalCols)
            .then(savedColumns => {
                watchlistColumnsReactive.splice(0, watchlistColumnsReactive.length, ...getWatchlistColumns());
                showColumnSettings.value = false;
                console.log("Pos reactive after:" + watchlistColumnsReactive.length);
                watchlistHeaders.splice(0, watchlistHeaders.length, ...buildHeaders(watchlistColumnsReactive));
            }).catch(reason => eventEmitter.emit("showError", ["Error in saving Position Columns"]))
    }

    /**
     * Retrieves watchlist columns from user's profile
     * @returns Array<WatchlistColumn>[]
     */
    function getWatchlistColumns(){
        let displayWatchlistColumns : Array<WatchlistColumn> = [];
        let preferences: UserPreferences = appGlobals.user?.userPreferences as UserPreferences;
        preferences.watchlistColumns.split(",").forEach(watchlistColumnKey => {
          if (!watchlistColumnsConfig.allColumnsDict[watchlistColumnKey]){
            console.log("Missing key - "+watchlistColumnKey);
          }
            displayWatchlistColumns.push(watchlistColumnsConfig.allColumnsDict[watchlistColumnKey])
        })
        return displayWatchlistColumns;
    }
    
    /**
     * builds headers for the data table based on watchlist columns in user's profile
     */
    function buildHeaders(watchlistColumns: WatchlistColumn[]) : DataTableHeader[]{
        // these are the default headers
        let watchlistHeaders : DataTableHeader[] = [
            {text: "Symbol", value: "symbol", sortable: true, align: "end", width: "8%" }
        ];
        // push dynamic headers
        for (let watchlistColumn of watchlistColumns) {
            watchlistHeaders.push({
            text: watchlistColumn.headerName,
            value: watchlistColumn.property,
            sortable: true,
            align:"end"
          })
        }
        return watchlistHeaders;
    }

    return {
        startColumnSettings,
        saveColumnSettings,
        watchlistColumnsReactive,
        watchlistHeaders,
        showColumnSettings,
        availableColumns,
        selectedColumns
    }
}