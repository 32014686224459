import {BrokerUser} from "@/data/UserData";
import {BrokerFactory} from "@/services/broker/BrokerFactory";
import {PositionItem} from "@/data/PositionsData";
import {appGlobals} from "@/data/AppData";
import {BrokerAccount} from "@/data/AccountData";
import _flatMap from "lodash/flatMap";
import {eventEmitter} from "@/main";


export class AccountService{

    getCachedAccounts(): BrokerAccount[]{
        return _flatMap(Object.values(appGlobals.userBrokerDataMap), brokerDataMap => brokerDataMap.accounts);
    }

    async loadAccounts(): Promise<BrokerAccount[]>{
        const positionItems: PositionItem[] = [];
        // retrieve all user accounts
        const brokerUsers = appGlobals.user!.brokerUsers;

        const allBrokerAccounts: BrokerAccount[] = [];
        for(let brokerUser of brokerUsers){
            // create BrokerAccount for each userAccount that we are managing for this BrokerUser
            const brokerAccounts : BrokerAccount[] = [];
            brokerUser.userAccounts.forEach(userAccount => {
                const brokerAccount: BrokerAccount = new BrokerAccount();
                brokerAccount.accountNumber = userAccount.accountNumber!;
                brokerAccount.userAccount = userAccount;
                brokerAccounts.push(brokerAccount);
            })
            const cachedBrokerAccounts = appGlobals.userBrokerDataMap[brokerUser.brokerUserId].accounts;
            cachedBrokerAccounts.splice(0, cachedBrokerAccounts.length, ...brokerAccounts);
            await this.refreshAccounts(brokerUser, brokerAccounts)
            allBrokerAccounts.push(...brokerAccounts);
        }
        return allBrokerAccounts;
    }

    async refreshAccounts(brokerUser: BrokerUser, brokerAccounts : BrokerAccount[]): Promise<void>{
        // load accounts from backend database and
        const brokerAccountService = BrokerFactory.getAccountService(brokerUser.broker!);
        let _this = this;
        try {
            brokerAccountService.updateAccounts(brokerUser, brokerAccounts);
        }catch (e){
            console.log(e.stack);
            eventEmitter.emit("showError", ["Error in loading account balances for user profile - "+brokerUser.brokerUserProfileName]);
        }
    }

}

export const accountService = new AccountService();