


















































































































import {defineComponent, reactive, ref, SetupContext} from "@vue/composition-api";
import {leadBackendService} from "@/services/lead/LeadBackendService";
import {AsyncResponse} from "@/data/CommonData";
import {WaitlistLead} from "@/data/lead/LeadData";
import {AsyncStatus} from "@/data/EnumData";
import {eventEmitter} from "@/main";

interface AppFeature{
  title: string;
  features: string[];
  screenshots: string[];
}

interface PricingPlan{
  title: string;
  features: string[];
  price: string;
}

export default defineComponent({
  name: "HomeLanding",
  setup(props: undefined, context: SetupContext) {

    const appFeatures: AppFeature[] = reactive([{
      title: "Positions",
      features: [
          "Access positions from multiple brokers in a single view",
          "Create custom grouping for different perspectives",
          "Create your desired layout"],
      screenshots: [
          "positions1.png",
          "positions2.png",
          "positions3.jpg"]},
      {
        title: "Trading",
        features: [
          "Highly interactive option chain to place multi-leg trades",
          "Analyze different trade combinations",
          "Create trade templates to easily place trades"],
        screenshots: [
          "optionchain1.jpg",
          "optionchain2.jpg",
          "tradeTicket1.jpg"]},
      {
        title: "Charts",
        features: [
          "Deep integration with TradingView Charts",
          "Advanced indicators and strategies",
          "Save your settings and styles"],
        screenshots: [
          "chart1.jpg",
          "charts2.jpg"]
      }
    ]);

    const pricingPlans: PricingPlan[] = reactive([
      {
        title: "Basic",
        features: ["1 Brokerage Acct", "200K Net Acct Value"],
        price: "$0.00/month"},
      {
        title: "Plus",
        features: ["3 Brokerage Accts", "1 Million Net Acct Value"],
        price: "$0.00/month"
      },
      {
        title: "Premium",
        features: ["Unlimited Accts", "Unlimited Net Acct Value"],
        price: "$0.00/month"
      }
    ])
    const email = ref<string>("");

    function joinWaitlist(){
      document.getElementById("waitlistSection")!.scrollIntoView({block: "center", behavior: "smooth"});
    }

    async function saveEmail(){
      const asyncResponse: AsyncResponse<WaitlistLead> = await leadBackendService.createWaitlistLead(email.value);
      if (asyncResponse.status == AsyncStatus.Ok){
        eventEmitter.emit("showMessage", ["Thank you for joining the waitlist"]);
      }else{
        eventEmitter.emit("showError", asyncResponse.businessErrors);
      }
    }

    return {
      appFeatures,
      pricingPlans,
      email,
      saveEmail,
      joinWaitlist
    }
  }
});


