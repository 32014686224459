








import {defineComponent, onMounted, reactive, SetupContext} from "@vue/composition-api";

export default defineComponent({
  name: "UserPreferences",
  setup(props: undefined, context: SetupContext) {

    return {

    }
  }
});

