import {
    OrderClass,
    OrderDuration,
    OrderStatus,
    OrderType,
    PriceEffect, PriceAdjustmentSide,
    SecurityDirection,
    SecurityType,
    SymbolItemType,
    TradeSide,
    TransactionType, RollingOrderStatus
} from "@/data/EnumData";
import DateUtil, {BNUtil} from "@/util/Util";
import BigNumber from "bignumber.js";
import {SymbolItem} from "@/data/PositionsData";
import {OptionContract, Quotable} from "@/data/QuoteData";
import {UserAccount} from "@/data/UserData";
import {Constants} from "@/data/common/Constants";
import {Transform, Type} from "class-transformer";


export class Order{
    class: OrderClass = OrderClass.None; // defaulting to None
    userAccount?: UserAccount;

    brokerOrderId: string = "";
    private _orderTypeStr: string = OrderType.Limit.toString(); // enum key
    private _orderType: OrderType = OrderType.Limit;    // Enum object

    private _duration: OrderDuration = OrderDuration.Day;
    private _durationStr: string = OrderDuration.Day.toString();

    symbol: string = "";    // refers to the underlying security symbol (SPY,QQQ)

    // private _quantityStr = "";
    // //   at the order level, quantity should be always positive and represents the gcd of the legs
    // // it's not clear how to set quantity for combo orders, so we may never use quantity at the order level
    // private _quantity: number = 0;
    //
    // quantityFilled: number = 0;

    private _status: OrderStatus = OrderStatus.Open;
    statusStr: string = OrderStatus.Open.toString();

    // subStatus is required to handle partially filled orders
    // Possible values are -
    // status - PartiallyFilled subStatus - Open
    // status - Expired/Cancelled subStatus - PartiallyFilled
    private _subStatus: OrderStatus | undefined;
    subStatusStr: string = "";

    priceLocked = false;    // indicates if the user locked the price. when true, quotes are not updated on the _price field
    // Typically we would display _priceStr, but for Market, Stop/Limit and Stop Order types, this value would be different
    // checkout updatePriceDescription method
    priceDescription: string = "";
    // used in the 'Price' text field, this field won't have any group separators
    // when this value is updated, rest of the price values
    priceInput: string = "";
    private _priceStr : string = "";    // used to display 'Price' in view only areas like Preview, Orders etc., where group separator is shown
    // order price entered by user. this value is always positive.
    // goes in combination with Net Debit/Credit for multi-leg orders or Buy/Sell for single leg orders
    private _price = BNUtil.ZERO;
    // indicates if it is a debit or credit order
    priceEffect: PriceEffect = PriceEffect.None;

    private _stopPriceInput: string = ""; // similar to priceInput, this is used for the input field
    private _stopPriceStr : string = "";
    // stop price entered by user. always positive
    private _stopPrice = BNUtil.ZERO;
    stopPriceEffect: PriceEffect = PriceEffect.None;

    currentPriceStr: string = "";
    // price as calculated from quotes. this is always set to positive value
    // use the currentPriceSide to determine if it's debit or credit
    private _currentPrice = BNUtil.ZERO;
    currentPriceEffect: PriceEffect = PriceEffect.None;

    currentPriceBidStr: string = "";
    private _currentPriceBid = BNUtil.ZERO;
    currentPriceBidEffect: PriceEffect = PriceEffect.None;
    currentPriceBidSideStr = "";

    currentPriceAskStr: string = "";
    private _currentPriceAsk = BNUtil.ZERO;
    currentPriceAskEffect: PriceEffect = PriceEffect.None;

    averageFillPriceStr: string = "";
    private _averageFillPrice = BNUtil.ZERO;
    averageFillPriceEffect: PriceEffect = PriceEffect.None;

    private _createdDate = new Date();
    createdDateStr = "";

    private _updatedDate = new Date();
    updatedDateStr = "";

    description = "";

    legs: OrderLeg[] = [];

    rollingLimitParams = new RollingLimitParams();

    // used to identify the action use would like to perform on the order
    // though only one action can be done at a time (requiring just a string instead of an array),
    // we had to use an array because of v-list-item-group quirks, where once a choice is selected, the choice stays selected and could not be cleared
    // possible values are "Modify", "Cancel" and "Similar"
    selectedActions: string[] = [];

    get orderTypeStr(): string {
        return this._orderTypeStr;
    }

    set orderTypeStr(value: string) {
        this._orderTypeStr = value;
        this.orderType = OrderType.getEnum(value);
    }

    get orderType(): OrderType {
        return this._orderType;
    }

    set orderType(value: OrderType) {
        this._orderType = value;
        this._orderTypeStr = value.label;
    }

    get duration(): OrderDuration {
        return this._duration;
    }

    set duration(value: OrderDuration) {
        this._duration = value;
        this._durationStr = value.label;
    }

    get durationStr(): string {
        return this._durationStr;
    }

    set durationStr(value: string) {
        this._durationStr = value;
        this._duration = OrderDuration.getEnum(value);
    }

    // get quantityStr(): string {
    //     return this._quantityStr;
    // }
    //
    // set quantityStr(value: string) {
    //     this._quantityStr = value;
    //     this._quantity = Number(value);
    // }
    //
    // get quantity(): number {
    //     return this._quantity;
    // }
    //
    // set quantity(value: number) {
    //     this._quantity = value;
    //     this._quantityStr = value.toString();
    // }

    get status(): OrderStatus {
        return this._status;
    }

    set status(value: OrderStatus) {
        this._status = value;
        this.statusStr = value.label;
    }

    get subStatus(): OrderStatus | undefined {
        return this._subStatus;
    }

    set subStatus(value: OrderStatus | undefined) {
        this._subStatus = value;
        this.subStatusStr = value?.label ?? "";
    }

    get priceStr(): string {
        return this._priceStr;
    }

    set priceStr(value: string) {
        this._priceStr = value;
        this._price = BNUtil.of(value);
    }

    get price(): BigNumber {
        return this._price;
    }

    set price(value: BigNumber) {
        this._price = value;
        this._priceStr = BNUtil.formatBN(value);
        this.priceInput = BNUtil.formatBN(value, false);
    }

    get currentPrice(): BigNumber {
        return this._currentPrice;
    }

    set currentPrice(value: BigNumber) {
        this._currentPrice = value;
        this.currentPriceStr = BNUtil.formatBN(value);
    }

    get currentPriceBid(): BigNumber {
        return this._currentPriceBid;
    }

    set currentPriceBid(value: BigNumber) {
        this._currentPriceBid = value;
        this.currentPriceBidStr = BNUtil.formatBN(value);
    }

    get currentPriceAsk(): BigNumber {
        return this._currentPriceAsk;
    }

    set currentPriceAsk(value: BigNumber) {
        this._currentPriceAsk = value;
        this.currentPriceAskStr = BNUtil.formatBN(value);
    }

    get stopPriceInput(): string {
        return this._stopPriceInput;
    }

    set stopPriceInput(value: string) {
        this._stopPriceInput = value;
    }

    get stopPrice(): BigNumber {
        return this._stopPrice;
    }

    set stopPrice(value: BigNumber) {
        this._stopPrice = value;
        this._stopPriceStr = BNUtil.formatBN(value);
        this._stopPriceInput = BNUtil.formatBN(value, false);
    }

    get stopPriceStr(): string {
        return this._stopPriceStr;
    }

    set stopPriceStr(value: string) {
        this._stopPriceStr = value;
        this._stopPrice = BNUtil.of(value);
    }

    get averageFillPrice(): BigNumber {
        return this._averageFillPrice;
    }

    set averageFillPrice(value: BigNumber) {
        this._averageFillPrice = value;
        this.averageFillPriceStr = BNUtil.formatBN(value);
    }

    get createdDate(): Date {
        return this._createdDate;
    }

    set createdDate(value: Date) {
        this._createdDate = value;
        this.createdDateStr = DateUtil.toShortDateTimeStr(value);
    }

    get updatedDate(): Date {
        return this._updatedDate;
    }

    set updatedDate(value: Date) {
        this._updatedDate = value;
        this.updatedDateStr = DateUtil.toShortDateTimeStr(value);
    }

    /**
     * Invoke this method after setting orderType and price values
     * To avoid having to code these conditional statements in template, we did it here
     */
    updatePriceDescription(){
        if (this.orderType == OrderType.Limit || this.orderType == OrderType.NetDebit || this.orderType == OrderType.NetCredit ){
            this.priceDescription = `${this.priceStr} <span class="${this.priceEffect.displayColor}">${this.priceEffect.label}</span>`;
        }else if(this.orderType == OrderType.Market){
            this.priceDescription = OrderType.Market.shortLabel;
        }else if(this.orderType == OrderType.Stop){
            this.priceDescription = `${OrderType.Stop.shortLabel} ${this.stopPriceStr} <span class="${this.stopPriceEffect.label}">${this.stopPriceEffect.label}</span>`;
        }else if(this.orderType == OrderType.StopLimit){
            this.priceDescription = `${OrderType.Stop.shortLabel} ${this.stopPriceStr} ${OrderType.Limit.shortLabel} ${this.priceStr} <span class="${this.priceEffect.displayColor}" >${this.priceEffect.label}</span> `;
        }else if(this.orderType == OrderType.RollingLimit){
            this.priceDescription = `${OrderType.RollingLimit.shortLabel} ${this.rollingLimitParams.priceStartStr} <span class="${this.rollingLimitParams.priceStartEffect.displayColor}" >${this.rollingLimitParams.priceStartEffect.label}</span> 
                                                to ${this.rollingLimitParams.priceStop1Str} <span class="${this.rollingLimitParams.priceStop1Effect.displayColor}" >${this.rollingLimitParams.priceStop1Effect.label}</span> `;
        }
    }

    getSelectedLegsOrAll() : OrderLeg[]{
        const selectedLegs = this.legs.filter(leg => leg.selected);
        return selectedLegs.length > 0 ? selectedLegs : this.legs;
    }

    // Because we support RollingOrderType which brokers don't support
    // We have this method that returns the appropriate OrderType for RollingLimit Orders
    getOrderTypeForBroker(): OrderType{
        if(this.orderType == OrderType.RollingLimit){
            if(this.legs.length > 1){
                return this.priceEffect == PriceEffect.Debit ? OrderType.NetDebit : OrderType.NetCredit;
            }else{
                return OrderType.Limit;
            }
        }
        return this.orderType;
    }

}

export class OrderLeg  implements SymbolItem {

    symbolItemType = SymbolItemType.OrderLeg;
    brokerLegId: string = "";
    symbol = "";    // stock symbol or osi option symbol
    underlying = ""; //
    // osiSymbol = ""; // osi symbol for option and regular symbol for stock
    private _securityType : SecurityType = SecurityType.Call;    // defaulting to call
    securityTypeStr = "";  // shows 'P', 'C' or 'Shares'

    private  _expiryDate = new Date(); // this could be
    expiryDateStr = "" ;    // format - yyyy-MM-dd
    expiryDateShort = ""; //    format - 'MMM dd'
    daysToExpiry = 0; //   number of days to expiry

    private  _strikePrice = BNUtil.ZERO; //
    strikePriceStr = ""     // derived from strikePrice

    private _securityDirection: SecurityDirection = SecurityDirection.Long;  // defaulting to Long

    private _transactionType: TransactionType = TransactionType.Open;

    // could be positive or negative based on the leg being long or short
    private _quantity: number = 0
    private _quantityStr = "";

    // this refers to a plain quotable (for equity leg) or could refer to the OptionContract from OptionChain
    quotable: Quotable | OptionContract | undefined;

    quantityFilledStr = "";
    private _quantityFilled: number = 0;

    averageFillPriceStr: string = "";
    private _averageFillPrice = BNUtil.ZERO;

    // derived property, so no setter
    private _side: TradeSide = TradeSide.BTO;

    // set to true when user selects this leg from TradeTicket or OptionChain
    selected:boolean = false;

    // when this contract is selected, should it provide controls to move the strike price or expiry
    // when the transactionType is Close, leg cannot move, so the value is set in the setter of transactionType
    private _canMove = false;

    get securityType(): SecurityType {
        return this._securityType;
    }

    set securityType(value: SecurityType) {
        this._securityType = value;
        this.securityTypeStr = value.toString();
    }

    get securityDirection(): SecurityDirection {
        return this._securityDirection;
    }

    set securityDirection(value: SecurityDirection) {
        this._securityDirection = value;
        this.updateTradeSide();
    }

    get transactionType(): TransactionType {
        return this._transactionType;
    }

    set transactionType(value: TransactionType) {
        this._transactionType = value;
        this._canMove = value == TransactionType.Open ? true : false;
        this.updateTradeSide();
    }

    get expiryDate():Date{
        return this._expiryDate;
    }
    set expiryDate(expiryDate: Date){
        this._expiryDate = expiryDate;
        // also set derived properties
        this.expiryDateShort = DateUtil.toExpiryViewStrNoYear(this._expiryDate);
        this.expiryDateStr = DateUtil.toDateStr(expiryDate);
        this.daysToExpiry = DateUtil.daysUntil(expiryDate);
    }

    get strikePrice(): BigNumber {
        return this._strikePrice;
    }

    set strikePrice(value: BigNumber) {
        this.strikePriceStr = BNUtil.formatBN(value);
        this._strikePrice = value;
    }

    set quantityStr(value: string) {
        this._quantityStr = value;
        // don't set the _quantity integer, we'll handle it in the change event
        // this._quantity = Number(value);
    }

    get quantity(): number {
        return this._quantity;
    }

    set quantity(value: number) {
        this._quantity = value;
        this._quantityStr = value.toString();
    }

    get quantityStr(): string {
        return this._quantityStr;
    }

    get quantityFilled(): number {
        return this._quantityFilled;
    }

    set quantityFilled(value: number) {
        this.quantityFilledStr = value > 0 ? String(value) : "";
        this._quantityFilled = value;
    }


    get averageFillPrice(): BigNumber {
        return this._averageFillPrice;
    }

    set averageFillPrice(value: BigNumber) {
        this._averageFillPrice = value;
        this.averageFillPriceStr = BNUtil.formatBN(value);
    }

    get canMove() : boolean{
        return this._canMove;
    }

    /**
     * No setter for this field
     */
    get side(): TradeSide {
        return this._side;
    }

    private updateTradeSide(){
        if(this.securityDirection == SecurityDirection.Long){
            this._side = (this.transactionType == TransactionType.Open) ? TradeSide.BTO : TradeSide.BTC;
        }else{
            this._side = (this.transactionType == TransactionType.Open) ? TradeSide.STO : TradeSide.STC;
        }
    }
}

export class RollingLimitParams{

    // generated by the server once the request is accepted by the server
    rollingOrderId = "";

    brokerOrderId = "";
    // when sending to backend, we send only userAccount.id
    userAccount: UserAccount | undefined;

    // starting limit price
    priceStartInput: string = "";
    priceStartStr : string = "";

    // This is how you would convert from BigNumber to string and back using Class-transformer
    // @Type(() => BigNumber)
    // @Transform(({value}) => BNUtil.formatBN(value as BigNumber, false), {toPlainOnly: true} )
    // @Transform(({value}) => BNUtil.of(value), {toClassOnly: true} )
    private _priceStart = BNUtil.ZERO;

    // This is how you would convert from Enum to string and back using Class-transformer
    // @Type(() => PriceEffect)
    // @Transform(({value}) => (value as PriceEffect).enumKey, {toPlainOnly: true} )
    // @Transform(({value}) => PriceEffect.getEnum(value), {toClassOnly: true} )
    priceStartEffect: PriceEffect = PriceEffect.None;

    // if we split the rolling limit order into two sets, we'll have two stops
    priceStop1Input = "";
    priceStop1Str : string = "";
    private _priceStop1 = BNUtil.ZERO;
    priceStop1Effect: PriceEffect = PriceEffect.None;

    priceStop2Input: string = "";
    priceStop2Str : string = "";
    private _priceStop2 = BNUtil.ZERO;
    priceStop2Effect: PriceEffect = PriceEffect.None;

    // while rolling, this price represents the current roll's price
    currentPriceStr = "";
    private _currentPrice = BNUtil.ZERO;
    currentPriceEffect = PriceEffect.None;

    adjustment1Str = "";
    private _adjustment1 = 0;

    adjustment2Str = "";
    private _adjustment2 = 0;

    private adjustmentSide1 : PriceAdjustmentSide | undefined;

    private adjustmentSide2 : PriceAdjustmentSide | undefined;

    timeIncrement1Str = Constants.RollingLimitTimeIncrement.toString();
    private _timeIncrement1 = Constants.RollingLimitTimeIncrement;

    timeIncrement2Str = Constants.RollingLimitTimeIncrement.toString();
    private _timeIncrement2 = Constants.RollingLimitTimeIncrement;

    status: RollingOrderStatus = RollingOrderStatus.Working;

    numberOfRolls: number = 0;

    message = "";

    errorMessages: String[]  = [];

    get priceStart(): BigNumber {
        return this._priceStart;
    }

    set priceStart(value: BigNumber) {
        this._priceStart = value;
        this.priceStartStr = BNUtil.formatBN(value);
        this.priceStartInput = BNUtil.formatBN(value, false);
    }

    get priceStop1(): BigNumber {
        return this._priceStop1;
    }

    set priceStop1(value: BigNumber) {
        this._priceStop1 = value;
        this.priceStop1Str = BNUtil.formatBN(value);
        this.priceStop1Input = BNUtil.formatBN(value, false);
    }

    get priceStop2(): BigNumber {
        return this._priceStop2;
    }

    set priceStop2(value: BigNumber) {
        this._priceStop2 = value;
        this.priceStop2Str = BNUtil.formatBN(value);
        this.priceStop2Input = BNUtil.formatBN(value, false);
    }

    get currentPrice(): BigNumber {
        return this._currentPrice;
    }

    set currentPrice(value: BigNumber) {
        this._currentPrice = value;
        this.currentPriceStr = BNUtil.formatBN(value);
    }


    get adjustment1(): number {
        return this._adjustment1;
    }

    set adjustment1(value: number) {
        this._adjustment1 = value;
        this.adjustment1Str = value.toString();
    }

    get adjustment2(): number {
        return this._adjustment2;
    }

    set adjustment2(value: number) {
        this._adjustment2 = value;
        this.adjustment2Str = value.toString();
    }

    get timeIncrement1(): number {
        return this._timeIncrement1;
    }

    set timeIncrement1(value: number) {
        this._timeIncrement1 = value;
        this.timeIncrement1Str = value.toString();
    }

    get timeIncrement2(): number {
        return this._timeIncrement2;
    }

    set timeIncrement2(value: number) {
        this._timeIncrement2 = value;
        this.timeIncrement2Str = value.toString();
    }



}

export class OrderPreview  {
    // cost of trade
    tradeCostStr: string = "";
    // always positive, tradeCostEffect says whether it is debit or credit
    private _tradeCost: BigNumber = BNUtil.ZERO;
    tradeCostEffect: PriceEffect = PriceEffect.None;

    // change in buying power, includes fee/commissions excluding regulartory fee
    buyingPowerChangeStr = "";
    // always positive
    private _buyingPowerChange: BigNumber = BNUtil.ZERO;
    buyingPowerChangeEffect: PriceEffect = PriceEffect.None;

    commissionStr: string = "";
    private _commission: BigNumber = BNUtil.ZERO;

    feesStr = "";
    private _fees: BigNumber = BNUtil.ZERO;

    // regulatory fee
    regulatoryFeesStr = "";
    private _regulatoryFees: BigNumber = BNUtil.ZERO;

    get tradeCost(): BigNumber {
        return this._tradeCost;
    }

    set tradeCost(value: BigNumber) {
        this._tradeCost = value;
        this.tradeCostStr = BNUtil.formatBN(value);
    }

    get buyingPowerChange(): BigNumber {
        return this._buyingPowerChange;
    }

    set buyingPowerChange(value: BigNumber) {
        this._buyingPowerChange = value;
        this.buyingPowerChangeStr = BNUtil.formatBN(value);
    }

    get commission(): BigNumber {
        return this._commission;
    }

    set commission(value: BigNumber) {
        this._commission = value;
        this.commissionStr = BNUtil.formatBN(value);
    }

    get fees(): BigNumber {
        return this._fees;
    }

    set fees(value: BigNumber) {
        this._fees = value;
        this.feesStr = BNUtil.formatBN(value);
    }

    get regulatoryFees(): BigNumber {
        return this._regulatoryFees;
    }

    set regulatoryFees(value: BigNumber) {
        this._regulatoryFees = value;
        this.regulatoryFeesStr = BNUtil.formatBN(value);
    }

}