














































































































































































import {defineComponent, onMounted, reactive, Ref, ref, SetupContext} from "@vue/composition-api";
import {appGlobals} from "@/data/AppData";
import {DataTableHeader} from "vuetify";
import {BNUtil, NumberUtil} from "@/util/Util";
import BigNumber from "bignumber.js";
import {eventEmitter} from "@/main";
import {orderService} from "@/services/OrderService";
import {Order} from "@/data/OrdersData";
import {
  OrderStatus
} from "@/data/EnumData";
import SymbolItemDisplay from "@/views/positions/SymbolItemDisplay.vue";
import _sortBy from "lodash/sortBy";
import _split from "lodash/split";
import _filter from "lodash/filter";
import {BrokerUser, UserAccount} from "@/data/UserData";
import {parse, format, differenceInDays} from 'date-fns'
import {tradeService} from "@/services/TradeService";
import {TradeAnalysis} from "@/data/TradeData";

class OrderFilterCriteria{
  symbol?: string;
  orderStatus: string[] = [];
  accounts: string[] = [];
  days?: string;  // ex - get orders whose creation/execution date is within the last 7 'days'
}

export default defineComponent({
  name: "Orders",
  components: {SymbolItemDisplay},
  setup(props: undefined, context: SetupContext) {
    let dataLoadingInd = ref<boolean>(true);
    let orderHeaders = reactive(buildHeaders());
    let orderItems = reactive<Order[]>([]);
    let actionsList = reactive([
      {icon: "", text: ""}
    ]);
    // used to identify the action use would like to perform on the order
    // though only one action can be done at a time (requiring just a string instead of an array),
    // we had to use an array because of v-list-item-group quirks, where once a choice is selected, the choice stays selected and could not be cleared
    // possible values are "Modify", "Cancel" and "Similar"
    let selectedOrderActions = reactive<string[]>([]);

    let filterCriteria = reactive(new OrderFilterCriteria());
    let allUserAccounts: UserAccount[] = reactive([]);  // allUserAccounts for filtering
    let daysFilterList = reactive([
      {text: "Today", value: 0},
      {text: "Yesterday", value: 1},
      {text: "7 Days", value: 7},
      {text: "14 Days", value: 14},
      {text: "30 Days", value: 30},
    ])

    function loadOrders(){
      orderItems.splice(0, orderItems.length, ...orderService.getCachedOrders());
      // we don't need the analysis object on Orders, but we're just using a dummy object so we can invoke updateOrderTotal which needs it
      const analysis: TradeAnalysis = new TradeAnalysis();
      orderItems.forEach(order => {
        order.priceLocked = true;
        try {
          tradeService.updateOrderTotal(order as Order, analysis);
        }catch (e) {
          console.log("Error with - "+order.symbol);
        }

      })
      dataLoadingInd.value = false;
      console.log("orderItems.length: "+ orderItems.length);
    }

    /**
     * load account list for filters
     */
    function loadFilterUserAccounts() {
      let brokerUsers: BrokerUser[] = appGlobals.user!.brokerUsers;
      if(appGlobals.user!.brokerUsers) {
        brokerUsers.forEach(brokerUser => allUserAccounts.push(...brokerUser.userAccounts));
      }
    }

    function loadFilterDays() {
      let brokerUsers: BrokerUser[] = appGlobals.user!.brokerUsers;
      if(appGlobals.user!.brokerUsers) {
        brokerUsers.forEach(brokerUser => allUserAccounts.push(...brokerUser.userAccounts));
      }
    }

    function updateFilters(){
      console.log("**Types: "+filterCriteria.days);
      let remoteOrders = orderService.getCachedOrders();
      processRemoteOrders(remoteOrders);
    }

    function processRemoteOrders(remoteOrders : Order[]){
      // sort them by symbol
      remoteOrders = _sortBy(remoteOrders, "symbol", order => order.updatedDate)
      // replace orders grid with remote orders
      remoteOrders = filterOrders(remoteOrders, filterCriteria);
      // set newOrders in the grid
      orderItems.splice(0, orderItems.length, ...remoteOrders);

    }

    function filterOrders(orders: Order[], filterCriteria: OrderFilterCriteria){
      if (filterCriteria.symbol) {
        let symbols: string[] =[];
        let result: Order[] = [];
        _split(filterCriteria.symbol,",").forEach(element => {
          result.push(..._filter(orders, order => order.symbol.toUpperCase() == element.trim().toUpperCase()));
        })
        orders.splice(0, orders.length,...result);
      };

      // filter by status
      if(filterCriteria.orderStatus?.length == 0 || filterCriteria.orderStatus?.length == 4) {
        // no or all selections, do nothing
      } else if(filterCriteria.orderStatus.length > 0){
        let result: Order[] = [];
        let status: OrderStatus[] = [];
        if(filterCriteria.orderStatus.includes('Working')) {
          status.push(OrderStatus.PartiallyFilled);
          status.push(OrderStatus.Open);
          status.push(OrderStatus.Pending);
        }
        if(filterCriteria.orderStatus.includes('Filled')) {
          status.push(OrderStatus.PartiallyFilled);
          status.push(OrderStatus.Filled);
        }
        if(filterCriteria.orderStatus.includes('Cancelled')) {
          status.push(OrderStatus.Canceled);
        }
        if(filterCriteria.orderStatus.includes('Other')) {
          status.push(OrderStatus.Expired);
          status.push(OrderStatus.Rejected);
          status.push(OrderStatus.Error);
        }
        orders.forEach(order => {
          // the filter status should match orderStatus or orderSubstatus
          if( (status.includes(order.status)
              || (order.status.canHaveSubStatus && order.subStatus && status.includes(order.subStatus) )  ) && !result.includes(order)){
            result.push(order);
            console.log("match "+order.symbol);
          }
        });
        orders.splice(0, orders.length,...result);
      }

      // filter by accounts
      if(filterCriteria.accounts && filterCriteria.accounts.length > 0){
        let result: Order[] = [];
        filterCriteria.accounts.forEach(element => {
          result.push(..._filter(orders, order => order.userAccount!.accountNumber == element));
          //console.log("rs: "+result.length);
        });
        orders.splice(0, orders.length,...result);
      }

      if(filterCriteria.days != null) {
        let results: Order[] = _filter(orders, order =>{
          return differenceInDays(new Date(), order.updatedDate) <= Number(filterCriteria.days)
        });
        orders.splice(0, orders.length,...results);
      }

      return orders;
    }

    function performSelectedAction(order: Order){
      console.log("Selected order - "+order.brokerOrderId);
      console.log("Selected Action - "+ order.selectedActions[0]);
      const orderAction = order.selectedActions[0];
      // reset the selection
      order.selectedActions.splice(0, order.selectedActions.length);
      // Object.assign(order.selectedAction, String("test"));
      if (orderAction == "Cancel"){
        orderService.cancelOrder(order).then(function(){
          loadOrders();
        });
      }else if(orderAction == "Modify"){
        tradeService.openOrderForEditing(order).then(function(proceedWithEditing: boolean){
          if (proceedWithEditing){
            // navigate to Trade Screen on
            context.root.$router.push("/main/trade");
          }else{
            // we cannot proceed, that means the local status might be different from remote status, best to refresh the orders screen
            loadOrders();
          }
        });
      }

    }

    onMounted(() => {
      eventEmitter.on("ordersLoaded", function(){
        loadOrders();
      })
      eventEmitter.on("quotesLoaded", function(){
        loadOrders();
      })
      loadFilterUserAccounts();
      if (appGlobals.ordersLoaded){
        loadOrders();
      }
    });

    return {
      dataLoadingInd,
      orderHeaders,
      orderItems,
      updateFilters,
      allUserAccounts,
      filterCriteria,
      daysFilterList,
      selectedOrderActions,
      performSelectedAction,
      OrderStatus
    }
  }
});

function  buildHeaders() : any[]{
  // these are the default headers
  let orderHeaders : DataTableHeader[] = [
    {text: "", value: "", sortable: false, width: "30px"},
    {text: "Symbol", value: "symbol", sortable: false, align:"start", width: "1%"},
    {text: "", value: "", sortable: false, align:"start"}, //SymbolItemDisplay
    {text: "Status", value: "status", sortable: false,align:"start"},
    {text: "Trd Price", value: "price", sortable: false,align:"end"},
    {text: "Mid", value: "currentPrice", sortable: false,align:"end"},
    {text: "Fill Price", value: "averageFillPrice", sortable: false,align:"end"},
    {text: "Duration", value: "duration", sortable: false,align:"end"},
    {text: "Time", value: "updatedDate", sortable: false,align:"end"},
    {text: "", value: "actions", sortable: false,align:"end"}
  ];
 return orderHeaders;
}

