import {Broker} from "@/data/EnumData";
import {genericAccountService} from "@/services/generic/GenericAccountService";
import {BrokerQuoteService} from "@/services/broker/BrokerQuoteService";
import {genericQuoteService} from "@/services/generic/GenericQuoteService";
import {BrokerAccountService} from "@/services/broker/BrokerAccountService";
import {BrokerOrderService} from "@/services/broker/BrokerOrderService";
import {genericOrderService} from "@/services/generic/GenericOrderService";


export class BrokerFactory{

    static getAccountService(broker: Broker): BrokerAccountService{
        if(broker == Broker.Generic){
            return genericAccountService;
        }else{
            return genericAccountService;
        }
    }

    static getQuoteService(broker: Broker): BrokerQuoteService{
        if(broker == Broker.Generic){
            return genericQuoteService;
        }else{
            return genericQuoteService;
        }
    }

    static getOrderService(broker: Broker): BrokerOrderService{
        if(broker == Broker.Generic){
            return genericOrderService;
        }else{
            return genericOrderService;
        }
    }

}